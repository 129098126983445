package org.company.app.presentation.component


import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mohamedrejeb.compose.dnd.DragAndDropContainer
import com.mohamedrejeb.compose.dnd.drag.DraggableItem
import com.mohamedrejeb.compose.dnd.drop.dropTarget
import com.mohamedrejeb.compose.dnd.rememberDragAndDropState
import kotlinx.coroutines.launch
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.checkmark
import multiplatform_app.composeapp.generated.resources.pencil
import multiplatform_app.composeapp.generated.resources.tool
import org.company.app.presentation.component.kanbanboard.KanbanBoardCard
import org.company.app.presentation.ui.board.rememberForeverLazyListState
import org.jetbrains.compose.resources.painterResource
import org.openapitools.client.models.TaskItem
import org.openapitools.client.models.TaskStatusDetailsResponse

@Composable
fun TeamRetrospective(){
    val dragAndDropState = rememberDragAndDropState<TaskItem?>()
    val tasks: MutableList<TaskItem?> = mutableListOf(
        TaskItem(id = "id_1", taskStatus = "keepDoingId", dueDate = "2024-05-29T03:00:00.000Z",taskStatusDetails = TaskStatusDetailsResponse(id = "keepDoingId",name = "Keep doing", value = "TO_DO"), name = "Test"),
        TaskItem(id = "id_2", taskStatus = "stopDoingId", dueDate = "2024-05-29T03:00:00.000Z",taskStatusDetails = TaskStatusDetailsResponse(id = "stopDoingId", name = "Stop doing", value = "IN_PROGRESS"), name = "Test"),
    )
    val taskStatusDetails = remember { mutableStateListOf(TaskStatusDetailsResponse(id = "keepDoingId",name = "Keep doing", value = "TO_DO"),TaskStatusDetailsResponse(id = "stopDoingId", name = "Stop doing", value = "IN_PROGRESS"),TaskStatusDetailsResponse(id = "startDoingId",name = "Start doing", value = "DONE")) }
    DragAndDropContainer(
        state = dragAndDropState,
    ) {
        LazyRow(modifier= Modifier.fillMaxSize().padding(horizontal = 20.dp,vertical = 0.dp)) {


            itemsIndexed(taskStatusDetails){index,column->
                val columnName = remember { mutableStateOf(column.name) }
                val listState = rememberLazyListState()

                Column(modifier= Modifier.fillMaxWidth(0.3f).fillMaxHeight()) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Row(verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier.background(color = when(column.name){
                                "Keep doing" -> Color(0xFFeaeeef)
                                "Stop doing" -> Color(0xFFf6edce)
                                "Start doing" -> Color(0xFFe5f3d3)

                                else -> Color(0xFFeaeeef)
                            }, shape = RoundedCornerShape(6.dp)
                            )) {
                            Image(painter = painterResource(
                                when(column.name){
                                    "Keep doing" -> Res.drawable.pencil
                                    "Stop doing" -> Res.drawable.tool
                                    "Start doing" -> Res.drawable.checkmark
                                    else -> Res.drawable.pencil
                                }

                            ),contentDescription = null,
                                modifier = Modifier.padding(start = 4.dp).padding(vertical = 4.dp))
                            Spacer(modifier = Modifier.width(4.dp))
                            BasicTextField(value = columnName.value?:"", onValueChange = {columnName.value = it},
                                textStyle = MaterialTheme.typography.bodyMedium.copy(fontSize = 18.sp,color = Color(0xFF032549)),
                                modifier = Modifier.padding(end = 4.dp)
                            )
                            /* Text(column.name?:"", fontSize = 18.sp,
                                 //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                 style =  MaterialTheme.typography.bodyMedium,
                                 fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                 color = Color(0xFF032549),
                                 modifier = Modifier.padding(end = 4.dp)
                             ) */
                        }

                        Spacer(modifier = Modifier.width(4.dp))

                        TextButton(contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp),onClick = {},
                        ){
                            Box(
                                modifier = Modifier
                                    .background(Color.Transparent)
                                    .padding(horizontal = 10.dp, vertical = 0.dp)
                                    .border(BorderStroke(1f.dp, Color.Gray), shape = RoundedCornerShape(50))
                                ,
                                contentAlignment = Alignment.Center
                            ) {
                                Text("${tasks.filter { it?.taskStatusDetails==column }.size}", fontSize = 14.sp,modifier = Modifier.padding(horizontal = 10.dp, vertical = 0.dp),fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF5C728A))
                            }

                        }

                    }
                    LazyColumn(state = rememberForeverLazyListState(key = column.name?:""),modifier = Modifier.border(
                        width = 1.dp,
                        color = with(MaterialTheme.colorScheme) {
                            if (dragAndDropState.hoveredDropTargetKey == "targetKey${column.id}") primary else Color.Transparent
                        },
                        shape = RoundedCornerShape(24.dp),
                    ).dropTarget(
                        key = "targetKey${column.id}",
                        state = dragAndDropState,
                        onDrop = {
                            tasks.remove(it.data)
                            tasks.add(it.data?.copy(taskStatusDetails = column))

                        }
                    )
                        // .then(if (itemCount>=2) Modifier.weight(1f) else Modifier) )
                        .then(Modifier.weight(1f)) )
                    {
                        items(tasks.filter { it?.taskStatusDetails==column }){
                            DraggableItem(
                                state = dragAndDropState,
                                key = it?.id?:"", // Unique key for each draggable item
                                data = it, // Data to be passed to the drop target
                                draggableContent = {


                                    TeamRetrospectiveCard(task = mutableStateOf(it?:TaskItem()), onClick = {})
                                }
                            ) {


                                TeamRetrospectiveCard(modifier= Modifier.graphicsLayer {
                                    alpha = if (isDragging) 0f else 1f
                                }, task = mutableStateOf(it?: TaskItem()), onClick = {})
                                /*
                                TaskCard(modifier= Modifier.graphicsLayer {
                                    alpha = if (isDragging) 0f else 1f
                                },onClick = {

                                            }, task = TaskItem()) */


                            }
                        }
                        if (tasks.filter { it?.taskStatusDetails==column }.isEmpty()){
                            item{
                                Spacer(modifier = Modifier.width(350.dp).padding(16.dp))
                            }
                        }
                    }
                    val editable = remember { mutableStateOf(false) }
                    val scope = rememberCoroutineScope()

                    if (editable.value){
                        val taskTitle = remember { mutableStateOf("") }
                        if (tasks.filter { it?.taskStatusDetails==column }.isNotEmpty()){
                            scope.launch { listState.animateScrollToItem(tasks.filter { it?.taskStatusDetails==column }.size-1) }
                        }

                        Card(
                            modifier = Modifier
                                .padding(16.dp)
                                .width(250.dp)


                            ,
                            elevation = CardDefaults.cardElevation(
                                defaultElevation = 4.dp
                            ),
                            colors = CardDefaults.cardColors(
                                containerColor = Color(0xFFFAFAFA), // set the background color of the card
                            )
                            ,
                            shape = RoundedCornerShape(10.dp),
                        ){
                            Column(modifier = Modifier.padding(top = 8.dp)) {
                                Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                                    Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                                        ZiFocusTextField(text = taskTitle, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type Task Title", withDivider = false)
                                    }
                                }
                                Row(modifier = Modifier.padding(bottom = 14.dp)) {
                                    Spacer(modifier = Modifier.weight(1f))
                                    TextButton(onClick = {
                                        editable.value = false
                                        taskTitle.value = ""
                                    }){
                                        Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                            color = Color(0xFF3b4958)
                                        )
                                    }
                                    Spacer(modifier = Modifier.width(8.dp))
                                    Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                                        modifier = Modifier
                                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                                            editable.value = false
                                            tasks.add(
                                                TaskItem(id = "id_${tasks.size}", taskStatus = column.id, dueDate = "2024-05-29T03:00:00.000Z",taskStatusDetails = TaskStatusDetailsResponse(id = column.id,name = column.name, value = column.value), name = taskTitle.value),

                                                )
                                            /* tasks.add(
                                                 TaskItem(name = taskTitle.value, taskStatus = column.id,
                                                     dueDate = "2024-05-29T03:00:00.000Z",
                                                     category = CategoryItem(title = "Work", color = 0xFFA98EDA))) */

                                        }){
                                        Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                                    }

                                    Spacer(modifier = Modifier.width(8.dp))

                                }


                            }
                        }
                    }

                    else {
                        Row(
                            modifier = Modifier
                                .wrapContentHeight()
                                .fillMaxWidth(), verticalAlignment = Alignment.CenterVertically
                        ) {
                            TextButton(
                                modifier = Modifier
                                    .wrapContentHeight(),
                                contentPadding = PaddingValues(horizontal = 8.dp, vertical = 4.dp),
                                colors = ButtonDefaults.textButtonColors(
                                    contentColor = MaterialTheme.colorScheme.primaryContainer
                                ),
                                onClick = {
                                    editable.value = true
                                }
                            ) {
                                Icon(
                                    imageVector = Icons.Filled.Add,
                                    contentDescription = "Add card"
                                )
                                Text(
                                    modifier = Modifier,
                                    fontSize = 12.sp,
                                    text = "Add Task"
                                )
                            }
                        }
                    }


                }
            }
            item{
                Icon(Icons.Default.Add,contentDescription = null, modifier = Modifier.clickable {

                    taskStatusDetails.add(
                        TaskStatusDetailsResponse(id = "new${taskStatusDetails.size}",name = "New", value = "TO_DO")
                    ) })
            }



        }

    }
}