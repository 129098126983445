@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package multiplatform_app.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val add_blocker: DrawableResource by 
      lazy { init_add_blocker() }

  public val add_comment: DrawableResource by 
      lazy { init_add_comment() }

  public val add_view_layout: DrawableResource by 
      lazy { init_add_view_layout() }

  public val arrow: DrawableResource by 
      lazy { init_arrow() }

  public val assign_member_: DrawableResource by 
      lazy { init_assign_member_() }

  public val blockers: DrawableResource by 
      lazy { init_blockers() }

  public val board_column: DrawableResource by 
      lazy { init_board_column() }

  public val board_name_screen: DrawableResource by 
      lazy { init_board_name_screen() }

  public val checkmark: DrawableResource by 
      lazy { init_checkmark() }

  public val chef: DrawableResource by 
      lazy { init_chef() }

  public val comments: DrawableResource by 
      lazy { init_comments() }

  public val delete: DrawableResource by 
      lazy { init_delete() }

  public val drag_icon: DrawableResource by 
      lazy { init_drag_icon() }

  public val due_datepicker_: DrawableResource by 
      lazy { init_due_datepicker_() }

  public val edit: DrawableResource by 
      lazy { init_edit() }

  public val empty_placeholder: DrawableResource by 
      lazy { init_empty_placeholder() }

  public val exit: DrawableResource by 
      lazy { init_exit() }

  public val eye: DrawableResource by 
      lazy { init_eye() }

  public val eye_hide: DrawableResource by 
      lazy { init_eye_hide() }

  public val filter: DrawableResource by 
      lazy { init_filter() }

  public val goals: DrawableResource by 
      lazy { init_goals() }

  public val google_: DrawableResource by 
      lazy { init_google_() }

  public val group_by: DrawableResource by 
      lazy { init_group_by() }

  public val ideas: DrawableResource by 
      lazy { init_ideas() }

  public val kanban_done_: DrawableResource by 
      lazy { init_kanban_done_() }

  public val low_flag: DrawableResource by 
      lazy { init_low_flag() }

  public val manage_work: DrawableResource by 
      lazy { init_manage_work() }

  public val mark_done: DrawableResource by 
      lazy { init_mark_done() }

  public val medium_flag: DrawableResource by 
      lazy { init_medium_flag() }

  public val notes: DrawableResource by 
      lazy { init_notes() }

  public val pause: DrawableResource by 
      lazy { init_pause() }

  public val pdf_svgrepo_com: DrawableResource by 
      lazy { init_pdf_svgrepo_com() }

  public val pencil: DrawableResource by 
      lazy { init_pencil() }

  public val personal_filled: DrawableResource by 
      lazy { init_personal_filled() }

  public val personal_image: DrawableResource by 
      lazy { init_personal_image() }

  public val play: DrawableResource by 
      lazy { init_play() }

  public val priority_flag_: DrawableResource by 
      lazy { init_priority_flag_() }

  public val remove: DrawableResource by 
      lazy { init_remove() }

  public val remove_filter: DrawableResource by 
      lazy { init_remove_filter() }

  public val scrum_board: DrawableResource by 
      lazy { init_scrum_board() }

  public val signin_google_: DrawableResource by 
      lazy { init_signin_google_() }

  public val task_notification: DrawableResource by 
      lazy { init_task_notification() }

  public val time_: DrawableResource by 
      lazy { init_time_() }

  public val tool: DrawableResource by 
      lazy { init_tool() }

  public val urgent_flag: DrawableResource by 
      lazy { init_urgent_flag() }

  public val work_filled: DrawableResource by 
      lazy { init_work_filled() }

  public val work_image: DrawableResource by 
      lazy { init_work_image() }

  public val x_blocker: DrawableResource by 
      lazy { init_x_blocker() }

  public val x_blocker_: DrawableResource by 
      lazy { init_x_blocker_() }

  public val x_cross: DrawableResource by 
      lazy { init_x_cross() }

  public val zi_pages: DrawableResource by 
      lazy { init_zi_pages() }

  public val zifocus_logo: DrawableResource by 
      lazy { init_zifocus_logo() }
}

internal val Res.drawable.add_blocker: DrawableResource
  get() = CommonMainDrawable0.add_blocker

private fun init_add_blocker(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:add_blocker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/add_blocker.xml", -1, -1),
    )
)

internal val Res.drawable.add_comment: DrawableResource
  get() = CommonMainDrawable0.add_comment

private fun init_add_comment(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:add_comment",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/add_comment.xml", -1, -1),
    )
)

internal val Res.drawable.add_view_layout: DrawableResource
  get() = CommonMainDrawable0.add_view_layout

private fun init_add_view_layout(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:add_view_layout",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/add_view_layout.png", -1, -1),
    )
)

internal val Res.drawable.arrow: DrawableResource
  get() = CommonMainDrawable0.arrow

private fun init_arrow(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/arrow.xml", -1, -1),
    )
)

internal val Res.drawable.assign_member_: DrawableResource
  get() = CommonMainDrawable0.assign_member_

private fun init_assign_member_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:assign_member_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/assign_member_.png", -1, -1),
    )
)

internal val Res.drawable.blockers: DrawableResource
  get() = CommonMainDrawable0.blockers

private fun init_blockers(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:blockers",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/blockers.xml", -1, -1),
    )
)

internal val Res.drawable.board_column: DrawableResource
  get() = CommonMainDrawable0.board_column

private fun init_board_column(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:board_column",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/board_column.png", -1, -1),
    )
)

internal val Res.drawable.board_name_screen: DrawableResource
  get() = CommonMainDrawable0.board_name_screen

private fun init_board_name_screen(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:board_name_screen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/board_name_screen.png", -1, -1),
    )
)

internal val Res.drawable.checkmark: DrawableResource
  get() = CommonMainDrawable0.checkmark

private fun init_checkmark(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:checkmark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/checkmark.png", -1, -1),
    )
)

internal val Res.drawable.chef: DrawableResource
  get() = CommonMainDrawable0.chef

private fun init_chef(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:chef",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/chef.png", -1, -1),
    )
)

internal val Res.drawable.comments: DrawableResource
  get() = CommonMainDrawable0.comments

private fun init_comments(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:comments",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/comments.xml", -1, -1),
    )
)

internal val Res.drawable.delete: DrawableResource
  get() = CommonMainDrawable0.delete

private fun init_delete(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:delete",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/delete.xml", -1, -1),
    )
)

internal val Res.drawable.drag_icon: DrawableResource
  get() = CommonMainDrawable0.drag_icon

private fun init_drag_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:drag_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/drag_icon.xml", -1, -1),
    )
)

internal val Res.drawable.due_datepicker_: DrawableResource
  get() = CommonMainDrawable0.due_datepicker_

private fun init_due_datepicker_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:due_datepicker_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/due_datepicker_.png", -1, -1),
    )
)

internal val Res.drawable.edit: DrawableResource
  get() = CommonMainDrawable0.edit

private fun init_edit(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/edit.xml", -1, -1),
    )
)

internal val Res.drawable.empty_placeholder: DrawableResource
  get() = CommonMainDrawable0.empty_placeholder

private fun init_empty_placeholder(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:empty_placeholder",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/empty_placeholder.png", -1, -1),
    )
)

internal val Res.drawable.exit: DrawableResource
  get() = CommonMainDrawable0.exit

private fun init_exit(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:exit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/exit.png", -1, -1),
    )
)

internal val Res.drawable.eye: DrawableResource
  get() = CommonMainDrawable0.eye

private fun init_eye(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:eye",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/eye.png", -1, -1),
    )
)

internal val Res.drawable.eye_hide: DrawableResource
  get() = CommonMainDrawable0.eye_hide

private fun init_eye_hide(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:eye_hide",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/eye_hide.png", -1, -1),
    )
)

internal val Res.drawable.filter: DrawableResource
  get() = CommonMainDrawable0.filter

private fun init_filter(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:filter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/filter.xml", -1, -1),
    )
)

internal val Res.drawable.goals: DrawableResource
  get() = CommonMainDrawable0.goals

private fun init_goals(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:goals",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/goals.xml", -1, -1),
    )
)

internal val Res.drawable.google_: DrawableResource
  get() = CommonMainDrawable0.google_

private fun init_google_(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/google_.png", -1, -1),
    )
)

internal val Res.drawable.group_by: DrawableResource
  get() = CommonMainDrawable0.group_by

private fun init_group_by(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:group_by",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/group_by.xml", -1, -1),
    )
)

internal val Res.drawable.ideas: DrawableResource
  get() = CommonMainDrawable0.ideas

private fun init_ideas(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ideas",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/ideas.xml", -1, -1),
    )
)

internal val Res.drawable.kanban_done_: DrawableResource
  get() = CommonMainDrawable0.kanban_done_

private fun init_kanban_done_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:kanban_done_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/kanban_done_.png", -1, -1),
    )
)

internal val Res.drawable.low_flag: DrawableResource
  get() = CommonMainDrawable0.low_flag

private fun init_low_flag(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:low_flag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/low_flag.xml", -1, -1),
    )
)

internal val Res.drawable.manage_work: DrawableResource
  get() = CommonMainDrawable0.manage_work

private fun init_manage_work(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:manage_work",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/manage_work.png", -1, -1),
    )
)

internal val Res.drawable.mark_done: DrawableResource
  get() = CommonMainDrawable0.mark_done

private fun init_mark_done(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mark_done",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/mark_done.xml", -1, -1),
    )
)

internal val Res.drawable.medium_flag: DrawableResource
  get() = CommonMainDrawable0.medium_flag

private fun init_medium_flag(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:medium_flag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/medium_flag.xml", -1, -1),
    )
)

internal val Res.drawable.notes: DrawableResource
  get() = CommonMainDrawable0.notes

private fun init_notes(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:notes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/notes.gif", -1, -1),
    )
)

internal val Res.drawable.pause: DrawableResource
  get() = CommonMainDrawable0.pause

private fun init_pause(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pause",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/pause.png", -1, -1),
    )
)

internal val Res.drawable.pdf_svgrepo_com: DrawableResource
  get() = CommonMainDrawable0.pdf_svgrepo_com

private fun init_pdf_svgrepo_com(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:pdf_svgrepo_com",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/pdf_svgrepo_com.xml", -1, -1),
    )
)

internal val Res.drawable.pencil: DrawableResource
  get() = CommonMainDrawable0.pencil

private fun init_pencil(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pencil",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/pencil.png", -1, -1),
    )
)

internal val Res.drawable.personal_filled: DrawableResource
  get() = CommonMainDrawable0.personal_filled

private fun init_personal_filled(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:personal_filled",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/personal_filled.png", -1, -1),
    )
)

internal val Res.drawable.personal_image: DrawableResource
  get() = CommonMainDrawable0.personal_image

private fun init_personal_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:personal_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/personal_image.png", -1, -1),
    )
)

internal val Res.drawable.play: DrawableResource
  get() = CommonMainDrawable0.play

private fun init_play(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/play.png", -1, -1),
    )
)

internal val Res.drawable.priority_flag_: DrawableResource
  get() = CommonMainDrawable0.priority_flag_

private fun init_priority_flag_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:priority_flag_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/priority_flag_.png", -1, -1),
    )
)

internal val Res.drawable.remove: DrawableResource
  get() = CommonMainDrawable0.remove

private fun init_remove(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:remove",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/remove.xml", -1, -1),
    )
)

internal val Res.drawable.remove_filter: DrawableResource
  get() = CommonMainDrawable0.remove_filter

private fun init_remove_filter(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:remove_filter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/remove_filter.xml", -1, -1),
    )
)

internal val Res.drawable.scrum_board: DrawableResource
  get() = CommonMainDrawable0.scrum_board

private fun init_scrum_board(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:scrum_board",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/scrum_board.png", -1, -1),
    )
)

internal val Res.drawable.signin_google_: DrawableResource
  get() = CommonMainDrawable0.signin_google_

private fun init_signin_google_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:signin_google_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/signin_google_.png", -1, -1),
    )
)

internal val Res.drawable.task_notification: DrawableResource
  get() = CommonMainDrawable0.task_notification

private fun init_task_notification(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:task_notification",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/task_notification.svg", -1, -1),
    )
)

internal val Res.drawable.time_: DrawableResource
  get() = CommonMainDrawable0.time_

private fun init_time_(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:time_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/time_.png", -1, -1),
    )
)

internal val Res.drawable.tool: DrawableResource
  get() = CommonMainDrawable0.tool

private fun init_tool(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:tool",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/tool.png", -1, -1),
    )
)

internal val Res.drawable.urgent_flag: DrawableResource
  get() = CommonMainDrawable0.urgent_flag

private fun init_urgent_flag(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:urgent_flag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/urgent_flag.xml", -1, -1),
    )
)

internal val Res.drawable.work_filled: DrawableResource
  get() = CommonMainDrawable0.work_filled

private fun init_work_filled(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:work_filled",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/work_filled.png", -1, -1),
    )
)

internal val Res.drawable.work_image: DrawableResource
  get() = CommonMainDrawable0.work_image

private fun init_work_image(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:work_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/work_image.png", -1, -1),
    )
)

internal val Res.drawable.x_blocker: DrawableResource
  get() = CommonMainDrawable0.x_blocker

private fun init_x_blocker(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:x_blocker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/x_blocker.xml", -1, -1),
    )
)

internal val Res.drawable.x_blocker_: DrawableResource
  get() = CommonMainDrawable0.x_blocker_

private fun init_x_blocker_(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:x_blocker_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/x_blocker_.png", -1, -1),
    )
)

internal val Res.drawable.x_cross: DrawableResource
  get() = CommonMainDrawable0.x_cross

private fun init_x_cross(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:x_cross",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/x_cross.xml", -1, -1),
    )
)

internal val Res.drawable.zi_pages: DrawableResource
  get() = CommonMainDrawable0.zi_pages

private fun init_zi_pages(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:zi_pages",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/zi_pages.xml", -1, -1),
    )
)

internal val Res.drawable.zifocus_logo: DrawableResource
  get() = CommonMainDrawable0.zifocus_logo

private fun init_zifocus_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:zifocus_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/multiplatform_app.composeapp.generated.resources/drawable/zifocus_logo.xml", -1, -1),
    )
)
