package org.company.app.presentation.component

import androidx.compose.foundation.*
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Surface
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType

import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import com.mohamedrejeb.richeditor.ui.BasicRichTextEditor

import multiplatform_app.composeapp.generated.resources.Res

import multiplatform_app.composeapp.generated.resources.x_cross

import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalResourceApi::class)
@Composable

fun CreateIdeaDialog(onClick:()->Unit={},onDismiss:()->Unit={}){
    var summary by remember { mutableStateOf("") }
    AlertDialog(
        onDismissRequest = {  },
        properties = DialogProperties(
            dismissOnClickOutside = true,
            dismissOnBackPress = true,
            usePlatformDefaultWidth = false
        ),
        modifier = Modifier.fillMaxWidth(0.7f).padding(40.dp),
        containerColor = Color(0xFFFAFAFA),
        title = {
            Row {

                Spacer(modifier = Modifier.weight(1f))
                Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier= Modifier.clickable { onDismiss() })


            }
        },
        text = {
            Column(modifier = Modifier.background(Color(0xFFFAFAFA))) {
                Text(
                    text = "Add Ideas",
                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                    style = MaterialTheme.typography.bodyMedium,
                    fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                    fontSize = 24.sp,
                    modifier = Modifier.padding(start = 16.dp),
                    color = Color(0xFF03264A)
                )
                Spacer(modifier = Modifier.height(28.dp))
                Text(
                    text = "Summary",
                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    fontSize = 18.sp,
                    modifier = Modifier.padding(start = 16.dp),
                    color = Color(0xFF5C728A)
                )
                Spacer(modifier = Modifier.height(12.dp))
                // Task name here
                Box(modifier = Modifier.background(Color(0xFFedf3fc)).padding(horizontal = 16.dp).fillMaxWidth().heightIn(min = 50.dp)) {
                    if (summary==""){
                        Text("Type Here", fontSize = 16.sp,
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                            style =  MaterialTheme.typography.bodySmall
                            , color = Color(0xFF5C728A).copy(0.5f), modifier = Modifier.align(Alignment.CenterStart))
                    }
                    BasicTextField(
                        modifier = Modifier.align(Alignment.CenterStart)
                        ,value = summary,
                        onValueChange = {
                            summary=it
                        }
                        ,

                        textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily),
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Text,
                            imeAction = ImeAction.Done
                        ),


                        )
                }
                Spacer(modifier = Modifier.height(24.dp))
                Text(
                    text = "Idea Description",
                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    fontSize = 18.sp,
                    modifier = Modifier.padding(start = 16.dp),
                    color = Color(0xFF5C728A)
                )
                Spacer(modifier = Modifier.height(12.dp))
                val basicRichTextState = rememberRichTextState()
                Surface(elevation = 3.dp, color = Color(0xFFFAFAFA)) {
                    RichTextStyleRow(
                        modifier = Modifier.fillMaxWidth(),
                        state = basicRichTextState,
                    )
                }

                Spacer(modifier = Modifier.height(8.dp))
                Box(modifier = Modifier.background(Color(0xFFedf3fc)).padding(12.dp)) {

                    if (basicRichTextState.annotatedString.text==""){
                        Text("Type Description", fontSize = 16.sp,
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style =  MaterialTheme.typography.bodySmall, color = Color(0xFF5C728A).copy(alpha = 0.5f),modifier = Modifier.padding(start = 8.dp))
                    }
                    BasicRichTextEditor(
                        modifier = Modifier.fillMaxWidth().height(150.dp).padding(start = 8.dp),
                        state = basicRichTextState,
                    )

                }
                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(top = 12.dp)) {

                    Spacer(modifier = Modifier.weight(1f))
                    Button(//contentPadding = PaddingValues(horizontal = 12.dp, vertical = 7.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
                        ,
                        shape = RoundedCornerShape(8.dp),
                        onClick = {
                            onClick()
                        },
                    ){
                        Text("Create", style = MaterialTheme.typography.bodyMedium, fontSize = 15.sp)
                    }
                }




            }

        },
        confirmButton = {

        }
    )

}
