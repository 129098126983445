package org.company.app.data.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DocsFileResponse(
    @SerialName("content")
    var content: DocsContentResponse
)
