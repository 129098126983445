/*
 * Copyright 2023 Joel Kanyi.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.slideInHorizontally
import androidx.compose.animation.slideOutHorizontally
import androidx.compose.foundation.*
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.*
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.annotation.InternalVoyagerApi
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.seiko.imageloader.rememberImagePainter
import multiplatform_app.composeapp.generated.resources.*
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.arrow
import org.company.app.presentation.component.CustomAlertDialog
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.DocsScreen
import org.company.app.presentation.ui.HomeScreen
import org.company.app.presentation.ui.LoopComponentScreen
import org.company.app.presentation.ui.board.TaskBoardScreen
import org.company.app.presentation.ui.goals.GoalsScreen
import org.company.app.presentation.ui.ideas.IdeasScreen
import org.company.app.presentation.ui.profile.ProfileScreen
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.NewLoopRequest
import org.openapitools.client.models.Project

@OptIn(ExperimentalMaterial3Api::class, InternalVoyagerApi::class, ExperimentalResourceApi::class)
@Composable
fun AppNavigationRailBar(
    modifier: Modifier = Modifier,
    navigatorParent: Navigator
) {
    val viewModel = koinInject<HomeViewModel>()
    val homeState by viewModel.homeState.collectAsState()
    val navigator = LocalNavigator.currentOrThrow
    val boards =  homeState.boards
    val projects =  homeState.projects
    val loopComponents =   homeState.loopComponentsList
    val boardMembers =  homeState.boardMembers
    val currentBoardName = homeState.currentBoardName
    LaunchedEffect(boards.isNotEmpty()){
        if (boards.isNotEmpty()){
            viewModel.currentBoard(boards.firstOrNull()?.id,boards.firstOrNull()?.name,true)
            viewModel.clearProjects()
            viewModel.getBoardProjects()
            //  viewModel.getBoardMembers(boards.firstOrNull()?.id?:"")
        }
    }
    LaunchedEffect(projects.isNotEmpty()){
        if (projects.isNotEmpty()){
            homeState.tasks.clear()
            homeState.loadedTasks=false
            viewModel.selectWorkSpace(projects.firstOrNull()?: Project(),projects.firstOrNull()?.id?:"",if (homeState.selectedWorkspaceId!=null)true else false)
            viewModel.getWorkspaceTasks()
        }
    }



    val createNewLoopDialog = remember { mutableStateOf(false) }
    val isDropdownMenuExpanded = remember { mutableStateOf(false) }
    var expanded by remember { mutableStateOf(true) }
    var peopleExpanded by remember { mutableStateOf(false) }
    var invitePeople by remember { mutableStateOf(false) }
    var changeOrganization by remember { mutableStateOf(false) }
    val fullScreenMode = remember { mutableStateOf(false) }
    val interactionSourceTarget = remember { MutableInteractionSource() }
    val isHoveredTarget = interactionSourceTarget.collectIsHoveredAsState().value
    val interactionSourcePeople = remember { MutableInteractionSource() }
    val isHoveredPeople = interactionSourcePeople.collectIsHoveredAsState().value
    val isDeleteTaskDialogVisible = remember { mutableStateOf(false) }

    if (createNewLoopDialog.value){
        val loopTitle = remember { mutableStateOf("") }
        AlertDialog(onDismissRequest = {
            createNewLoopDialog.value = false
        },
            confirmButton = {
                Button(onClick = {
                    viewModel.createNewLoop(NewLoopRequest(title = loopTitle.value))
                    createNewLoopDialog.value = false
                }){
                    Text("Create Loop")
                }
            },
            title = { Text("Create Loop") },
            text = {

                Spacer(modifier=Modifier.height(10.dp))
                OutlinedTextField(label = {Text("Loop Title")},placeholder = {Text("Loop Title")},value = loopTitle.value, onValueChange = {
                    loopTitle.value = it
                })
            }
        )
    }

    if (isDeleteTaskDialogVisible.value){
        CustomAlertDialog(title = "Delete Organization", description = "Are you sure you want to delete (${currentBoardName}) organization?",onDelete = {
            viewModel.deleteBoard(homeState.currentBoardId?:""){
                if (it.isNotEmpty()){
                    viewModel.currentBoard(it.firstOrNull()?.id?:"",it.firstOrNull()?.name,true)
                    viewModel.clearProjects()
                    viewModel.getBoardProjects()
                    // viewModel.getBoardMembers(it.firstOrNull()?.id?:"")
                } else {
                    viewModel.clearProjects(loadedProject = true)
                }
            }
            isDeleteTaskDialogVisible.value=false


        }, onDismiss = {
            isDeleteTaskDialogVisible.value=false
        })
    }

    Box(modifier=Modifier.fillMaxHeight()) {

        AnimatedVisibility(enter = slideInHorizontally(
            initialOffsetX = { -it },
            animationSpec = tween(durationMillis = 100)
        ),
            exit = slideOutHorizontally(
                targetOffsetX = { -it },
                animationSpec = tween(durationMillis = 150)
            ),
            visible = !fullScreenMode.value,){
            Surface(shadowElevation = 1.dp, modifier = Modifier.padding(end = 1.dp)) {
                NavigationRail(
                    modifier = modifier.fillMaxHeight().width(200.dp).verticalScroll(rememberScrollState()),
                    containerColor = Color(0xFFFAFAFA),
                    header = {
                        //  Text("ZiFOCUS", fontFamily = fontFamilyResource(MR.fonts.sf.bold), fontSize = 18.sp,modifier=Modifier.padding(horizontal = 12.dp, vertical = 14.dp),textAlign = TextAlign.Start)

                        Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.CenterVertically) {
                            Text(boards.find { it?.id==homeState.currentBoardId }?.name?:"Select Organization",
                                // fontFamily = fontFamilyResource(MR.fonts.sf.bold),
                                fontFamily =  MaterialTheme.typography.bodyLarge.fontFamily,
                                fontSize = 18.sp,
                                overflow = TextOverflow.Ellipsis,
                                modifier=Modifier.padding(horizontal = 18.dp, vertical = 14.dp).fillMaxWidth(0.83f),
                                textAlign = TextAlign.Start)
                            Spacer(modifier = Modifier.weight(1f))
                            Box {
                                IconButton(
                                    onClick = { isDropdownMenuExpanded.value = !isDropdownMenuExpanded.value }
                                ) {
                                    Icon(
                                        imageVector = Icons.Default.MoreVert,
                                        contentDescription = "More Options"
                                    )
                                }


                                DropdownMenu(
                                    expanded = isDropdownMenuExpanded.value,
                                    onDismissRequest = { isDropdownMenuExpanded.value = false },
                                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA))
                                ){
                                    if (homeState.currentBoardName!=null&&homeState.boards.isNotEmpty()){
                                        DropdownMenuItem(
                                            text = { Text("Add workspace",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                isDropdownMenuExpanded.value = false
                                                // Handle add workspace action
                                                viewModel.showCreateWorkSpaceDialog()
                                            }
                                        )
                                    }

                                    if (homeState.currentBoardName!=null&&homeState.boards.isNotEmpty()){
                                        DropdownMenuItem(
                                            text = { Text("Invite people",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                isDropdownMenuExpanded.value = false
                                                // Handle invite people action
                                                invitePeople=true

                                            }
                                        )
                                    }

                                    if (homeState.currentBoardName!=null&&homeState.boards.isNotEmpty()){
                                        DropdownMenuItem(
                                            text = { Text("Change Organization",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                isDropdownMenuExpanded.value = false
                                                // Handle change organization action
                                                changeOrganization = true
                                            }
                                        )
                                    }

                                    DropdownMenuItem(
                                        text = { Text("New Organization",
                                            // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                            style =  MaterialTheme.typography.bodySmall,
                                            fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                            , fontSize = 15.sp) },
                                        onClick = {
                                            isDropdownMenuExpanded.value = false
                                            // Handle new organization action
                                            viewModel.showCreateBoardDialog()

                                        }
                                    )
                                    // hamokshaa
                                    if (homeState.currentBoardName!=null&&homeState.boards.isNotEmpty()){
                                        DropdownMenuItem(
                                            text = { Text("Delete",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                isDropdownMenuExpanded.value = false
                                                isDeleteTaskDialogVisible.value = true
                                            }
                                        )
                                    }

                                }
                            }


                        }


                    },
                    contentColor = MaterialTheme.colorScheme.onSurface,

                    ) {


                    if (changeOrganization) {
                        val currentBoardId = remember { mutableStateOf<String?>(homeState.currentBoardId) }
                        val currentBoardName = remember { mutableStateOf<String?>("") }
                        AlertDialog(containerColor = Color.White,
                            onDismissRequest = { changeOrganization=false },
                            properties = DialogProperties(
                                dismissOnClickOutside = true,
                                dismissOnBackPress = true,
                                usePlatformDefaultWidth = false
                            ),
                            modifier = Modifier.fillMaxWidth(0.55f).fillMaxHeight().padding(vertical = 80.dp),
                            title = {   },
                            text = {
                                Column {

                                    Row {
                                        Text("Change Organisation",
                                            //   fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                            style =  MaterialTheme.typography.bodyMedium,
                                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily
                                            ,
                                            color = Color(0xFF03264A),
                                            fontSize = 24.sp)
                                        Spacer(modifier = Modifier.weight(1f))
                                        Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier=Modifier.clickable { changeOrganization=false })

                                    }
                                    Spacer(modifier = Modifier.height(32.dp))
                                    LazyVerticalGrid(
                                        columns = GridCells.Fixed(3),
                                    ) {
                                        items(boards) { board ->

                                            Card(modifier = Modifier.padding(16.dp).height(80.dp)
                                                .clickable {
                                                    currentBoardId.value = board?.id
                                                    currentBoardName.value = board?.name
                                                }
                                                , colors = CardDefaults.cardColors(
                                                    containerColor = if (currentBoardId.value==board?.id) Color(0xFF3D8BFD)  else Color(0xFFeff4fb)
                                                ), elevation = CardDefaults.cardElevation(defaultElevation = 4.dp)) {
                                                Column(horizontalAlignment = Alignment.Start, verticalArrangement = Arrangement.Center, modifier = Modifier.fillMaxSize().padding(horizontal = 16.dp)) {
                                                    Text(board?.name?:"",
                                                        fontSize = 18.sp,
                                                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                                                        style =  MaterialTheme.typography.bodySmall,
                                                        color = if (currentBoardId.value==board?.id) Color.White  else Color(0xFF03264A)
                                                    )
                                                    Spacer(modifier = Modifier.height(15.dp))
                                                    Text("${board?.memberCount} members",
                                                        fontSize = 14.sp,
                                                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                                                        style =  MaterialTheme.typography.bodySmall,
                                                        color = if (currentBoardId.value==board?.id) Color.White else Color(0xFF5C728A).copy(alpha = 0.4f)
                                                    )
                                                }

                                            }


                                        }
                                    }
                                    Spacer(modifier = Modifier.weight(1f))
                                }

                            },
                            confirmButton = {

                                Row(verticalAlignment = Alignment.CenterVertically) {

                                    TextButton(onClick = {
                                        // changeOrganization=false
                                        viewModel.showCreateBoardDialog()
                                    }){
                                        Text("Create new Organisation", fontSize = 15.sp, style = MaterialTheme.typography.bodyMedium,
                                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                            color = MaterialTheme.colorScheme.primaryContainer)
                                    }
                                    Spacer(modifier = Modifier.weight(1f))
                                    Button(contentPadding = PaddingValues(horizontal = 10.dp, vertical = 5.dp),
                                        modifier = Modifier
                                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.primary),onClick = {
                                            viewModel.currentBoard(currentBoardId.value?:"",currentBoardName.value,true)
                                            viewModel.clearProjects()
                                            viewModel.getBoardProjects()
                                            //viewModel.getBoardMembers(board?.id?:"")
                                            changeOrganization=false
                                        }){
                                        Text("Change", fontSize = 15.sp, style = MaterialTheme.typography.bodyMedium,
                                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                                    }



                                }
                            }
                        )
                    }



                    if (invitePeople) {
                        AlertDialog(containerColor = Color.White,
                            onDismissRequest = {  },
                            properties = DialogProperties(
                                dismissOnClickOutside = false,
                                dismissOnBackPress = true
                            ),
                            title = {  Row {

                                Spacer(modifier = Modifier.weight(1f))
                                Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier=Modifier.clickable { invitePeople=false })

                            } },
                            text = {
                                val emails = remember { mutableStateListOf<String>() }
                                val newEmail = remember { mutableStateOf("") }

                                val textFields = remember { mutableStateListOf<String>() }
                                val newTextFieldValue = remember { mutableStateOf("") }
                                Column(modifier=Modifier.background(Color.White)) {

                                    Text("Invite members",
                                        // fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                        style =  MaterialTheme.typography.bodyMedium,
                                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                        color = Color(0xFF03264A)
                                        , fontSize = 24.sp)
                                    Text("Invite members to zifocus by sharing the link",
                                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style =  MaterialTheme.typography.bodySmall,
                                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily,
                                        color=Color(0xFF5C728A)
                                        , fontSize = 17.sp)
                                    Spacer(modifier=Modifier.height(48.dp))
                                    Column(verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally,
                                        modifier = Modifier.fillMaxWidth()) {
                                        ZiFocusTextField(value = newEmail.value,
                                            onValueChange = { newEmail.value = it }, placeHolder = "Email address",
                                            modifier = Modifier.fillMaxWidth(0.5f))
                                        Spacer(modifier=Modifier.height(32.dp))
                                        for (i in textFields.indices) {
                                            Box {
                                                ZiFocusTextField(value = textFields[i],
                                                    onValueChange = { newValue -> textFields[i] = newValue }, placeHolder = "Email address", modifier = Modifier.fillMaxWidth(0.5f))
                                                Image(painterResource(Res.drawable.x_cross),contentDescription = null,Modifier.clickable { textFields.removeAt(i) }.align(Alignment.CenterEnd))

                                            }

                                            Spacer(modifier=Modifier.height(32.dp))
                                        }
                                        Spacer(modifier=Modifier.height(8.dp))
                                        Row(horizontalArrangement = Arrangement.Center, modifier = Modifier.fillMaxWidth(0.5f)) {

                                            IconButton(
                                                onClick = {  if (newEmail.value.isNotEmpty()) {
                                                    textFields.add(newTextFieldValue.value)
                                                    newTextFieldValue.value = ""
                                                }
                                                }
                                            ) {
                                                Icon(
                                                    imageVector = Icons.Default.Add,
                                                    contentDescription = null,
                                                    tint = Color.Gray,
                                                )
                                            }


                                            Spacer(modifier = Modifier.weight(1f))
                                        }
                                        Spacer(modifier=Modifier.height(32.dp))
                                        Button(modifier=Modifier.width(150.dp),shape = RoundedCornerShape(10.dp),onClick = {
                                            viewModel.sendBoardInvite(textFields+newEmail.value,homeState.currentBoardId)
                                        }) {
                                            Text("Invite")
                                        }


                                    }
                                }
                            },
                            confirmButton = {

                            }
                        )
                    }

                    if (homeState.acceptedBoardInvite!=null){
                        invitePeople=false
                        homeState.acceptedBoardInvite=null
                    }



                    Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.CenterVertically,modifier = Modifier.padding(start = 12.dp).background(Color.Transparent,
                        CircleShape)
                        .hoverable(interactionSourceTarget)
                    ) {


                        val rotationAngle: Float by animateFloatAsState(
                            targetValue = if (expanded) 90f else 0f,
                            animationSpec = tween(durationMillis = 300)
                        )
                        Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.CenterVertically,modifier = Modifier.padding(start = 12.dp).background(Color.Transparent,
                            CircleShape)
                            .clickable { expanded = !expanded }) {
                            Icon(
                                painter = painterResource(Res.drawable.arrow),
                                contentDescription = null,
                                tint = Color.Gray,
                                modifier = Modifier
                                    .rotate(rotationAngle).size(18.dp)
                            )

                            Text("Workspaces",fontSize = 18.sp,
                                //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                style =  MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                textAlign = TextAlign.Start,
                                modifier=Modifier.padding(start = 8.dp, end = 12.dp),
                                color = Color(0xFF03264A)
                            )
                        }

                        Spacer(modifier = Modifier.weight(1f))


                            Icon(
                                imageVector = Icons.Default.Add,
                                contentDescription = null,
                                tint = Color(0xFF3D8BFD),
                                modifier = Modifier.alpha(if (isHoveredTarget)1f else 0f).size(24.dp).clickable { viewModel.showCreateWorkSpaceDialog() }
                            )


                    }
                    Spacer(modifier=Modifier.height(6.dp))
                    AnimatedVisibility(expanded){
                        if (projects.isNotEmpty()||homeState.loadedProject){
                            // User Boards
                            Column(modifier=Modifier.fillMaxWidth().padding(start = 48.dp)) {
                                projects.forEach {project->
                                    Spacer(modifier=Modifier.height(6.dp))
                                    Text(project?.projectName?:"",fontSize = 16.sp,
                                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style =  MaterialTheme.typography.bodySmall,
                                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A),
                                        modifier=Modifier.clickable(onClick = {
                                            homeState.tasks.clear()
                                            homeState.loadedTasks=false
                                            viewModel.selectWorkSpace(project?: Project(),project?.id?:"",if (homeState.selectedWorkspaceId!=null)true else false)
                                            viewModel.getWorkspaceTasks()

                                        }, onClickLabel = "Select", role = Role.Button)
                                    )
                                    Spacer(modifier=Modifier.height(12.dp))

                                }
                            }

                        } else {
                            CircularProgressIndicator()
                        }
                    }

                    Spacer(modifier=Modifier.height(12.dp))
                    Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.CenterVertically,modifier = Modifier.padding(start = 12.dp).background(Color.Transparent,
                        CircleShape)
                        .hoverable(interactionSourcePeople)
                    ) {

                        val rotationAngle: Float by animateFloatAsState(
                            targetValue = if (peopleExpanded) 90f else 0f,
                            animationSpec = tween(durationMillis = 300)
                        )
                        Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.CenterVertically,modifier = Modifier.padding(start = 12.dp).background(Color.Transparent,
                            CircleShape)
                            .clickable {
                                peopleExpanded = !peopleExpanded
                                viewModel.getBoardMembers(homeState.currentBoardId?:"")

                            }) {
                            Icon(
                                painter = painterResource(Res.drawable.arrow),
                                contentDescription = null,
                                tint = Color.Gray,
                                modifier = Modifier
                                    .rotate(rotationAngle).size(18.dp)
                            )
                            Text("People",fontSize = 18.sp,
                                //fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                style =  MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                textAlign = TextAlign.Start,
                                modifier=Modifier.padding(start = 8.dp,end = 12.dp),
                                color = Color(0xFF03264A)
                            )
                        }

                        Spacer(modifier = Modifier.weight(1f))
                            Icon(
                                imageVector = Icons.Default.Add,
                                contentDescription = null,
                                tint = Color(0xFF3D8BFD),
                                modifier = Modifier.alpha(if (isHoveredPeople)1f else 0f).size(24.dp).clickable { invitePeople = true }
                            )


                    }

                    AnimatedVisibility(peopleExpanded){
                        if (boardMembers.isNotEmpty()){
                            // User Boards
                            LazyColumn(modifier=Modifier.fillMaxWidth().heightIn(max=250.dp).padding(start = 24.dp)) {
                                items(boardMembers){member->
                                    Spacer(modifier=Modifier.height(6.dp))
                                    Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.clickable {
                                        homeState.boardMember = member

                                        navigatorParent.push(ProfileScreen(true))
                                    }) {
                                        if (member?.avatar.isNullOrEmpty()){
                                            val name = member?.name?:""
                                            val words = name.split(" ")
                                            val initials = if (words.size > 1) {
                                                words[0].take(1) + words[words.lastIndex].take(1)
                                            } else {
                                                words[0].take(2)
                                            }.uppercase()
                                            TextButton(onClick = {

                                            },modifier = Modifier
                                                .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(0.dp,0.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                Text(
                                                    text = initials,
                                                    fontSize = 18.sp,
                                                    color = Color.White,
                                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                    maxLines = 1,
                                                    overflow = TextOverflow.Ellipsis,
                                                    textAlign = TextAlign.Center
                                                )
                                            }

                                        }
                                        else {
                                            Image(
                                                rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                    .size(35.dp)
                                                    .clip(CircleShape)
                                                    .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                        }
                                        Spacer(modifier=Modifier.width(6.dp))
                                        Text(if (member?.id==homeState.user?.id)"${member?.name} (me)"?:"" else member?.name?:"",fontSize = 14.sp,
                                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                            style =  MaterialTheme.typography.bodySmall,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                            textAlign = TextAlign.Start,
                                            color = Color(0xFF5C728A),
                                            modifier=Modifier.padding(end = 12.dp),
                                        )
                                    }

                                    Spacer(modifier=Modifier.height(6.dp))
                                }
                            }


                        }
                    }


                    Spacer(modifier=Modifier.height(12.dp))
                    Row(modifier=Modifier
                        .fillMaxWidth()
                        .padding(start = 24.dp)
                        .clickable {

                            navigator.push(GoalsScreen) }
                        ,verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Start) {
                        Image(painterResource(Res.drawable.goals),modifier=Modifier.size(18.dp), contentDescription = null)
                        Text("Goals",fontSize = 18.sp,
                            //fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                            style =  MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                            textAlign = TextAlign.Start,
                            modifier=Modifier.padding(start = 8.dp,end = 12.dp),
                            color = Color(0xFF03264A)
                        )
                    }



                    Spacer(modifier=Modifier.height(12.dp))


                    var expandedLoop by remember { mutableStateOf(false) }
                    val interactionSourceLoop = remember { MutableInteractionSource() }
                    val isHoveredLoop = interactionSourceLoop.collectIsHoveredAsState().value
                    Row(modifier=Modifier
                        .fillMaxWidth()
                        .padding(start = 6.dp)
                        .hoverable(interactionSourceLoop)

                        ,verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Start) {

                        val rotationAngle: Float by animateFloatAsState(
                            targetValue = if (expandedLoop) 90f else 0f,
                            animationSpec = tween(durationMillis = 300)
                        )
                        Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.CenterVertically,modifier = Modifier.padding(start = 12.dp).background(Color.Transparent,
                            CircleShape)
                            .clickable {
                                viewModel.getAllLoopList()
                                println("$loopComponents \n ${homeState.loopComponentsList}")
                                expandedLoop = !expandedLoop
                            }.hoverable(interactionSourceLoop))
                        {
                            Icon(
                                painter = painterResource(Res.drawable.arrow),
                                contentDescription = null,
                                tint = Color.Gray,
                                modifier = Modifier
                                    .rotate(rotationAngle).size(18.dp)
                            )

                            Text("Loop",fontSize = 18.sp,
                                //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                style =  MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                textAlign = TextAlign.Start,
                                modifier=Modifier.padding(start = 8.dp, end = 12.dp),
                                color = Color(0xFF03264A)
                            )

                        }
                        Spacer(modifier = Modifier.weight(1f))


                        Icon(
                            imageVector = Icons.Default.Add,
                            contentDescription = null,
                            tint = Color(0xFF3D8BFD),
                            modifier = Modifier
                                .padding(end = 12.dp)
                                .alpha(if (isHoveredLoop)1f else 0f)
                                .size(24.dp).clickable { createNewLoopDialog.value = true }
                        )

                    }

                    Spacer(modifier=Modifier.height(6.dp))
                    AnimatedVisibility(expandedLoop){
                        if (loopComponents?.isNotEmpty()==true){
                            // User Boards
                            Column(modifier=Modifier.fillMaxWidth().padding(start = 48.dp)) {
                                loopComponents?.forEach {loop->
                                    Spacer(modifier=Modifier.height(6.dp))
                                    Text(loop.title?:"",fontSize = 16.sp,
                                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                        style =  MaterialTheme.typography.bodySmall,
                                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A),
                                        modifier=Modifier.clickable(onClick = {
                                            viewModel.getNewLoopDetails(loopId = loop.id?:"")
                                            viewModel.setCurrentLoop(loop)
                                            navigator.push(LoopComponentScreen)

                                        }, onClickLabel = "Select", role = Role.Button)
                                    )
                                    Spacer(modifier=Modifier.height(12.dp))

                                }
                            }

                        }
                    }


                    Spacer(modifier=Modifier.height(12.dp))
                    Row(modifier=Modifier
                        .fillMaxWidth()
                        .padding(start = 24.dp)
                        .clickable {

                            navigator.push(DocsScreen) }
                        ,verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Start) {
                        Image(painterResource(Res.drawable.zi_pages),modifier=Modifier.size(18.dp), contentDescription = null)
                        Text("Zipages",fontSize = 18.sp,
                            //fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                            style =  MaterialTheme.typography.bodyMedium,
                            fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                            textAlign = TextAlign.Start,
                            modifier=Modifier.padding(start = 8.dp,end = 12.dp),
                            color = Color(0xFF03264A)
                        )
                    }
                    Spacer(modifier=Modifier.weight(1f))
                    /*
                    Row(horizontalArrangement = Arrangement.Center, modifier = Modifier.padding(start = 48.dp).background(Color.Transparent,
                        CircleShape)
                        .clickable {  }


                    ) {
                        Icon(
                            modifier= Modifier.padding(end=4.dp),
                            painter = rememberVectorPainter(Icons.Default.Settings) ,
                            contentDescription = "Settings",
                        )
                        Text(text = "Settings",
                            fontSize = 16.sp,
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                            style =  MaterialTheme.typography.bodyMedium,
                            textAlign = TextAlign.Start,
                            modifier=Modifier.fillMaxWidth(),
                        )
                        // Spacer(modifier=Modifier.weight(1f))
                    }

                    Spacer(modifier.height(24.dp))
                    Row(horizontalArrangement = Arrangement.Center, modifier = Modifier.padding(start = 48.dp).background(Color.Transparent,
                        CircleShape)
                        .clickable {
                            val settings: Settings = Settings()

                            settings.clear()
                            navigator.replace(LoginScreen())

                        }


                    ) {
                        Icon(
                            modifier= Modifier.padding(end=4.dp),
                            painter = rememberVectorPainter(Icons.Default.ExitToApp),
                            contentDescription = "Sign Out",

                            )
                        Text(text = "Sign Out",
                            fontSize = 16.sp,
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                            style =  MaterialTheme.typography.bodyMedium,
                            textAlign = TextAlign.Start,
                            modifier=Modifier.fillMaxWidth(),
                        )

                    }
                    Spacer(modifier=Modifier.height(51.dp))
                    */


                }
            }

        }
        val rotationAngle: Float by animateFloatAsState(
            targetValue = if (!fullScreenMode.value) 180f else 0f,
            animationSpec = tween(durationMillis = 300)
        )
        Button(onClick = {
            fullScreenMode.value=!fullScreenMode.value
        },modifier=Modifier.align(Alignment.CenterEnd).width(20.dp).height(80.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(),
            colors = ButtonDefaults.buttonColors(containerColor = Color(0xFFD9D9D9))
        ){

            Icon(
                painter = painterResource(Res.drawable.arrow),
                contentDescription = null,
                tint = Color.Gray,
                modifier = Modifier
                    .rotate(rotationAngle)
            )
        }
    }


}
@Composable
fun ArrowIcon() {
    var expanded by remember { mutableStateOf(false) }
    val rotationAngle: Float by animateFloatAsState(
        targetValue = if (expanded) 90f else 0f,
        animationSpec = tween(durationMillis = 300)
    )
    Icon(
        imageVector = Icons.Default.ArrowRight,
        contentDescription = null,
        modifier = Modifier
            .size(35.dp)
            .clickable { expanded = !expanded }
            .rotate(rotationAngle)
    )
}

@OptIn(ExperimentalMaterial3Api::class, ExperimentalResourceApi::class)
@Composable
fun FeaturesList(boardId:String,projectId:String,viewModel: HomeViewModel) {
    val homeState = viewModel.homeState.collectAsState()
    val features = remember { homeState.value.features }
    val newFeature = remember { mutableStateOf("") }
    val editing = remember { mutableStateOf(false) }

    Column(modifier = Modifier.padding(start = 90.dp).fillMaxWidth()) {
        features.forEach { feature ->
            Row(verticalAlignment = Alignment.CenterVertically) {
                Icon(painter = painterResource(Res.drawable.arrow),contentDescription = null,
                    modifier=Modifier)
                Text(text = feature.title?:"",fontSize = 14.sp,
                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                    style =  MaterialTheme.typography.bodySmall,
                    textAlign = TextAlign.Start,
                    modifier=Modifier.padding(end = 12.dp))
            }

        }

        if (editing.value) {


            BasicTextField(
                modifier = Modifier
                    .background(MaterialTheme.colorScheme.surface, RoundedCornerShape(4.dp))
                    .height(25.dp),
                value = newFeature.value,
                singleLine = true,
                keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Done),
                keyboardActions = KeyboardActions(onDone={
                    editing.value=false
                    if(newFeature.value.isNotBlank()) {
                        //hit API to create board  features.add(newFeature.value)
                        newFeature.value=""
                    }
                }),
                onValueChange = {
                    newFeature.value = it
                },
                decorationBox = { innerTextField ->
                    if (newFeature.value.isEmpty()) {
                        Text(
                            text = "Feature name",
                            color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.3f),
                            fontSize = 10.sp,
                        )

                    }
                    Row {
                        innerTextField()
                        Spacer(modifier = Modifier.weight(1f))
                        if (newFeature.value.isNotEmpty()){
                            Icon(Icons.Default.Add,null, modifier = Modifier.clickable {
                                //hit api here features.add(newFeature.value)
                                newFeature.value=""
                                editing.value=false
                            })
                        }
                        Icon(Icons.Default.Delete,null, modifier = Modifier.clickable {
                            editing.value=false
                            newFeature.value="" })
                    }

                }
            )

        } else {
            Row(modifier=Modifier.clickable {
                editing.value=true
            }) {
                Icon(Icons.Default.Add,contentDescription = null, tint = MaterialTheme.colorScheme.primaryContainer,
                    modifier=Modifier)
                Text("Add Feature",fontSize = 14.sp,
                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                    style =  MaterialTheme.typography.bodySmall,
                    textAlign = TextAlign.Start,
                    modifier=Modifier.padding(end = 12.dp))
            }
            /*  BasicTextField(
                  value = newFeature.value,
                  onValueChange = { newFeature.value = it },
                  cursorBrush = SolidColor(Color.Black)
              ) */

        }
    }
}

