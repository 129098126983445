package org.company.app.presentation.ui.board

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.*

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape

import androidx.compose.material.Scaffold
import androidx.compose.material.Surface
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.*
import androidx.compose.material.rememberScaffoldState
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mohamedrejeb.compose.dnd.DragAndDropContainer
import com.mohamedrejeb.compose.dnd.drag.DraggableItem
import com.mohamedrejeb.compose.dnd.drop.dropTarget
import com.mohamedrejeb.compose.dnd.rememberDragAndDropState
import com.seiko.imageloader.rememberImagePainter
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock

import org.company.app.platformName
import org.company.app.presentation.ui.carddetails.CardDetailsScreen
import org.company.app.presentation.utils.dateTimeToString
import org.company.app.presentation.utils.selectedDateMillisToLocalDateTime
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.koin.compose.koinInject
import kotlinx.datetime.Instant
import multiplatform_app.composeapp.generated.resources.*
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.kanban_done_
import multiplatform_app.composeapp.generated.resources.pause
import org.company.app.presentation.component.CreateTaskDialog
import org.company.app.presentation.component.CustomAlertDialog
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.utils.getRandomColor
import org.company.app.presentation.utils.getRandomColorForColumns
import org.jetbrains.compose.resources.painterResource
import org.openapitools.client.models.*
import kotlin.time.Duration


class TaskBoardScreen(
    val navigateWithRouter: () -> Unit = {},
    val navigateBackFromCardDetails: () -> Unit,
    val taskId: MutableState<String?>? = null,

) : Screen {
   @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
   @Composable
    override fun Content() {
       val windowSizeClass = calculateWindowSizeClass()
       val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
       val homeViewModel = koinInject<HomeViewModel>()
       val navigator = LocalNavigator.currentOrThrow
       val isLoading = remember { mutableStateOf(false) }

           if (taskId?.value!=null){
               homeViewModel.getTaskDetails(taskId.value) {
                   navigator.push(
                       CardDetailsScreen(
                           navigateBackFromCardDetails
                       )
                   )

               }

           }
       if (isLoading.value){
           Column(modifier = Modifier.fillMaxSize(), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
               CircularProgressIndicator()
           }

       } else {
           TaskBoardRoute(navigateToCardDetail = {
               isLoading.value=true
               homeViewModel.currentTask(it)
               homeViewModel.getTaskDetails(it.id) {
                   if (platformName() == "Web") navigateWithRouter()
                   homeViewModel.navigateToHome(false)
                   navigator.push(CardDetailsScreen( navigateBackFromCardDetails))

               }



           },
               isExpandedScreen = useNavRail)
       }


    }

    @OptIn( ExperimentalResourceApi::class, ExperimentalMaterial3Api::class)
    @Composable
    fun TaskBoardRoute(
        modifier: Modifier = Modifier,
        isExpandedScreen: Boolean = false,
        navigateToCardDetail: (TaskItem) -> Unit = {},
        backgroundColor: Color = MaterialTheme.colorScheme.background,
    ) {
        val homeViewModel = koinInject<HomeViewModel>()
        val homeState by homeViewModel.homeState.collectAsState()
        val tasks = homeState.tasks
        val projects = homeState.projects
        val selectedProject = homeState.selectedProject
        val loadingNextPage = remember { mutableStateOf(false) }
        val statusFilter= remember { mutableStateOf("") }
        val priorityFilter= remember { mutableStateOf("") }
        val dueDateFilter= remember { mutableStateOf("") }
        val assignedToFilter = remember { mutableStateOf(BoardMember()) }
        val statusChange = remember{ mutableStateOf(false) }
        val priorityChange = remember{ mutableStateOf(false) }
        val assignToChange = remember{ mutableStateOf(false) }





        // tasks to receive from BE
        var workspace = homeState.selectedWorkspace?:"Select Workspace"

        var createTask by remember { mutableStateOf(false) }

        val isDeleteTaskDialogVisible = remember { mutableStateOf(false) }
        val isDropdownMenuExpanded = remember { mutableStateOf(false) }
        val isFilterMenuExpanded = remember { mutableStateOf(false) }
        val workSpaceDelete = remember { mutableStateOf(false) }


        var dateSelected =rememberDatePickerState(yearRange = 2023..2050,)
        var datePicker = remember { mutableStateOf(false) }
        // var dueDate by remember { mutableStateOf("") }
        var dueDate by mutableStateOf(dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString())


        val scaffoldState = rememberScaffoldState()


        if (workSpaceDelete.value){
            LaunchedEffect(projects.isNotEmpty()){
                if (projects.isNotEmpty()){
                    homeState.tasks.clear()
                    homeState.loadedTasks=false
                    homeViewModel.selectWorkSpace(projects.firstOrNull()?: Project(),projects.firstOrNull()?.id?:"",if (homeState.selectedWorkspaceId!=null)true else false)
                    homeViewModel.getWorkspaceTasks()
                    workSpaceDelete.value=false
                }
            }
        }


        if (isDeleteTaskDialogVisible.value){
            CustomAlertDialog(title = "Delete Workspace", description = "Are you sure you want to delete (${workspace}) workspace?",onDelete = {
               homeViewModel.deleteProject(homeState.selectedWorkspaceId?:""){
                   workSpaceDelete.value=true
               }
                isDeleteTaskDialogVisible.value=false

            }, onDismiss = {
                isDeleteTaskDialogVisible.value=false
            })
        }


        if (homeState.task!=null){
            createTask=false
        }
        if (homeState.errorMessage!=null) {
            createTask=false
           /* AlertDialog(
                onDismissRequest = {  },
                properties = DialogProperties(
                    dismissOnClickOutside = false,
                    dismissOnBackPress = true
                ),
                title = {  },
                text = { Text(homeState.errorMessage?:"Error") },
                confirmButton = {
                    Button(
                        modifier = Modifier.fillMaxWidth(),
                        onClick = {
                            homeViewModel.clearUIState()

                        }
                    ) {
                        Text("Dismiss")
                    }
                }
            ) */
        }



        if (datePicker.value){
            DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
                TextButton(onClick = {
                    dueDateFilter.value = dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString()
                    datePicker.value = false
                    homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id, dueDate = dueDateFilter.value)

                }) {
                    Text("Confirm",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall
                        , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
                }
            }, colors = DatePickerDefaults.colors(containerColor = Color(0xFFFAFAFA))){
                DatePicker(
                    state = dateSelected,
                    modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                    colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
                )
            }
        }


        Scaffold(
            scaffoldState = scaffoldState,

            ) { scaffoldPaddingValues ->
            Surface(
                modifier = modifier
                    .fillMaxSize()
                    .padding(scaffoldPaddingValues),
                color = backgroundColor
            ) {
                if (createTask){
                    AlertDialog(
                        onDismissRequest = { createTask=false },
                        properties = DialogProperties(
                            dismissOnClickOutside = true,
                            dismissOnBackPress = true,
                            usePlatformDefaultWidth = false
                        ),
                        modifier = Modifier.fillMaxWidth(0.7f).padding(40.dp),
                        containerColor = Color(0xFFFAFAFA),
                        title = {
                            Row {

                                Spacer(modifier = Modifier.weight(1f))
                                Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier=Modifier.clickable { createTask=false })


                            }
                        },
                        text = {
                            CreateTaskDialog(onDismiss = {createTask=false})
                               },
                        confirmButton = {

                        }
                    )
                }
                Box(contentAlignment = Alignment.Center) {
                    AnimatedVisibility(loadingNextPage.value){
                        Box(modifier=Modifier.fillMaxSize()) {
                            CircularProgressIndicator(modifier=Modifier.align(Alignment.Center))
                        }
                    }
                    Column(
                        modifier = Modifier.fillMaxSize(),

                        ) {

                        Row(modifier = Modifier.fillMaxWidth().padding( horizontal =if (isExpandedScreen) 20.dp else 4.dp).padding(top = 20.dp)) {
                            Text(workspace, fontSize = 24.sp,
                                style =  MaterialTheme.typography.bodyLarge,
                                fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                                color = Color(0xFF032549)
                            )
                            Spacer(modifier=Modifier.weight(1f))
                            if(workspace!="Select Workspace"){
                                Button(contentPadding = PaddingValues(horizontal = 10.dp, vertical = 4.dp), modifier = Modifier.defaultMinSize(1.dp,1.dp),onClick = {createTask = true}, colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.primaryContainer)){
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Icon(Icons.Default.Add,null)
                                        Text(
                                            text = "Task",
                                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                            style = MaterialTheme.typography.bodySmall,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                            fontSize = 14.sp,
                                            modifier = Modifier.padding(start = 4.dp)
                                        )
                                    }
                                }
                                Spacer(modifier=Modifier.width(12.dp))
                                Box {

                                    // kachwaa
                                    IconButton(
                                        onClick = { isFilterMenuExpanded.value = !isFilterMenuExpanded.value }
                                    ) {
                                        Icon(
                                            painter = painterResource(Res.drawable.filter) ,
                                            contentDescription = "Filter"
                                        )
                                    }

                                    DropdownMenu(
                                        expanded = isFilterMenuExpanded.value,
                                        onDismissRequest = { isFilterMenuExpanded.value = false },
                                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA), shape = RoundedCornerShape(6.dp))
                                    ){
                                        Column(modifier=Modifier.padding(8.dp)) {
                                            // change here
                                           // selectedProject?.taskStatusDetails?: arrayListOf()
                                                Row {
                                                    Box {
                                                        OutlinedButton(colors = ButtonDefaults.outlinedButtonColors(containerColor = if (statusFilter.value=="") Color.Transparent else MaterialTheme.colorScheme.primaryContainer),onClick = {statusChange.value =true},border = BorderStroke(1.dp, Color(0xFFD9D9D9)),){
                                                            Text(if (statusFilter.value=="")"Status" else
                                                                (selectedProject?.taskStatusDetails?.find { it.id==statusFilter.value}?.name)?:"Status"
                                                                ,
                                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                color = if (statusFilter.value=="") Color(0xFF5C728A) else Color.White,
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                                , fontSize = 15.sp)
                                                        }
                                                        DropdownMenu(
                                                            expanded = statusChange.value,
                                                            onDismissRequest = { statusChange.value = false },
                                                            modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA)).padding(16.dp)
                                                        ){
                                                            Column(
                                                                modifier = Modifier.fillMaxSize(),
                                                                verticalArrangement = Arrangement.Center,
                                                                horizontalAlignment = Alignment.CenterHorizontally
                                                            ) {
                                                                // honaa
                                                                selectedProject?.taskStatusDetails?.forEachIndexed { index, column ->
                                                                    TextButton(modifier = Modifier.fillMaxWidth(),onClick = {
                                                                        statusFilter.value = column.id?:""
                                                                        statusChange.value = false
                                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id,dueDate = dueDateFilter.value)
                                                                    },
                                                                        colors = ButtonDefaults.textButtonColors(containerColor = Color(0xFFCFE2FF))){
                                                                        Text(column.name?:"", style = MaterialTheme.typography.bodySmall, fontSize = 12.sp, color = Color.Black)
                                                                    }

                                                                    Spacer(modifier = Modifier.height(14.dp))
                                                                }




                                                            }

                                                        }
                                                    }

                                                    Spacer(modifier=Modifier.width(12.dp))


                                                    Box {
                                                        OutlinedButton(colors = ButtonDefaults.outlinedButtonColors(containerColor = if (priorityFilter.value=="") Color.Transparent else MaterialTheme.colorScheme.primaryContainer),onClick = {priorityChange.value=true},border = BorderStroke(1.dp, Color(0xFFD9D9D9)),){
                                                            Text(if (priorityFilter.value=="")"Priority" else priorityFilter.value,
                                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                color = if (priorityFilter.value=="") Color(0xFF5C728A) else Color.White,
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                                , fontSize = 15.sp)
                                                        }
                                                        DropdownMenu(
                                                            expanded = priorityChange.value,
                                                            onDismissRequest = { priorityChange.value = false },
                                                            modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA)).padding(16.dp)
                                                        ){
                                                            Column (modifier = Modifier.fillMaxWidth(),verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally){

                                                                OutlinedButton(
                                                                    border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
                                                                    modifier = Modifier.fillMaxWidth(),onClick = {
                                                                        priorityFilter.value ="Urgent"
                                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id,dueDate = dueDateFilter.value)
                                                                        priorityChange.value=false
                                                                    },){
                                                                    Row(verticalAlignment = Alignment.CenterVertically) {

                                                                        Image(
                                                                            painter = painterResource(Res.drawable.urgent_flag),
                                                                            contentDescription = null,
                                                                        )

                                                                        Spacer(modifier = Modifier.width(4.dp))
                                                                        Text("Urgent", color = Color(0xFF03264A),textAlign = TextAlign.Center,
                                                                            style = MaterialTheme.typography.bodySmall,
                                                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                            fontSize = 14.sp,)
                                                                        Spacer(modifier = Modifier.weight(1f))
                                                                    }
                                                                }
                                                                Spacer(modifier = Modifier.height(14.dp))
                                                                OutlinedButton(
                                                                    border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
                                                                    modifier = Modifier.fillMaxWidth(),onClick = {
                                                                        priorityFilter.value ="Medium"
                                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id)
                                                                        priorityChange.value=false
                                                                    },){
                                                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                                                        Image(
                                                                            painter = painterResource(Res.drawable.medium_flag),
                                                                            contentDescription = null,
                                                                        )
                                                                        Spacer(modifier = Modifier.width(4.dp))
                                                                        Text("Medium", color = Color(0xFF03264A), textAlign = TextAlign.Center,
                                                                            style = MaterialTheme.typography.bodySmall,
                                                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                            fontSize = 14.sp,)
                                                                        Spacer(modifier = Modifier.weight(1f))
                                                                    }
                                                                }
                                                                Spacer(modifier = Modifier.height(14.dp))
                                                                OutlinedButton(
                                                                    border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
                                                                    modifier = Modifier.fillMaxWidth(),onClick = {
                                                                        priorityFilter.value ="Low"
                                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id)
                                                                        priorityChange.value=false
                                                                    },){
                                                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                                                        Image(
                                                                            painter = painterResource(Res.drawable.low_flag),
                                                                            contentDescription = null,
                                                                        )
                                                                        Spacer(modifier = Modifier.width(4.dp))
                                                                        Text("Low", color = Color(0xFF03264A), textAlign = TextAlign.Center,
                                                                            style = MaterialTheme.typography.bodySmall,
                                                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                            fontSize = 14.sp,)
                                                                        Spacer(modifier = Modifier.weight(1f))
                                                                    }
                                                                }

                                                            }


                                                        }
                                                    }


                                                    Spacer(modifier=Modifier.width(12.dp))

                                                    Box {
                                                        //Collecting states from ViewModel
                                                        val searchText by homeViewModel.searchText.collectAsState() // Query for SearchBar
                                                        val isSearching by homeViewModel.isSearching.collectAsState() // Active state for SearchBar
                                                        val countriesList by homeViewModel.boardMemberList.collectAsState()
                                                        OutlinedButton(colors = ButtonDefaults.outlinedButtonColors(containerColor = if (assignedToFilter.value.id.isNullOrEmpty()) Color.Transparent else MaterialTheme.colorScheme.primaryContainer),onClick = {assignToChange.value =true},border = BorderStroke(1.dp, Color(0xFFD9D9D9)),){
                                                            Text(if (assignedToFilter.value.id.isNullOrEmpty())"Assigned to" else assignedToFilter.value.name?:"Assigned to",
                                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                color = if (assignedToFilter.value.id.isNullOrEmpty()) Color(0xFF5C728A) else Color.White,
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                                , fontSize = 15.sp)
                                                        }

                                                            DropdownMenu(
                                                                expanded = assignToChange.value,
                                                                onDismissRequest = { assignToChange.value = false },
                                                                modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                                            ){
                                                                Text("Assign to", fontSize = 16.sp,
                                                                    style = MaterialTheme.typography.displayMedium
                                                                )
                                                                DockedSearchBar(
                                                                    query = searchText,//text showed on SearchBar
                                                                    onQueryChange = homeViewModel::onSearchTextChange, //update the value of searchText
                                                                    onSearch = homeViewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                                                                    active = true, //whether the user is searching or not
                                                                    onActiveChange = { homeViewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                                                                    modifier = Modifier
                                                                        .fillMaxWidth()
                                                                        .padding(16.dp)
                                                                    ,
                                                                    placeholder = {
                                                                        Text(text = "Search members", fontSize = 15.sp, style = MaterialTheme.typography.bodySmall)
                                                                    },
                                                                    leadingIcon = {
                                                                        Icon(imageVector = Icons.Default.Search, contentDescription = null)
                                                                    },
                                                                    colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                                                                ) {
                                                                    countriesList.let {
                                                                        Column(
                                                                            modifier = Modifier
                                                                                .fillMaxWidth()
                                                                                .heightIn(max=250.dp)
                                                                                .verticalScroll(state = rememberScrollState())
                                                                        ) {
                                                                            it.forEach { member ->
                                                                                TextButton(
                                                                                    onClick = {
                                                                                        assignedToFilter.value = member?: BoardMember()
                                                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = member?.id,dueDate = dueDateFilter.value)
                                                                                        assignToChange.value = false
                                                                                    }
                                                                                ) {
                                                                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                                                                        if (member?.avatar.isNullOrEmpty()){
                                                                                            val name = member?.name?:""
                                                                                            val words = name.split(" ")
                                                                                            val initials = if (words.size > 1) {
                                                                                                words[0].take(1) + words[words.lastIndex].take(1)
                                                                                            } else {
                                                                                                words[0].take(2)
                                                                                            }.uppercase()
                                                                                            TextButton(onClick = {},modifier = Modifier
                                                                                                .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                                                                                Text(
                                                                                                    text = initials,
                                                                                                    fontSize = 16.sp,
                                                                                                    color = Color.White,
                                                                                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                                                    maxLines = 1,
                                                                                                    overflow = TextOverflow.Ellipsis,
                                                                                                    textAlign = TextAlign.Center
                                                                                                )
                                                                                            }

                                                                                        }
                                                                                        else {
                                                                                            Image(
                                                                                                rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                                                                    .size(35.dp)
                                                                                                    .clip(CircleShape)
                                                                                                    .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                                                        }
                                                                                        Spacer(modifier = Modifier.width(6.dp))

                                                                                        Text(if (member?.id==homeState.user?.id) "${member?.name} - @Me" else member?.name?:"",
                                                                                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                                            style =  MaterialTheme.typography.bodySmall,
                                                                                            fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                                                                    }

                                                                                }

                                                                            }
                                                                        }
                                                                    }
                                                                }



                                                            }


                                                    }
                                                    Spacer(modifier=Modifier.width(12.dp))
                                                    OutlinedButton(colors = ButtonDefaults.outlinedButtonColors(containerColor = if (dueDateFilter.value.isNullOrEmpty()) Color.Transparent else MaterialTheme.colorScheme.primaryContainer),onClick = {
                                                        datePicker.value = true

                                                    },border = BorderStroke(1.dp, Color(0xFFD9D9D9)),){
                                                        Text(if (dueDateFilter.value=="") "Due date" else  formatDateString(dueDate),
                                                            // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                            color = if (dueDateFilter.value.isNullOrEmpty()) Color(0xFF5C728A) else Color.White,
                                                            style =  MaterialTheme.typography.bodySmall,
                                                            fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                            , fontSize = 15.sp)
                                                    }

                                                }
                                            if (priorityFilter.value!=""||statusFilter.value!=""||!assignedToFilter.value.id.isNullOrEmpty()||!dueDate.contains("1970")){
                                                Spacer(modifier=Modifier.height(24.dp))
                                                Text("Applied",
                                                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                    modifier=Modifier.padding(start = 8.dp),
                                                    color = Color(0xFF5C728A),
                                                    style =  MaterialTheme.typography.bodySmall,
                                                    fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                    , fontSize = 15.sp)
                                                Spacer(modifier=Modifier.height(8.dp))
                                            }

                                            Row(horizontalArrangement = Arrangement.Start) {
                                                if (statusFilter.value!=""){
                                                    OutlinedButton(modifier=Modifier.defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(vertical = 5.dp, horizontal = 12.dp),onClick = {
                                                                             statusFilter.value=""
                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id, dueDate = dueDateFilter.value)
                                                    },){
                                                        Box {

                                                            Text(if (statusFilter.value=="")"Status" else
                                                                (selectedProject?.taskStatusDetails?.find { it.id==statusFilter.value}?.name)?:"Status",
                                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                modifier=Modifier.padding(end = 24.dp),
                                                                color = Color(0xFF5C728A),
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                                , fontSize = 15.sp)

                                                            Image(painterResource(Res.drawable.remove_filter),modifier=Modifier.align(
                                                                Alignment.CenterEnd),contentDescription = null)
                                                        }

                                                    }
                                                }
                                                Spacer(modifier=Modifier.width(8.dp))
                                                if (priorityFilter.value!=""){
                                                    OutlinedButton(modifier=Modifier.defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(vertical = 5.dp, horizontal = 12.dp),onClick = {
                                                        priorityFilter.value=""
                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id, dueDate = dueDateFilter.value)
                                                    },){
                                                        Box {
                                                            Text(priorityFilter.value,
                                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                modifier=Modifier.padding(end = 24.dp),
                                                                color = Color(0xFF5C728A),
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                                , fontSize = 15.sp)

                                                            Image(painterResource(Res.drawable.remove_filter),modifier=Modifier.align(
                                                                Alignment.CenterEnd),contentDescription = null)
                                                        }

                                                    }
                                                }
                                                Spacer(modifier=Modifier.width(8.dp))
                                                if (!assignedToFilter.value.id.isNullOrEmpty()){
                                                    OutlinedButton(modifier=Modifier.defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(vertical = 5.dp, horizontal = 12.dp),onClick = {
                                                        assignedToFilter.value=BoardMember()
                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id, dueDate = dueDateFilter.value)
                                                    },){
                                                        Box {
                                                            Text(assignedToFilter.value.name?:"Assigned to",
                                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                modifier=Modifier.padding(end = 24.dp),
                                                                color = Color(0xFF5C728A),
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                                , fontSize = 15.sp)

                                                            Image(painterResource(Res.drawable.remove_filter),modifier=Modifier.align(
                                                                Alignment.CenterEnd),contentDescription = null)
                                                        }

                                                    }
                                                }
                                                Spacer(modifier=Modifier.width(8.dp))
                                                if (dueDateFilter.value!=""){
                                                    OutlinedButton(modifier=Modifier.defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(vertical = 5.dp, horizontal = 12.dp),onClick = {
                                                        dueDateFilter.value = ""
                                                        homeViewModel.getWorkspaceTasks(taskStatus = statusFilter.value, priority = priorityFilter.value, assignedTo = assignedToFilter.value.id, dueDate = dueDateFilter.value)
                                                    },){
                                                        Row {
                                                            Text(if (dueDateFilter.value=="") "Due date" else  formatDateString(dueDate),
                                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                color = Color(0xFF5C728A),
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                                , fontSize = 15.sp)

                                                            Image(painterResource(Res.drawable.remove_filter),modifier=Modifier.padding(start = 4.dp),contentDescription = null)
                                                        }

                                                    }
                                                }


                                            }


                                        }



                                    }
                                }
                                Spacer(modifier=Modifier.width(3.dp))
                                Box {

                                    IconButton(
                                        onClick = { isDropdownMenuExpanded.value = !isDropdownMenuExpanded.value }
                                    ) {
                                        Icon(
                                            imageVector = Icons.Default.MoreVert,
                                            contentDescription = "More Options"
                                        )
                                    }

                                    DropdownMenu(
                                        expanded = isDropdownMenuExpanded.value,
                                        onDismissRequest = { isDropdownMenuExpanded.value = false },
                                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA))
                                    ){
                                        DropdownMenuItem(
                                            text = { Text("Edit workspace",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                isDropdownMenuExpanded.value = false
                                                homeViewModel.showCreateWorkSpaceDialog(editWorkSpace = true)

                                            }
                                        )
                                        DropdownMenuItem(
                                            text = { Text("Delete workspace",
                                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                                                , fontSize = 15.sp) },
                                            onClick = {
                                                isDropdownMenuExpanded.value = false
                                                isDeleteTaskDialogVisible.value = true
                                            }
                                        )
                                    }
                                }
                            }



                        }

                        val dragAndDropState = rememberDragAndDropState<TaskItem?>()
                        DragAndDropContainer(
                            state = dragAndDropState,
                        ) {
                            LazyRow(modifier=Modifier.fillMaxSize().padding(horizontal = if (isExpandedScreen) 20.dp else 4.dp,vertical = 0.dp)) {

                                if (homeState.selectedWorkspaceId!=null){
                                    itemsIndexed(selectedProject?.taskStatusDetails?: arrayListOf()){index,column->

                                        val listState = rememberLazyListState()
                                        val itemCount = tasks.filter { it?.taskStatusDetails==column }.size

                                        Column(modifier=Modifier.fillMaxWidth(0.3f).fillMaxHeight()) {
                                            Row(verticalAlignment = Alignment.CenterVertically) {
                                                Text(column.name?:"", fontSize = 18.sp,
                                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                                    style =  MaterialTheme.typography.bodyMedium,
                                                    fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                    color = Color(0xFF032549)
                                                )
                                                Spacer(modifier = Modifier.width(8.dp))
                                                TextButton(contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp),onClick = {},
                                                ){
                                                    Box(
                                                        modifier = Modifier
                                                            .background(Color.Transparent)
                                                            .padding(horizontal = 10.dp, vertical = 0.dp)
                                                            .border(BorderStroke(1f.dp, Color.Gray), shape = RoundedCornerShape(50))
                                                        ,
                                                        contentAlignment = Alignment.Center
                                                    ) {
                                                        Text("${tasks.filter { it?.taskStatusDetails==column }.size}", fontSize = 14.sp,modifier = Modifier.padding(horizontal = 10.dp, vertical = 0.dp),fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF5C728A))
                                                    }

                                                }
                                            }
                                            LazyColumn(state = rememberForeverLazyListState(key = column.name?:""),modifier = Modifier.border(
                                                width = 1.dp,
                                                color = with(MaterialTheme.colorScheme) {
                                                    if (dragAndDropState.hoveredDropTargetKey == "targetKey${column.id}") primary else Color.Transparent
                                                },
                                                shape = RoundedCornerShape(24.dp),
                                            ).dropTarget(
                                                    key = "targetKey${column.id}",
                                                state = dragAndDropState,
                                                onDrop = {
                                                    tasks.remove(it.data)
                                                    tasks.add(it.data?.copy(taskStatusDetails = column))
                                                    homeViewModel.updateTask(it.data?.copy(taskStatus = column.id)?:TaskItem()){updatedTask->

                                                    }
                                                }
                                            )
                                              // .then(if (itemCount>=2) Modifier.weight(1f) else Modifier) )
                                                .then(Modifier.weight(1f)) )
                                            {
                                                items(tasks.filter { it?.taskStatusDetails==column }.sortedBy { when (it?.priority) {
                                                    "Urgent" -> 0
                                                    "Medium" -> 1
                                                    "Low" -> 2
                                                    else -> 3
                                                } }){
                                                    DraggableItem(
                                                        state = dragAndDropState,
                                                        key = it?.id?:"", // Unique key for each draggable item
                                                        data = it, // Data to be passed to the drop target
                                                        draggableContent = {
                                                            TaskCard(onClick = {navigateToCardDetail(it?: TaskItem())}, viewModel = homeViewModel,task = it?: TaskItem())

                                                        }
                                                    ) {
                                                        TaskCard(modifier=Modifier.graphicsLayer {
                                                            alpha = if (isDragging) 0f else 1f
                                                        },onClick = {navigateToCardDetail(it?: TaskItem())}, viewModel = homeViewModel,task = it?: TaskItem())

                                                    }
                                                }
                                                if (tasks.filter { it?.taskStatusDetails==column }.isEmpty()){
                                                    item{
                                                        Spacer(modifier = Modifier.width(350.dp).padding(16.dp))
                                                    }
                                                }
                                            }
                                            val editable = remember { mutableStateOf(false) }
                                            val scope = rememberCoroutineScope()
                                            if (homeState.selectedWorkspaceId!=null){
                                                if (editable.value){
                                                    val taskTitle = remember { mutableStateOf("") }
                                                    if (tasks.filter { it?.taskStatusDetails==column }.isNotEmpty()){
                                                        scope.launch { listState.animateScrollToItem(tasks.filter { it?.taskStatusDetails==column }.size-1) }
                                                    }

                                                    Card(
                                                        modifier = Modifier
                                                            .padding(16.dp)
                                                            .width(350.dp)


                                                        ,
                                                        elevation = CardDefaults.cardElevation(
                                                            defaultElevation = 4.dp
                                                        ),
                                                        colors = CardDefaults.cardColors(
                                                            containerColor = Color(0xFFFAFAFA), // set the background color of the card
                                                        )
                                                        ,
                                                        shape = RoundedCornerShape(10.dp),
                                                    ){
                                                        Column(modifier = Modifier.padding(top = 8.dp)) {
                                                            Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                                                                Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                                                                    ZiFocusTextField(text = taskTitle, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type Task Title", withDivider = false)
                                                                }
                                                            }
                                                            Row(modifier = Modifier.padding(bottom = 14.dp)) {
                                                                Spacer(modifier = Modifier.weight(1f))
                                                                TextButton(onClick = {
                                                                    editable.value = false
                                                                    taskTitle.value = ""
                                                                }){
                                                                    Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                                                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                                        color = Color(0xFF3b4958))
                                                                }
                                                                Spacer(modifier = Modifier.width(8.dp))
                                                                Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                                                                    modifier = Modifier
                                                                        .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                                                                        editable.value = false
                                                                        homeViewModel.createTask(
                                                                            CreateTaskRequest(
                                                                                name = taskTitle.value,
                                                                                projectId = homeState.selectedWorkspaceId?:"",
                                                                                taskStatus = column.id,
                                                                                category = CategoryItem(title = "Work", color = 0xFFA98EDA)
                                                                            ))

                                                                    }){
                                                                    Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                                                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                                                                }

                                                                Spacer(modifier = Modifier.width(8.dp))

                                                            }


                                                        }
                                                    }
                                                }
                                                else {
                                                    Row(
                                                        modifier = modifier
                                                            .wrapContentHeight()
                                                            .fillMaxWidth(), verticalAlignment = Alignment.CenterVertically
                                                    ) {
                                                        TextButton(
                                                            modifier = Modifier
                                                                .wrapContentHeight(),
                                                            contentPadding = PaddingValues(horizontal = 8.dp, vertical = 4.dp),
                                                            colors = ButtonDefaults.textButtonColors(
                                                                contentColor = MaterialTheme.colorScheme.primaryContainer
                                                            ),
                                                            onClick = {
                                                                editable.value = true
                                                            }
                                                        ) {
                                                            Icon(
                                                                imageVector = Icons.Filled.Add,
                                                                contentDescription = "Add card"
                                                            )
                                                            Text(
                                                                modifier = Modifier,
                                                                fontSize = 12.sp,
                                                                text = "Add Task"
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                /*
                                item {
                                    val listState = rememberLazyListState()
                                    val itemCount = tasks.filter { it?.taskStatus=="TO_DO" }.size
                                    val itemCountInProgress = tasks.filter { it?.taskStatus=="IN_PROGRESS" }.size

                                    Column(modifier=Modifier.fillMaxWidth(0.3f).fillMaxHeight()) {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text("To do", fontSize = 18.sp,
                                                //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                                style =  MaterialTheme.typography.bodyMedium,
                                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                color = Color(0xFF032549)
                                            )
                                            Spacer(modifier = Modifier.width(8.dp))
                                            TextButton(contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp),onClick = {},
                                            ){
                                                Box(
                                                    modifier = Modifier
                                                        .background(Color.Transparent)
                                                        .padding(horizontal = 10.dp, vertical = 0.dp)
                                                        .border(BorderStroke(1f.dp, Color.Gray), shape = RoundedCornerShape(50))
                                                        ,
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    Text("${tasks.filter { it?.taskStatus=="TO_DO" }.size}", fontSize = 14.sp,modifier = Modifier.padding(horizontal = 10.dp, vertical = 0.dp),fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF5C728A))
                                                }

                                            }
                                        }
                                        LazyColumn(state = rememberForeverLazyListState(key = "To do"),modifier =if (itemCount>=2) Modifier.weight(1f) else Modifier) {
                                            items(tasks.filter { it?.taskStatus=="TO_DO" }.sortedBy { when (it?.priority) {
                                                "Urgent" -> 0
                                                "Medium" -> 1
                                                "Low" -> 2
                                                else -> 3
                                            } }){
                                                TaskCard(onClick = {navigateToCardDetail(it?: TaskItem())}, viewModel = homeViewModel,task = it?: TaskItem())
                                            }
                                            if (tasks.filter { it?.taskStatus=="TO_DO" }.isEmpty()){
                                                item{
                                                    Spacer(modifier = Modifier.width(350.dp).padding(16.dp))
                                                }
                                            }
                                        }
                                        val editable = remember { mutableStateOf(false) }
                                        val scope = rememberCoroutineScope()
                                        if (homeState.selectedWorkspaceId!=null){
                                            if (editable.value){
                                                val taskTitle = remember { mutableStateOf("") }
                                                if (tasks.filter { it?.taskStatus == "TO_DO" }.isNotEmpty()){
                                                    scope.launch { listState.animateScrollToItem(tasks.filter { it?.taskStatus=="TO_DO" }.size-1) }
                                                }

                                                Card(
                                                    modifier = Modifier
                                                        .padding(16.dp)
                                                        .width(350.dp)


                                                    ,
                                                    elevation = CardDefaults.cardElevation(
                                                        defaultElevation = 4.dp
                                                    ),
                                                    colors = CardDefaults.cardColors(
                                                        containerColor = Color(0xFFFAFAFA), // set the background color of the card
                                                    )
                                                    ,
                                                    shape = RoundedCornerShape(10.dp),
                                                ){
                                                    Column(modifier = Modifier.padding(top = 8.dp)) {
                                                        Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                                                            Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                                                                ZiFocusTextField(text = taskTitle, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type Task Title", withDivider = false)
                                                            }
                                                        }
                                                        Row(modifier = Modifier.padding(bottom = 14.dp)) {
                                                            Spacer(modifier = Modifier.weight(1f))
                                                            TextButton(onClick = {
                                                                editable.value = false
                                                                taskTitle.value = ""
                                                            }){
                                                                Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                                                    fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                                    color = Color(0xFF3b4958))
                                                            }
                                                            Spacer(modifier = Modifier.width(8.dp))
                                                            Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                                                                modifier = Modifier
                                                                    .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                                                                    editable.value = false
                                                                    homeViewModel.createTask(
                                                                        org.openapitools.client.models.CreateTaskRequest(
                                                                        name = taskTitle.value,
                                                                        projectId = homeState.selectedWorkspaceId?:"",
                                                                        taskStatus = "TO_DO",
                                                                        category = CategoryItem(title = "Work", color = 0xFFA98EDA)
                                                                    ))

                                                                }){
                                                                Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                                                    fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                                                            }

                                                            Spacer(modifier = Modifier.width(8.dp))

                                                        }


                                                    }
                                                }
                                            }
                                            else {
                                                Row(
                                                    modifier = modifier
                                                        .wrapContentHeight()
                                                        .fillMaxWidth(), verticalAlignment = Alignment.CenterVertically
                                                ) {
                                                    TextButton(
                                                        modifier = Modifier
                                                            .wrapContentHeight(),
                                                        contentPadding = PaddingValues(horizontal = 8.dp, vertical = 4.dp),
                                                        colors = ButtonDefaults.textButtonColors(
                                                            contentColor = MaterialTheme.colorScheme.primaryContainer
                                                        ),
                                                        onClick = {
                                                            editable.value = true
                                                        }
                                                    ) {
                                                        Icon(
                                                            imageVector = Icons.Filled.Add,
                                                            contentDescription = "Add card"
                                                        )
                                                        Text(
                                                            modifier = Modifier,
                                                            fontSize = 12.sp,
                                                            text = "Add Task"
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }


                                    Column(modifier=Modifier.fillMaxWidth(0.3f)) {

                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text("In progress", fontSize = 18.sp,
                                                //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                                style =  MaterialTheme.typography.bodyMedium,
                                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                color = Color(0xFF032549)
                                            )
                                            Spacer(modifier = Modifier.width(8.dp))
                                            TextButton(contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp),onClick = {},
                                            ){
                                                Box(
                                                    modifier = Modifier
                                                        .background(Color.Transparent)
                                                        .padding(horizontal = 10.dp, vertical = 0.dp)
                                                        .border(BorderStroke(1f.dp, Color.Gray), shape = RoundedCornerShape(50))
                                                        ,
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    Text("${tasks.filter { it?.taskStatus=="IN_PROGRESS" }.size}", fontSize = 14.sp,modifier = Modifier.padding(horizontal = 10.dp, vertical = 0.dp),fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF5C728A))
                                                }

                                            }
                                        }

                                        LazyColumn(state = rememberForeverLazyListState(key = "In progress"),modifier =if (itemCountInProgress>=2) Modifier.weight(1f) else Modifier) {
                                            items(tasks.filter { it?.taskStatus=="IN_PROGRESS" }.sortedBy { when (it?.priority) {
                                                "Urgent" -> 0
                                                "Medium" -> 1
                                                "Low" -> 2
                                                else -> 3
                                            } }){
                                                TaskCard(onClick = {navigateToCardDetail(it?: TaskItem())}, viewModel = homeViewModel,task = it?: TaskItem())
                                            }
                                            if (tasks.filter { it?.taskStatus=="IN_PROGRESS" }.isEmpty()){
                                                item{
                                                    Spacer(modifier = Modifier.width(350.dp).padding(16.dp))
                                                }
                                            }

                                        }

                                            val editable = remember { mutableStateOf(false) }
                                            val scope = rememberCoroutineScope()
                                            if (homeState.selectedWorkspaceId!=null){
                                                if (editable.value){
                                                    val taskTitle = remember { mutableStateOf("") }
                                                    if (tasks.filter { it?.taskStatus == "IN_PROGRESS" }.isNotEmpty()){
                                                        scope.launch { listState.animateScrollToItem(tasks.filter { it?.taskStatus=="IN_PROGRESS" }.size-1) }
                                                    }

                                                    Card(
                                                        modifier = Modifier
                                                            .padding(16.dp)
                                                            .width(350.dp)


                                                        ,
                                                        elevation = CardDefaults.cardElevation(
                                                            defaultElevation = 4.dp
                                                        ),
                                                        colors = CardDefaults.cardColors(
                                                            containerColor = Color(0xFFFAFAFA), // set the background color of the card
                                                        )
                                                        ,
                                                        shape = RoundedCornerShape(10.dp),
                                                    ){
                                                        Column(modifier = Modifier.padding(top = 8.dp)) {
                                                            Row(modifier = Modifier.padding(horizontal = 8.dp)) {
                                                                Box(modifier = Modifier.background(Color(0xFFe5eefd), shape = RoundedCornerShape(10.dp)).padding(horizontal = 8.dp, vertical = 4.dp).fillMaxWidth().height(74.dp)) {
                                                                    ZiFocusTextField(text = taskTitle, textStyle = MaterialTheme.typography.bodySmall, placeHolder = "Type Task Title", withDivider = false)
                                                                }
                                                            }
                                                            Row(modifier = Modifier.padding(bottom = 14.dp)) {
                                                                Spacer(modifier = Modifier.weight(1f))
                                                                TextButton(onClick = {
                                                                    editable.value = false
                                                                    taskTitle.value = ""
                                                                }){
                                                                    Text("Discard", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                                                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                                        color = Color(0xFF3b4958))
                                                                }
                                                                Spacer(modifier = Modifier.width(8.dp))
                                                                Button(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                                                                    modifier = Modifier
                                                                        .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp).width(56.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF495564)),onClick = {
                                                                        editable.value = false
                                                                        homeViewModel.createTask(
                                                                            org.openapitools.client.models.CreateTaskRequest(
                                                                            name = taskTitle.value,
                                                                            projectId = homeState.selectedWorkspaceId?:"",
                                                                            taskStatus = "IN_PROGRESS",
                                                                            category = CategoryItem(title = "Work", color = 0xFFA98EDA)
                                                                        ))

                                                                    }){
                                                                    Text("Save", fontSize = 14.sp, style = MaterialTheme.typography.bodyMedium,
                                                                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                                                                }

                                                                Spacer(modifier = Modifier.width(8.dp))

                                                            }


                                                        }
                                                    }
                                                }
                                                else {
                                                    Row(
                                                        modifier = modifier
                                                            .wrapContentHeight()
                                                            .fillMaxWidth(), verticalAlignment = Alignment.CenterVertically
                                                    ) {
                                                        TextButton(
                                                            modifier = Modifier
                                                                .wrapContentHeight(),
                                                            contentPadding = PaddingValues(horizontal = 8.dp, vertical = 4.dp),
                                                            colors = ButtonDefaults.textButtonColors(
                                                                contentColor = MaterialTheme.colorScheme.primaryContainer
                                                            ),
                                                            onClick = {
                                                                editable.value = true
                                                            }
                                                        ) {
                                                            Icon(
                                                                imageVector = Icons.Filled.Add,
                                                                contentDescription = "Add card"
                                                            )
                                                            Text(
                                                                modifier = Modifier,
                                                                fontSize = 12.sp,
                                                                text = "Add Task"
                                                            )
                                                        }
                                                    }
                                                }
                                            }

                                    }
                                    Column(modifier=Modifier.fillMaxWidth(0.3f)) {

                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text("Done", fontSize = 18.sp,
                                                //  fontFamily = fontFamilyResource(MR.fonts.sf.medium)
                                                style =  MaterialTheme.typography.bodyMedium,
                                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                color = Color(0xFF032549)
                                            )
                                            Spacer(modifier = Modifier.width(8.dp))
                                            TextButton(contentPadding = PaddingValues(), modifier = Modifier.defaultMinSize(1.dp,1.dp),onClick = {},
                                            ){
                                                Box(
                                                    modifier = Modifier
                                                        .background(Color.Transparent)
                                                        .padding(horizontal = 10.dp, vertical = 0.dp)
                                                        .border(BorderStroke(1f.dp, Color.Gray), shape = RoundedCornerShape(50))
                                                        ,
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    Text("${tasks.filter { it?.taskStatus=="DONE" }.size}", fontSize = 14.sp,modifier = Modifier.padding(horizontal = 10.dp, vertical = 0.dp),fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF5C728A))
                                                }

                                            }
                                        }

                                        LazyColumn(state = rememberForeverLazyListState(key = "Done")) {
                                            items(tasks.filter { it?.taskStatus=="DONE" }.sortedBy { when (it?.priority) {
                                                "Urgent" -> 0
                                                "Medium" -> 1
                                                "Low" -> 2
                                                else -> 3
                                            } }){
                                                TaskCard(onClick = {navigateToCardDetail(it?: TaskItem())}, viewModel = homeViewModel, task = it?: TaskItem())
                                            }
                                            if (tasks.filter { it?.taskStatus=="DONE" }.isEmpty()){
                                                item{
                                                    Spacer(modifier = Modifier.width(350.dp).padding(16.dp))
                                                }
                                            }
                                        }
                                    }
                                }
                                */
                            }

                        }


                    }

                    if (homeState.selectedWorkspaceId == null){
                        Column(horizontalAlignment = Alignment.CenterHorizontally) {
                            Image(painterResource(Res.drawable.empty_placeholder),modifier=Modifier, contentDescription = null)
                            Text("No workspace available", color = Color(0xFF5C728A),
                                style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                fontSize = 20.sp)
                            Spacer(modifier=Modifier.height(16.dp))
                            Button(onClick = {
                                homeViewModel.showCreateWorkSpaceDialog()
                                             }, shape = RoundedCornerShape(10.dp)){
                                Text("Create a workspace", color = Color(0xFFFAFAFA),
                                    style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                    fontSize = 17.sp)
                            }
                        }


                    } else {
                        AnimatedVisibility(tasks.isEmpty()&&!homeState.loadedTasks){
                            CircularProgressIndicator()
                        }
                    }



                }

            }
        }
    }






}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun TaskCard(
    modifier: Modifier = Modifier,
    onClick: () -> Unit,
    viewModel: HomeViewModel,
    task: TaskItem
) {
    val windowSizeClass = calculateWindowSizeClass()
    val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
    Card(
        modifier = modifier
            .padding(8.dp)

            // disable card Click
            .clickable { onClick() }

        ,
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
        colors = CardDefaults.cardColors(
            containerColor = Color(0xFFFAFAFA), // set the background color of the card
        )
        ,
        shape = RoundedCornerShape(10.dp),
    ) {
        Column(
            modifier = Modifier.width(if (!useNavRail) 250.dp else 360.dp).padding(16.dp)
        ) {

            var timer by rememberSaveable { mutableStateOf(0L) }
            val started = remember { mutableStateOf(false) }
            LaunchedEffect(started.value) {
                while (started.value) {
                    delay(200L)
                    timer += 200L
                }
            }
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(horizontal = 10.dp)) {
                Text("#${task.taskNumber}", fontSize = 15.sp,
                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                    style =  MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    color = Color(0xFF5C728A)
                )
                Spacer(modifier=Modifier.weight(1f))
                Text(formatDateString(task.createdAt?:""), fontSize = 15.sp,
                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                    style =  MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    color = Color(0xFF5C728A)
                )


            }
            Spacer(modifier=Modifier.height(16.dp))
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth()) {
                Box(modifier=Modifier.fillMaxWidth()) {
                    Text(task.name?:"", fontSize = 18.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                        style =  MaterialTheme.typography.displayMedium,
                        fontFamily = MaterialTheme.typography.displayMedium.fontFamily,
                        // overflow = TextOverflow.Ellipsis,
                        modifier = Modifier.padding(start = 10.dp,end=if (task.taskStatus=="IN_PROGRESS")65.dp else 30.dp)
                        ,

                        color = Color(0xFF03264A))

                    /*
                    if (homeState.value.pause_image!=null){
                        Row(modifier = Modifier.align(Alignment.CenterEnd)) {
                            (if (task.taskStatus=="IN_PROGRESS") homeState.value.pause_image
                            else homeState.value.play_image)?.let {
                                Image(
                                    bitmap = it,
                                    contentDescription = null,
                                    modifier = Modifier.size(32.dp).padding(start = 4.dp).clickable {
                                        viewModel.updateTask(task.copy(taskStatus = if (task.taskStatus=="TO_DO")"IN_PROGRESS" else if (task.taskStatus=="IN_PROGRESS") "TO_DO" else "IN_PROGRESS"))

                                    },

                                    )
                            }

                            if (task.taskStatus=="IN_PROGRESS"){
                                homeState.value.kanban_done?.let {
                                    Image(
                                        bitmap = it,
                                        contentDescription = null,
                                        modifier = Modifier.size(32.dp).padding(start = 4.dp)
                                            .clickable {
                                                viewModel.updateTask(task.copy(taskStatus = "DONE"))

                                            }

                                    )
                                }

                            }
                        }


                    }
                    else {
                        Image(
                            painter = if (task.taskStatus=="IN_PROGRESS") painterResource(Res.drawable.pause) else painterResource(Res.drawable.pause) ,
                            contentDescription = null,
                            modifier = Modifier.size(32.dp).align(Alignment.CenterEnd).padding(start = 4.dp).clickable {
                                viewModel.updateTask(task.copy(taskStatus = if (task.taskStatus=="TO_DO")"IN_PROGRESS" else if (task.taskStatus=="IN_PROGRESS") "TO_DO" else "IN_PROGRESS"))
                            },
                        )
                        if (task.taskStatus=="IN_PROGRESS"){
                            Image(
                                painter = painterResource(Res.drawable.kanban_done_),
                                contentDescription = null,
                                modifier = Modifier.size(32.dp).align(Alignment.CenterEnd).padding(start = 4.dp)
                                    .clickable {
                                        viewModel.updateTask(task.copy(taskStatus = "DONE"))

                                    }
                            )
                        }
                    }
                    */
                }






            }

            Spacer(modifier=Modifier.height(2.dp))

            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(10.dp)) {
                if (!task.priority.isNullOrEmpty()) {
                    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Start) {
                        /* if (urgentFlag != null) {
                             task.priority?.let { priority ->
                                 when (priority) {
                                     "Urgent" -> urgentFlag
                                     "Medium" -> mediumFlag
                                     "Low" -> lowFlag
                                     else -> urgentFlag
                                 }
                             }?.let {
                                 Image(
                                     bitmap = it, // use defaultBitmapImage if task.priority is null or the flag is null
                                     contentDescription = null,
                                     modifier = Modifier.size(24.dp)
                                 )
                             }
                         } */
                        //  else {
                        Image(
                            painter = when (task.priority) {
                                "Urgent" -> painterResource(Res.drawable.urgent_flag)
                                "Medium" -> painterResource(Res.drawable.medium_flag)
                                "Low" -> painterResource(Res.drawable.low_flag)
                                else -> painterResource(Res.drawable.urgent_flag)
                            }, // use defaultBitmapImage if task.priority is null or the flag is null
                            contentDescription = null,

                            )
                        // }
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            task.priority ?: "Urgent",
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style = MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                            color = Color(0xFF5C728A).copy(alpha = 0.7f),
                            fontSize = 14.sp
                        )


                    }
                    Spacer(modifier=Modifier.width(12.dp))
                }


                Row(verticalAlignment = Alignment.CenterVertically) {
                    Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                        drawCircle(Color(task.category?.color?.toLong()?:0xFF9056F0))

                    })

                    Text(task.category?.title?:"Work", fontSize = 14.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                        color = Color(0xFF5C728A).copy(alpha = 0.7f),
                        modifier = Modifier.padding(start = 4.dp))

                }

                Spacer(modifier=Modifier.width(12.dp))
                if (task.assignedTo==viewModel.homeState.value.user?.id){
                    if ((task.taskStatus!="TO_DO"&&task.startTime?.isNotEmpty() == true)||(task.taskStatus=="DONE"&&task.stopTime?.isNotEmpty()==true)){
                        var now = Clock.System.now()
                        val startTime: Instant = Instant.parse(task.startTime?:"" )

                        var durationSinceThenState: MutableState<Duration> = remember { mutableStateOf(now - startTime) }
                        if (task.taskStatus=="DONE") {
                            val stopedTime: Instant = Instant.parse(task.stopTime?:"" )
                            durationSinceThenState = remember { mutableStateOf(stopedTime - startTime) }

                        }
                        LaunchedEffect(durationSinceThenState.value.inWholeMinutes) {
                            if (task.taskStatus!="DONE"){
                                while (true) {
                                    now = Clock.System.now()
                                    durationSinceThenState.value = now - startTime
                                    delay(60000) // Wait for 1 second
                                }
                            }


                        }

                        val formattedDuration = when {
                            durationSinceThenState.value.inWholeMinutes.toInt() == 0 -> {
                                "Just now"
                            }
                            durationSinceThenState.value.inWholeMinutes < 60 -> {
                                val minutes = durationSinceThenState.value.inWholeMinutes
                                "$minutes ${if (minutes > 1) "mins" else "min"}"
                            }
                            durationSinceThenState.value.inWholeHours < 24 -> {
                                val hours = durationSinceThenState.value.inWholeHours
                                "$hours ${if (hours > 1) "hours" else "hour"}" +
                                        (if (durationSinceThenState.value.inWholeMinutes % 60 > 0) " ${durationSinceThenState.value.inWholeMinutes % 60} min" else "")
                            }
                            else -> {
                                val days = durationSinceThenState.value.inWholeDays
                                val hours = durationSinceThenState.value.inWholeHours % 24
                                val minutes = durationSinceThenState.value.inWholeMinutes % 60
                                val daysText = if (days > 0) "$days day" + (if (days > 1) "s" else "") else ""
                                val hoursText = if (hours > 0) " $hours hour" + (if (hours > 1) "s" else "") else ""
                                val minutesText = if (minutes > 0) " $minutes min" + (if (minutes > 1) "s" else "") else ""
                                //  "$daysText$hoursText$minutesText"
                                "$daysText$hoursText"
                            }
                        }
                        //  Image(painterResource(time_image.value), contentDescription = null)
                        Row(verticalAlignment = Alignment.CenterVertically) {

                            Image(
                                painter = painterResource(Res.drawable.time_),
                                contentDescription = null,
                                modifier=Modifier.size(16.dp)
                            )


                            Text(
                                formattedDuration,
                                fontSize = 16.sp,
                                // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                                style =  MaterialTheme.typography.bodySmall,
                                fontWeight = FontWeight(400),
                                modifier = Modifier.padding(start = 5.dp),
                                color = Color(0xFF5C728A)
                            )
                        }

                    }

                }

                Spacer(modifier = Modifier.width(12.dp))
                /* Image(
                     if (started.value) painterResource(MR.images.pause_task)
                     else painterResource(MR.images.play_task),
                     null,
                     modifier = Modifier.size(20.dp).clickable {
                         started.value = !started.value

                                                               },
                 ) */

            }
            /* hide description
              Spacer(modifier=Modifier.height(8.dp))

              BasicRichTextEditor(
                  modifier = Modifier.padding(horizontal = 10.dp),
                  state = basicRichTextState,
                  readOnly = true,
                  singleLine = true,
                  textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 14.sp)
              )
               Spacer(modifier=Modifier.height(12.dp))
              */

            if (task.commentCount!=0||task.blockerCount!=0) Spacer(modifier=Modifier.height(12.dp))
            if (task.commentCount!=0){

                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center,
                    modifier = Modifier.padding(horizontal = 10.dp)) {
                    //  homeState.value.comments_image?.let { Image(bitmap = it, contentDescription = null) }
                    Image(painterResource(Res.drawable.comments),contentDescription = null)

                    Text("${task.commentCount} Comments"
                        , fontSize = 14.sp,
                        //   fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                        color = Color(0xFF5C728A).copy(alpha = 0.7f),
                        modifier=Modifier.padding(start=8.dp)
                    )
                }
                Spacer(modifier=Modifier.height(12.dp))
            }



            if (task.blockerCount!=0){
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center,
                    modifier = Modifier.padding(horizontal = 10.dp)) {
                    //  homeState.value.blockers_image?.let { Image(bitmap = it, contentDescription = null) }
                    Image(painterResource(Res.drawable.blockers),contentDescription = null)

                    Text("${task.blockerCount} Blocker"
                        , fontSize = 14.sp,
                        //   fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        color = Color(0xFF5C728A).copy(alpha = 0.7f),
                        modifier=Modifier.padding(start=8.dp)
                    )
                }
                Spacer(modifier=Modifier.height(12.dp))
            }


            if (task.assignedToDetails?.name!=null|| task.subTasksCount?.toInt() !=0){
                Row(verticalAlignment = Alignment.CenterVertically,modifier = Modifier.padding(10.dp)) {
                    if (task.assignedToDetails?.name!=null){
                        Text("${task.assignedToDetails?.name}", color = Color(0xFF5C728A),
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style =  MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily
                            , fontSize = 14.sp)
                    }


                    Spacer(modifier = Modifier.weight(1f))
                    if (task.subTasksCount?.toInt() !=0){
                        Text("Tasks ${task.doneSubTasksCount}/${task.subTasksCount}", color = Color(0xFF5C728A).copy(alpha = 0.7f),
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style =  MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                            fontSize = 14.sp)
                    }

                }

            }






        }
    }
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun TaskCard(
    modifier: Modifier = Modifier,
    onClick: () -> Unit,
    task: TaskItem
) {
    val windowSizeClass = calculateWindowSizeClass()
    val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
    Card(
        modifier = modifier
            .padding(8.dp)

            // disable card Click
            .clickable { onClick() }

        ,
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
        colors = CardDefaults.cardColors(
            containerColor = Color(0xFFFAFAFA), // set the background color of the card
        )
        ,
        shape = RoundedCornerShape(10.dp),
    ) {
        Column(
            modifier = Modifier.width(if (!useNavRail) 250.dp else 360.dp).padding(16.dp)
        ) {

            var timer by rememberSaveable { mutableStateOf(0L) }
            val started = remember { mutableStateOf(false) }
            LaunchedEffect(started.value) {
                while (started.value) {
                    delay(200L)
                    timer += 200L
                }
            }
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(horizontal = 10.dp)) {
                Text("#${task.taskNumber}", fontSize = 15.sp,
                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                    style =  MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    color = Color(0xFF5C728A)
                )
                Spacer(modifier=Modifier.weight(1f))
                Text(formatDateString(task.createdAt?:""), fontSize = 15.sp,
                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                    style =  MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    color = Color(0xFF5C728A)
                )


            }
            Spacer(modifier=Modifier.height(16.dp))
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth()) {
                Box(modifier=Modifier.fillMaxWidth()) {
                    Text(task.name?:"", fontSize = 18.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                        style =  MaterialTheme.typography.displayMedium,
                        fontFamily = MaterialTheme.typography.displayMedium.fontFamily,
                        // overflow = TextOverflow.Ellipsis,
                        modifier = Modifier.padding(start = 10.dp,end=if (task.taskStatus=="IN_PROGRESS")65.dp else 30.dp)
                        ,

                        color = Color(0xFF03264A))

                    /*
                    if (homeState.value.pause_image!=null){
                        Row(modifier = Modifier.align(Alignment.CenterEnd)) {
                            (if (task.taskStatus=="IN_PROGRESS") homeState.value.pause_image
                            else homeState.value.play_image)?.let {
                                Image(
                                    bitmap = it,
                                    contentDescription = null,
                                    modifier = Modifier.size(32.dp).padding(start = 4.dp).clickable {
                                        viewModel.updateTask(task.copy(taskStatus = if (task.taskStatus=="TO_DO")"IN_PROGRESS" else if (task.taskStatus=="IN_PROGRESS") "TO_DO" else "IN_PROGRESS"))

                                    },

                                    )
                            }

                            if (task.taskStatus=="IN_PROGRESS"){
                                homeState.value.kanban_done?.let {
                                    Image(
                                        bitmap = it,
                                        contentDescription = null,
                                        modifier = Modifier.size(32.dp).padding(start = 4.dp)
                                            .clickable {
                                                viewModel.updateTask(task.copy(taskStatus = "DONE"))

                                            }

                                    )
                                }

                            }
                        }


                    }
                    else {
                        Image(
                            painter = if (task.taskStatus=="IN_PROGRESS") painterResource(Res.drawable.pause) else painterResource(Res.drawable.pause) ,
                            contentDescription = null,
                            modifier = Modifier.size(32.dp).align(Alignment.CenterEnd).padding(start = 4.dp).clickable {
                                viewModel.updateTask(task.copy(taskStatus = if (task.taskStatus=="TO_DO")"IN_PROGRESS" else if (task.taskStatus=="IN_PROGRESS") "TO_DO" else "IN_PROGRESS"))
                            },
                        )
                        if (task.taskStatus=="IN_PROGRESS"){
                            Image(
                                painter = painterResource(Res.drawable.kanban_done_),
                                contentDescription = null,
                                modifier = Modifier.size(32.dp).align(Alignment.CenterEnd).padding(start = 4.dp)
                                    .clickable {
                                        viewModel.updateTask(task.copy(taskStatus = "DONE"))

                                    }
                            )
                        }
                    }
                    */
                }






            }

            Spacer(modifier=Modifier.height(2.dp))

            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(10.dp)) {
                if (!task.priority.isNullOrEmpty()) {
                    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Start) {
                        /* if (urgentFlag != null) {
                             task.priority?.let { priority ->
                                 when (priority) {
                                     "Urgent" -> urgentFlag
                                     "Medium" -> mediumFlag
                                     "Low" -> lowFlag
                                     else -> urgentFlag
                                 }
                             }?.let {
                                 Image(
                                     bitmap = it, // use defaultBitmapImage if task.priority is null or the flag is null
                                     contentDescription = null,
                                     modifier = Modifier.size(24.dp)
                                 )
                             }
                         } */
                        //  else {
                        Image(
                            painter = when (task.priority) {
                                "Urgent" -> painterResource(Res.drawable.urgent_flag)
                                "Medium" -> painterResource(Res.drawable.medium_flag)
                                "Low" -> painterResource(Res.drawable.low_flag)
                                else -> painterResource(Res.drawable.urgent_flag)
                            }, // use defaultBitmapImage if task.priority is null or the flag is null
                            contentDescription = null,

                            )
                        // }
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            task.priority ?: "Urgent",
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style = MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                            color = Color(0xFF5C728A).copy(alpha = 0.7f),
                            fontSize = 14.sp
                        )


                    }
                    Spacer(modifier=Modifier.width(12.dp))
                }


                Row(verticalAlignment = Alignment.CenterVertically) {
                    Canvas(modifier = Modifier.size(18.dp).padding(top = 4.dp), onDraw = {
                        drawCircle(Color(task.category?.color?.toLong()?:0xFF9056F0))

                    })

                    Text(task.category?.title?:"Work", fontSize = 14.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                        color = Color(0xFF5C728A).copy(alpha = 0.7f),
                        modifier = Modifier.padding(start = 4.dp))

                }

                Spacer(modifier=Modifier.width(12.dp))





            }


            if (task.commentCount!=0||task.blockerCount!=0) Spacer(modifier=Modifier.height(12.dp))
            if (task.commentCount!=0){

                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center,
                    modifier = Modifier.padding(horizontal = 10.dp)) {
                    //  homeState.value.comments_image?.let { Image(bitmap = it, contentDescription = null) }
                    Image(painterResource(Res.drawable.comments),contentDescription = null)

                    Text("${task.commentCount} Comments"
                        , fontSize = 14.sp,
                        //   fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                        color = Color(0xFF5C728A).copy(alpha = 0.7f),
                        modifier=Modifier.padding(start=8.dp)
                    )
                }
                Spacer(modifier=Modifier.height(12.dp))
            }



            if (task.blockerCount!=0){
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center,
                    modifier = Modifier.padding(horizontal = 10.dp)) {
                    //  homeState.value.blockers_image?.let { Image(bitmap = it, contentDescription = null) }
                    Image(painterResource(Res.drawable.blockers),contentDescription = null)

                    Text("${task.blockerCount} Blocker"
                        , fontSize = 14.sp,
                        //   fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        color = Color(0xFF5C728A).copy(alpha = 0.7f),
                        modifier=Modifier.padding(start=8.dp)
                    )
                }
                Spacer(modifier=Modifier.height(12.dp))
            }


            if (task.assignedToDetails?.name!=null|| task.subTasksCount?.toInt() !=0){
                Row(verticalAlignment = Alignment.CenterVertically,modifier = Modifier.padding(10.dp)) {
                    if (task.assignedToDetails?.name!=null){
                        Text("${task.assignedToDetails?.name}", color = Color(0xFF5C728A),
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style =  MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily
                            , fontSize = 14.sp)
                    }


                    Spacer(modifier = Modifier.weight(1f))
                    if (task.subTasksCount?.toInt() !=0){
                        Text("Tasks ${task.doneSubTasksCount}/${task.subTasksCount}", color = Color(0xFF5C728A).copy(alpha = 0.7f),
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style =  MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                            fontSize = 14.sp)
                    }

                }

            }






        }
    }
}





@Composable
fun StatusChooseCreateTask(taskStatus: MutableState<String>, statusChange: MutableState<Boolean>,project: Project?=null) {
    Column {
        if (project!=null){
            project?.taskStatusDetails?.forEach {
                TextButton(modifier = Modifier.fillMaxWidth(),onClick = {
                    taskStatus.value= it.id?:""
                    statusChange.value = false
                },
                    colors = ButtonDefaults.textButtonColors(containerColor = when (it.id) {
                        project.taskStatusDetails?.firstOrNull()?.id -> Color(0xFFCFE2FF)
                        project.taskStatusDetails?.find { it.name== "In Progress"}?.id -> Color(0xFFFFF3CD)
                        project.taskStatusDetails?.lastOrNull()?.id -> Color(0xFFD1E7DD)
                        else -> getRandomColorForColumns()
                    })){
                    Text(it.name?:"", fontFamily = MaterialTheme.typography.bodySmall.fontFamily, fontSize = 12.sp, color = Color.Black)
                }

                Spacer(modifier = Modifier.height(14.dp))
            }
        } else {
            val statusList = listOf("To Do","In Progress","Done")
            statusList.forEach {
                TextButton(modifier = Modifier.fillMaxWidth(),onClick = {
                    taskStatus.value= it
                    statusChange.value = false
                },
                    colors = ButtonDefaults.textButtonColors(containerColor = when (it) {
                        "To Do" -> Color(0xFFCFE2FF)
                       "In Progress" -> Color(0xFFFFF3CD)
                       "Done" -> Color(0xFFD1E7DD)
                        else -> getRandomColorForColumns()
                    })){
                    Text(it, fontFamily = MaterialTheme.typography.bodySmall.fontFamily, fontSize = 12.sp, color = Color.Black)
                }

                Spacer(modifier = Modifier.height(14.dp))
            }
        }


    }




}
@Composable
fun PriorityChooseCreateTask(taskPriority: MutableState<String>, priorityChange: MutableState<Boolean>){
    Column (modifier = Modifier.fillMaxWidth(),verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally){

        OutlinedButton(
            border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
            modifier = Modifier.fillMaxWidth(),onClick = {
            taskPriority.value="Urgent"
            priorityChange.value=false
        },){
            Row(verticalAlignment = Alignment.CenterVertically) {

                    Image(
                        painter = painterResource(Res.drawable.urgent_flag),
                        contentDescription = null,
                    )

                Spacer(modifier = Modifier.width(4.dp))
                Text("Urgent", color = Color(0xFF03264A),textAlign = TextAlign.Center,
                            style =  MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    fontSize = 14.sp,)
                Spacer(modifier = Modifier.weight(1f))

            }
        }
        Spacer(modifier = Modifier.height(14.dp))
        OutlinedButton(
            border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
            modifier = Modifier.fillMaxWidth(),onClick = {
            taskPriority.value="Medium"
            priorityChange.value=false
        },){
            Row(verticalAlignment = Alignment.CenterVertically) {
                Image(
                    painter = painterResource(Res.drawable.medium_flag),
                    contentDescription = null,
                )
                Spacer(modifier = Modifier.width(4.dp))
                Text("Medium", color = Color(0xFF03264A), textAlign = TextAlign.Center,
                            style =  MaterialTheme.typography.bodySmall, fontSize = 14.sp,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,)
                Spacer(modifier = Modifier.weight(1f))
            }
        }
        Spacer(modifier = Modifier.height(14.dp))
        OutlinedButton(
            border = BorderStroke(1.dp, Color(0xFFD9D9D9)),
            modifier = Modifier.fillMaxWidth(),onClick = {
            taskPriority.value="Low"
            priorityChange.value=false
        },){
            Row(verticalAlignment = Alignment.CenterVertically) {
                Image(
                    painter = painterResource(Res.drawable.low_flag),
                    contentDescription = null,
                )
                Spacer(modifier = Modifier.width(4.dp))
                Text("Low", color = Color(0xFF03264A), textAlign = TextAlign.Center,
                            style =  MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,fontSize = 14.sp,)
                Spacer(modifier = Modifier.weight(1f))
            }
        }

    }


}
fun formatDateString(dateString: String): String {
    val splits = dateString.split('-', 'T', ':')

    val year = splits[0]
    val month = splits[1]
    val day = splits[2]

    return "$day/${month.padStart(2, '0')}/${year.takeLast(2)}"
}

private val SaveMap = mutableMapOf<String, KeyParams>()

private data class KeyParams(
    val params: String = "",
    val index: Int,
    val scrollOffset: Int
)
@Composable
fun rememberForeverLazyListState(
    key: String,
    params: String = "",
    initialFirstVisibleItemIndex: Int = 0,
    initialFirstVisibleItemScrollOffset: Int = 0
): LazyListState {
    val scrollState = rememberSaveable(saver = LazyListState.Saver) {
        var savedValue = SaveMap[key]
        if (savedValue?.params != params) savedValue = null
        val savedIndex = savedValue?.index ?: initialFirstVisibleItemIndex
        val savedOffset = savedValue?.scrollOffset ?: initialFirstVisibleItemScrollOffset
        LazyListState(
            savedIndex,
            savedOffset
        )
    }
    DisposableEffect(Unit) {
        onDispose {
            val lastIndex = scrollState.firstVisibleItemIndex
            val lastOffset = scrollState.firstVisibleItemScrollOffset
            SaveMap[key] = KeyParams(params, lastIndex, lastOffset)
        }
    }
    return scrollState
}