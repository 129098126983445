package org.company.app.presentation.component

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.*
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import org.company.app.presentation.component.table.ContentCell
import org.company.app.presentation.component.table.Table
import org.company.app.presentation.ui.carddetails.AutoSubmittingRicthTextEditor

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun OKR(){
    val html = """<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>OKR Template</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
        }
        .container {
            width: 80%;
            margin: 20px auto;
            padding: 20px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1, h2 {
            color: #333;
        }
        .objective {
            border-bottom: 2px solid #ccc;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }
        .key-result {
            margin-left: 20px;
        }
        .add-btn {
            background-color: #007BFF;
            color: white;
            padding: 10px 15px;
            border: none;
            cursor: pointer;
            display: inline-block;
            margin-top: 10px;
        }
        .add-btn:hover {
            background-color: #0056b3;
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>OKR Template</h1>

        <div class="objective">
            <h2>Objective 1</h2>
            <div class="key-result">
                <p>Key Result 1.1: Description</p>
                <p>Key Result 1.2: Description</p>
                <p>Key Result 1.3: Description</p>
            </div>
        </div>

        <div class="objective">
            <h2>Objective 2</h2>
            <div class="key-result">
                <p>Key Result 2.1: Description</p>
                <p>Key Result 2.2: Description</p>
                <p>Key Result 2.3: Description</p>
            </div>
        </div>

        <div class="objective">
            <h2>Objective 3</h2>
            <div class="key-result">
                <p>Key Result 3.1: Description</p>
                <p>Key Result 3.2: Description</p>
                <p>Key Result 3.3: Description</p>
            </div>
        </div>

        <button class="add-btn">Add Objective</button>
    </div>
</body>
</html>
"""

    Column(modifier = Modifier.padding(16.dp)) {
        val richTextState = rememberRichTextState()
        LaunchedEffect(Unit){
            //  richTextState.setHtml(contentHtml.value)
            richTextState.setHtml(html)
        }


        AutoSubmittingRicthTextEditor(richTextState, onClick = {})

    }

}