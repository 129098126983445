package org.company.app.presentation.component.table


import androidx.compose.foundation.*
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Sort
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.runtime.snapshots.SnapshotStateMap
import androidx.compose.runtime.toMutableStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.carddetails.AutoSubmittingTextField

@Composable
fun Table(
    modifier: Modifier = Modifier,
    rowCount: Int,
    columnCount: Int,
    stickyRowCount: Int = 0,
    stickyColumnCount: Int = 0,
    maxCellWidthDp: Dp = Dp.Infinity,
    maxCellHeightDp: Dp = Dp.Infinity,
    verticalScrollState: ScrollState = rememberScrollState(),
    horizontalScrollState: ScrollState = rememberScrollState(),
    customHeaders: List<String> = emptyList(),
    customElements: List<@Composable () -> Unit> = emptyList(),
    cellContent: @Composable (rowIndex: Int, columnIndex: Int, customHeaders: List<String>, customElements: List<@Composable () -> Unit>) -> Unit
) {
    val columnWidths = remember { mutableStateMapOf<Int, Int>() }
    val rowHeights = remember { mutableStateMapOf<Int, Int>() }

    val maxCellWidth = if (listOf(Dp.Infinity, Dp.Unspecified).contains(maxCellWidthDp)) {
        Constraints.Infinity
    } else {
        with(LocalDensity.current) { maxCellWidthDp.toPx() }.toInt()
    }
    val maxCellHeight = if (listOf(Dp.Infinity, Dp.Unspecified).contains(maxCellHeightDp)) {
        Constraints.Infinity
    } else {
        with(LocalDensity.current) { maxCellHeightDp.toPx() }.toInt()
    }

    var accumWidths = mutableListOf<Int>()
    var accumHeights = mutableListOf<Int>()

    @Composable
    fun StickyCells(modifier: Modifier = Modifier, rowCount: Int, columnCount: Int) {
        if (rowCount > 0 && columnCount > 0) {
            Box(modifier = modifier) {
                Layout(
                    content = {
                        (0 until rowCount).forEach { rowIndex ->
                            (0 until columnCount).forEach { columnIndex ->
                                cellContent( rowIndex, columnIndex,customHeaders, customElements)
                            }
                        }
                    },
                ) { measurables, constraints ->
                    val placeables = measurables.mapIndexed { index, it ->
                        val columnIndex = index % columnCount
                        val rowIndex = index / columnCount
                        it.measure(
                            Constraints(
                                minWidth = columnWidths[columnIndex] ?: 0,
                                minHeight = rowHeights[rowIndex] ?: 0,
                                maxWidth = columnWidths[columnIndex] ?: 0,
                                maxHeight = rowHeights[rowIndex] ?: 0
                            )
                        )
                    }

                    val totalWidth = accumWidths[columnCount]
                    val totalHeight = accumHeights[rowCount]

                    layout(width = totalWidth, height = totalHeight) {
                        placeables.forEachIndexed { index, placeable ->
                            val columnIndex = index % columnCount
                            val rowIndex = index / columnCount

                            placeable.placeRelative(
                                accumWidths[columnIndex],
                                accumHeights[rowIndex]
                            )
                        }
                    }
                }
            }
        }
    }

    Box(modifier = modifier) {
        Box(
            modifier = Modifier
                .then(Modifier.horizontalScroll(horizontalScrollState))
                .then(Modifier.verticalScroll(verticalScrollState))
        ) {
            Layout(
                content = {
                    (0 until rowCount).forEach { rowIndex ->
                        (0 until columnCount).forEach { columnIndex ->
                            cellContent(rowIndex, columnIndex,customHeaders, customElements)
                        }
                    }
                },
            ) { measurables, constraints ->
                val placeables = measurables.mapIndexed { index, it ->
                    val columnIndex = index % columnCount
                    val rowIndex = index / columnCount
                    it.measure(
                        Constraints(
                            minWidth = columnWidths[columnIndex] ?: 0,
                            minHeight = rowHeights[rowIndex] ?: 0,
                            maxWidth = maxCellWidth,
                            maxHeight = maxCellHeight
                        )
                    )
                }

                placeables.forEachIndexed { index, placeable ->
                    val columnIndex = index % columnCount
                    val rowIndex = index / columnCount

                    val existingWidth = columnWidths[columnIndex] ?: 0
                    val maxWidth = maxOf(existingWidth, placeable.width)
                    if (maxWidth > existingWidth) {
                        columnWidths[columnIndex] = maxWidth
                    }

                    val existingHeight = rowHeights[rowIndex] ?: 0
                    val maxHeight = maxOf(existingHeight, placeable.height)
                    if (maxHeight > existingHeight) {
                        rowHeights[rowIndex] = maxHeight
                    }
                }

                accumWidths = mutableListOf(0).apply {
                    (1..columnWidths.size).forEach { i ->
                        this += this.last() + columnWidths[i - 1]!!
                    }
                }
                accumHeights = mutableListOf(0).apply {
                    (1..rowHeights.size).forEach { i ->
                        this += this.last() + rowHeights[i - 1]!!
                    }
                }

                val totalWidth = accumWidths.last()
                val totalHeight = accumHeights.last()

                layout(width = totalWidth, height = totalHeight) {
                    placeables.forEachIndexed { index, placeable ->
                        val columnIndex = index % columnCount
                        val rowIndex = index / columnCount

                        placeable.placeRelative(accumWidths[columnIndex], accumHeights[rowIndex])
                    }
                }
            }
        }

        StickyCells(
            modifier = Modifier.horizontalScroll(horizontalScrollState),
            rowCount = stickyRowCount,
            columnCount = columnCount
        )

        StickyCells(
            modifier = Modifier.verticalScroll(verticalScrollState),
            rowCount = rowCount,
            columnCount = stickyColumnCount
        )

        StickyCells(
            rowCount = stickyRowCount,
            columnCount = stickyColumnCount
        )
    }
}

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun HeaderCell(text: MutableState<String>) {
    if (text.value.isNullOrBlank()) {
        androidx.compose.material.Surface {}
        return
    }
    Box(
        modifier = Modifier
            .background(
                color = Color(0xFFf5f5f5),
                shape = RoundedCornerShape(corner = CornerSize(10.dp))
            )
            .border(width = 1.dp, color = Color.Gray)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,modifier = Modifier
            .padding(10.dp)
            .align(Alignment.TopStart)) {
          /*  androidx.compose.material.Text(
                text = text,
                fontWeight = FontWeight.Bold,
            ) */
            ZiFocusTextField(text, placeHolder = "Type Here",modifier = Modifier, withDivider = false,
                textStyle = MaterialTheme. typography. bodySmall. copy(fontSize = 15.sp, fontFamily = MaterialTheme. typography. bodyLarge.fontFamily, fontWeight = FontWeight.Bold,color = Color(0xFF03264A)))

           /* var isAscending by remember { mutableStateOf(true) }
            val header = data.firstOrNull() ?: return
            val rest = data.subList(1, data.size)

            val sortedDataAsc = listOf(header) + rest.sortedWith(compareBy { it[columnIndex] })
            val sortedDataDesc = (listOf(header) + rest.sortedWith(compareByDescending { it[columnIndex] })).toMutableStateList()
            Icon(Icons.AutoMirrored.Filled.Sort, contentDescription = null, modifier = Modifier.clickable {
                isAscending = !isAscending
                val sortedData = if (isAscending) sortedDataAsc else sortedDataDesc

                // Clear and addAll creates a new list instance
                data.clear()
                data.addAll(sortedData)


            }) */
        }

    }
}

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun JobContentCell(text: MutableState<String?>, isChecked: Boolean, alignment: Alignment = Alignment.Center,size:Int,onChange:(String?)->Unit={}) {
    val configuration = LocalWindowInfo.current.containerSize
    val screenWidth = configuration.width
    val textFieldWidth = (screenWidth / size).dp
    Box(modifier = Modifier
        .background(
            color = if (!isChecked) Color.White else Color.Yellow,
            shape = RoundedCornerShape(corner = CornerSize(10.dp))
        ).fillMaxWidth(0.5f)
        .border(width = 1.dp, color = Color.Gray)
        .clickable {
            // do something wonderful
        }
    ) {
        if (text.value==""){
            Text("Type here",
                style = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A), )
                , color = Color.Gray, modifier = Modifier.padding(start = 16.dp).align(Alignment.CenterStart).fillMaxWidth())
        }
        AutoSubmittingTextField(text,
            textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A), )
            , onClick = {
                onChange(text.value)
            }, modifier = Modifier.align(alignment).padding(start = 16.dp).fillMaxWidth())
        /*
        ZiFocusTextField(text, placeHolder = "Type Here",modifier = Modifier
            .padding(10.dp),
            withDivider = false,
            textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A), textAlign = TextAlign.Center)
        ) */

        /* androidx.compose.material.Text(
             text = text.value,
             modifier = Modifier
                 .padding(10.dp)
                 .align(alignment)
         ) */
    }
}

@OptIn(ExperimentalComposeUiApi::class)
@Composable
fun ContentCell(text: MutableState<String?>, isChecked: Boolean, alignment: Alignment = Alignment.Center,size:Int,onChange:(String?)->Unit={}) {
    val configuration = LocalWindowInfo.current.containerSize
    val screenWidth = configuration.width
    val textFieldWidth = (screenWidth).dp
    Box(modifier = Modifier
        .background(
            color = if (!isChecked) Color.White else Color.Yellow,
            shape = RoundedCornerShape(corner = CornerSize(10.dp))
        ).width(textFieldWidth)
        .border(width = 1.dp, color = Color.Gray)
        .clickable {
            // do something wonderful
        }
    ) {
        if (text.value==""){
            Text("Type here",
                    style = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A), )
                , color = Color.Gray, modifier = Modifier.padding(start = 16.dp).align(Alignment.CenterStart).width(textFieldWidth))
        }
        AutoSubmittingTextField(text,
            textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A), )
            , onClick = {
                onChange(text.value)
        }, modifier = Modifier.align(alignment).padding(start = 16.dp).width(textFieldWidth))
        /*
        ZiFocusTextField(text, placeHolder = "Type Here",modifier = Modifier
            .padding(10.dp),
            withDivider = false,
            textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 15.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,color = Color(0xFF03264A), textAlign = TextAlign.Center)
        ) */

       /* androidx.compose.material.Text(
            text = text.value,
            modifier = Modifier
                .padding(10.dp)
                .align(alignment)
        ) */
    }
}
@Composable
fun ContentCell(text: String, isChecked: Boolean, alignment: Alignment = Alignment.TopStart) {
    Box(modifier = Modifier
        .background(
            color = if (!isChecked) Color.White else Color.Yellow,
            shape = RoundedCornerShape(corner = CornerSize(10.dp))
        )
        .border(width = 1.dp, color = Color.Gray)
        .clickable {
            // do something wonderful
        }
    ) {

         androidx.compose.material.Text(
             text = text,
             modifier = Modifier
                 .padding(10.dp)
                 .align(alignment)
         )
    }
}

@Composable
fun ToggleCell(row: Int, checkedRows: SnapshotStateMap<Int, Unit>) {
    Box(contentAlignment = Alignment.Center, modifier = Modifier.background(Color.White)) {
        androidx.compose.material.Switch(
            checked = checkedRows.containsKey(row),
            onCheckedChange = {
                if (!checkedRows.containsKey(row)) {
                    checkedRows[row] = Unit
                } else {
                    checkedRows.remove(row)
                }
            }
        )
    }
}
