/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package api

import org.openapitools.client.models.BoardDetailResponse
import org.openapitools.client.models.BoardListResponse
import org.openapitools.client.models.BoardMemberInviteRequest
import org.openapitools.client.models.BoardMemberInviteResponse
import org.openapitools.client.models.BoardMemberInviteUserResponseRequest
import org.openapitools.client.models.BoardMembersListResponse
import org.openapitools.client.models.BoardRequest
import org.openapitools.client.models.BoardResponse
import org.openapitools.client.models.ErrorData
import org.openapitools.client.models.GetNewLoopDetailsListResponse
import org.openapitools.client.models.GetNewLoopDetailsResponse
import org.openapitools.client.models.InfiniteBoardRequest
import org.openapitools.client.models.InfiniteBoardResponse
import org.openapitools.client.models.InfiniteListBoardResponse
import org.openapitools.client.models.LoopComponentsListResponse
import org.openapitools.client.models.ManagedWorkResponse
import org.openapitools.client.models.MessageResponse
import org.openapitools.client.models.NewLoopRequest
import org.openapitools.client.models.ProjectListResponse
import org.openapitools.client.models.SaveLoopDataInDbRequest
import org.openapitools.client.models.SaveLoopDataInDbResponse
import org.openapitools.client.models.SelectedColumnResponse
import org.openapitools.client.models.SetRoleResponse
import org.openapitools.client.models.ViewLayoutResponse

import org.openapitools.client.infrastructure.*
import io.ktor.client.HttpClient
import io.ktor.client.HttpClientConfig
import io.ktor.client.request.forms.formData
import io.ktor.client.engine.HttpClientEngine
import kotlinx.serialization.json.Json
import io.ktor.http.ParametersBuilder
import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

open class BoardsApi : ApiClient {

    constructor(
        baseUrl: String = ApiClient.BASE_URL,
        httpClientEngine: HttpClientEngine? = null,
        httpClientConfig: ((HttpClientConfig<*>) -> Unit)? = null,
        jsonSerializer: Json = ApiClient.JSON_DEFAULT
    ) : super(baseUrl = baseUrl, httpClientEngine = httpClientEngine, httpClientConfig = httpClientConfig, jsonBlock = jsonSerializer)

    constructor(
        baseUrl: String,
        httpClient: HttpClient
    ): super(baseUrl = baseUrl, httpClient = httpClient)

    /**
     * Create a new board
     * 
     * @param boardRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return BoardResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createBoard(boardRequest: BoardRequest, authorization: kotlin.String? = null): HttpResponse<BoardResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = boardRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/boards/createBoards",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * create Infinite Board
     * 
     * @param infiniteBoardRequest board Data
     * @param authorization  (optional)
     * @return InfiniteBoardResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createInfiniteBoard(infiniteBoardRequest: InfiniteBoardRequest, authorization: kotlin.String? = null): HttpResponse<InfiniteBoardResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = infiniteBoardRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createInfiniteBoard",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * createNewLoop
     * 
     * @param newLoopRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return GetNewLoopDetailsResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun createNewLoop(newLoopRequest: NewLoopRequest, authorization: kotlin.String? = null): HttpResponse<GetNewLoopDetailsResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = newLoopRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/createNewLoop",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Delete a board
     * 
     * @param boardId 
     * @param authorization Bearer token for authentication (optional)
     * @return BoardListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteBoard(boardId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<BoardListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/boards/deleteBoard/{boardId}".replace("{" + "boardId" + "}", "$boardId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * delete Infinite Board
     * 
     * @param id 
     * @param authorization  (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteInfiniteBoard(id: kotlin.String, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/deleteInfiniteBoard/{id}".replace("{" + "id" + "}", "$id"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * get all Infinite Board
     * 
     * @param authorization  (optional)
     * @return InfiniteListBoardResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getAllInfiniteBoard(authorization: kotlin.String? = null): HttpResponse<InfiniteListBoardResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getAllInfiniteBoard",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * getAllLoopComponents
     * 
     * @param authorization Bearer token for authentication (optional)
     * @return LoopComponentsListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getAllLoopComponents(authorization: kotlin.String? = null): HttpResponse<LoopComponentsListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getAllLoopComponents",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * getAllLoopList
     * 
     * @param authorization Bearer token for authentication (optional)
     * @param name fiter on name (optional)
     * @param nextPageToken asdaksljdfksajf (optional)
     * @return GetNewLoopDetailsListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getAllLoopList(authorization: kotlin.String? = null, name: kotlin.String? = null, nextPageToken: kotlin.String? = null): HttpResponse<GetNewLoopDetailsListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        name?.apply { localVariableQuery["name"] = listOf("$name") }
        nextPageToken?.apply { localVariableQuery["nextPageToken"] = listOf("$nextPageToken") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getAllLoopList",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get board details by ID
     * get user&#39;s created board
     * @param id id of board
     * @param authorization Access token for authorization (optional)
     * @return BoardDetailResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getBoard(id: kotlin.String, authorization: kotlin.String? = null): HttpResponse<BoardDetailResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        id?.apply { localVariableQuery["id"] = listOf("$id") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/boards/getBoard",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * getBoardMembers
     * getBoardMembers
     * @param boardId Access token for authorization
     * @param authorization Access token for authorization (optional)
     * @return BoardMembersListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getBoardMembers(boardId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<BoardMembersListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        boardId?.apply { localVariableQuery["boardId"] = listOf("$boardId") }
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/boards/getBoardMembers",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * get detail of Infinite Board
     * 
     * @param id 
     * @param authorization  (optional)
     * @return InfiniteBoardResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getInfiniteBoardDetails(id: kotlin.String, authorization: kotlin.String? = null): HttpResponse<InfiniteBoardResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getInfiniteBoardDetails/{id}".replace("{" + "id" + "}", "$id"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Retrieve managed work information
     * 
     * @param authorization Access token for authorization (optional)
     * @return ManagedWorkResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getManageWork(authorization: kotlin.String? = null): HttpResponse<ManagedWorkResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/boards/getManageWork",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get My Boards
     * get user&#39;s created boards
     * @param authorization Access token for authorization (optional)
     * @return BoardListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getMyBoards(authorization: kotlin.String? = null): HttpResponse<BoardListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/boards/getMyBoards",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * getNewLoopDetails
     * 
     * @param loopId 
     * @param authorization Bearer token for authentication (optional)
     * @return GetNewLoopDetailsResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getNewLoopDetails(loopId: kotlin.String, authorization: kotlin.String? = null): HttpResponse<GetNewLoopDetailsResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/getNewLoopDetails/{loopId}".replace("{" + "loopId" + "}", "$loopId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get Select Column
     * select columns for your board..
     * @param authorization Access token for authorization (optional)
     * @return SelectedColumnResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getSelectColumn(authorization: kotlin.String? = null): HttpResponse<SelectedColumnResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/boards/getSelectColumn",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get View Layout
     * Retrieve the layout for a specific view
     * @param authorization Access token for authorization (optional)
     * @return ViewLayoutResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getViewLayout(authorization: kotlin.String? = null): HttpResponse<ViewLayoutResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/boards/getViewLayout",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Invite board members
     * Endpoint to invite board members via shared link or email
     * @param boardMemberInviteRequest 
     * @param authorization Access token for authorization (optional)
     * @return BoardMemberInviteResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun inviteBoardMembers(boardMemberInviteRequest: BoardMemberInviteRequest, authorization: kotlin.String? = null): HttpResponse<BoardMemberInviteResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = boardMemberInviteRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/boards/inviteBoardMembers",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Retrieve a list of user projects
     * Returns a list of projects associated with the user
     * @param authorization Access token for authorization (optional)
     * @return ProjectListResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun projects(authorization: kotlin.String? = null): HttpResponse<ProjectListResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/boards/projectList",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * responseToBoardInviteRequest
     * Endpoint to accept/reject board invite request
     * @param boardMemberInviteUserResponseRequest 
     * @param authorization Access token for authorization (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun responseToBoardInviteRequest(boardMemberInviteUserResponseRequest: BoardMemberInviteUserResponseRequest, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = boardMemberInviteUserResponseRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/boards/responseToBoardInviteRequest",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * saveLoopDataInDb
     * 
     * @param loopId 
     * @param saveLoopDataInDbRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return SaveLoopDataInDbResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun saveLoopDataInDb(loopId: kotlin.String, saveLoopDataInDbRequest: SaveLoopDataInDbRequest, authorization: kotlin.String? = null): HttpResponse<SaveLoopDataInDbResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = saveLoopDataInDbRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/saveLoopDataInDb/{loopId}".replace("{" + "loopId" + "}", "$loopId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Set role for team members
     * This API allows setting roles for team members.
     * @param setRoleResponse 
     * @param authorization Access token for authorization (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun setRoleOfTeamMembers(setRoleResponse: SetRoleResponse, authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = setRoleResponse

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/boards/setRoleOfTeamMembers",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update a board
     * 
     * @param boardId 
     * @param boardRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return BoardResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateBoard(boardId: kotlin.String, boardRequest: BoardRequest, authorization: kotlin.String? = null): HttpResponse<BoardResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = boardRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/boards/updateBoard/{boardId}".replace("{" + "boardId" + "}", "$boardId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * update Infinite Board
     * 
     * @param id 
     * @param infiniteBoardRequest board Data
     * @param authorization  (optional)
     * @return InfiniteBoardResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateInfiniteBoard(id: kotlin.String, infiniteBoardRequest: InfiniteBoardRequest, authorization: kotlin.String? = null): HttpResponse<InfiniteBoardResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = infiniteBoardRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/updateInfiniteBoard/{id}".replace("{" + "id" + "}", "$id"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * updateNewLoop
     * 
     * @param loopId 
     * @param newLoopRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return GetNewLoopDetailsResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateNewLoop(loopId: kotlin.String, newLoopRequest: NewLoopRequest, authorization: kotlin.String? = null): HttpResponse<GetNewLoopDetailsResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = newLoopRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/updateNewLoop/{loopId}".replace("{" + "loopId" + "}", "$loopId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * updateSaveLoopDataInDb
     * 
     * @param componentId 
     * @param saveLoopDataInDbRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return SaveLoopDataInDbResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateSaveLoopDataInDb(componentId: kotlin.String, saveLoopDataInDbRequest: SaveLoopDataInDbRequest, authorization: kotlin.String? = null): HttpResponse<SaveLoopDataInDbResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = saveLoopDataInDbRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/updateSaveLoopDataInDb/{componentId}".replace("{" + "componentId" + "}", "$componentId"),
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



}
