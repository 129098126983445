package org.company.app.presentation.ui


import Route
import Whiteboard
import androidx.compose.foundation.*
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.autoSaver
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import kotlinx.serialization.json.Json
import org.company.app.presentation.component.*
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.koin.compose.koinInject
import org.openapitools.client.models.InfiniteBoardDataType
import org.openapitools.client.models.NewLoopRequest
import org.openapitools.client.models.SaveLoopDataInDb
import org.openapitools.client.models.SaveLoopDataInDbRequest

object LoopComponentScreen :Screen {


    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val viewModel= koinInject<HomeViewModel>()
        val homeState = viewModel.homeState.collectAsState()

        if (homeState.value.navigateToHome){
            navigator.pop()
            viewModel.navigateToHome()
        }

        LoopComponentScreenContent()
    }
    @OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3WindowSizeClassApi::class,
        ExperimentalFoundationApi::class, ExperimentalComposeUiApi::class
    )
    @Composable
    fun LoopComponentScreenContent(){
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        val addIdea = remember { mutableStateOf(false) }
        if (addIdea.value){
            CreateIdeaDialog(onDismiss = {addIdea.value=false})
        }
            Column(modifier = Modifier.fillMaxSize().padding(top = 40.dp)) {
                EditableScreen()
            }

    }
}









@Composable
fun EditableScreen() {

    val viewModel = koinInject<HomeViewModel>()
    val homeState = viewModel.homeState.collectAsState()


    val loopComponents  =homeState.value.currentComponents


      //  viewModel.getInfiniteBoard()

    var textFields by remember { mutableStateOf(listOf<TextFieldState>()) }

    Box (
        modifier = Modifier
            .fillMaxSize()
            .pointerInput(Unit) {
                detectTapGestures(
                    onPress = { offset ->
                        // Remove all empty TextFields
                        textFields = textFields.filter { it.value.isNotEmpty() }

                        // Add a new TextField at the start position
                        if (textFields.none { it.x == offset.x }) {
                            textFields = textFields + TextFieldState(0f, offset.y, "")
                        }

                    }
                )
            }
    ) {
        val density = LocalDensity.current
        val focusRequester = remember { FocusRequester() }
        Column(modifier = Modifier.fillMaxWidth()) {
            loopComponents?.forEach {loop->
                val basicRichTextState = rememberRichTextState()
                LoopComponentLoad(Modifier.focusRequester(focusRequester)

                    .fillMaxWidth(),basicRichTextState,loop, onClick = {
                    viewModel.updateSaveLoopComponentInDb(componentId = loop.id?:"",SaveLoopDataInDbRequest(type = loop.type, data = it) )
                    //  viewModel.createInfiniteBoard(mapTextFieldStateToData(textFields))
                }, createTable = {
                    // viewModel.createInfiniteBoard(data = mapTextFieldStateToData(textFields), tableData = it)
                    viewModel.updateSaveLoopComponentInDb(componentId = loop.id?:"",SaveLoopDataInDbRequest(type = loop.type, tableData = it))
                })

            }
        }

        /*
        LazyColumn {
            items(loopComponents?: emptyList()){loop->
                Text(loop.type?:"")
                val basicRichTextState = rememberRichTextState()
                LoopComponentLoad(Modifier.focusRequester(focusRequester)

                    .fillMaxWidth(),basicRichTextState,loop, onClick = {
                    viewModel.updateSaveLoopComponentInDb(componentId = loop.id?:"",SaveLoopDataInDbRequest(type = loop.type, data = it) )
                    //  viewModel.createInfiniteBoard(mapTextFieldStateToData(textFields))
                }, createTable = {
                    // viewModel.createInfiniteBoard(data = mapTextFieldStateToData(textFields), tableData = it)
                    viewModel.updateSaveLoopComponentInDb(componentId = loop.id?:"",SaveLoopDataInDbRequest(type = loop.type, tableData = it))
                })

                //  Icon(Icons.Default.Delete,contentDescription = null, modifier = Modifier.align(Alignment.TopEnd).clickable { textFields = textFields - textField })

            }
        }
        */

            textFields.forEach { textField ->
                val basicRichTextState = rememberRichTextState()
                LoopComponent(Modifier.focusRequester(focusRequester)
                        .offset(
                            x = with(density) { 0f.toDp() }, // Always start at 0
                            y = with(density) { textField.y.toDp() }
                        )
                        .fillMaxWidth(),basicRichTextState,textField, onClick = {
                        println(textFields.toString())
                    viewModel.saveLoopComponentInDb(SaveLoopDataInDbRequest(type = textField.type, data = it), loopId =homeState.value.currentLoop.data?.id?:"" )
                      //  viewModel.createInfiniteBoard(mapTextFieldStateToData(textFields))
                    }, createTable = {
                      // viewModel.createInfiniteBoard(data = mapTextFieldStateToData(textFields), tableData = it)
                    viewModel.saveLoopComponentInDb(SaveLoopDataInDbRequest(type = textField.type, tableData = it),loopId =homeState.value.currentLoop.data?.id?:"")
                    })

                Icon(Icons.Default.Delete,contentDescription = null, modifier = Modifier.align(Alignment.TopEnd).clickable { textFields = textFields - textField })



                textFields = textFields.map {
                    if (it === textField) it.copy(value = basicRichTextState.annotatedString.text) else it
                }



            }


        if (textFields.isNotEmpty()){
            LaunchedEffect(textFields.lastOrNull()){
                focusRequester.requestFocus()
            }
        }

    }
}

data class TextFieldState(val x: Float, val y: Float, var value: String = "", var type:String="text")

fun mapTextFieldStateToData(textFieldStateList: List<TextFieldState>): List<InfiniteBoardDataType> {
    return textFieldStateList.map { textFieldState ->
        InfiniteBoardDataType(
            type = textFieldState.type,
            value = textFieldState.value,

        )
    }
}


