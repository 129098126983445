package org.company.app.presentation.ui.goals

import androidx.compose.foundation.*
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.seiko.imageloader.rememberImagePainter
import multiplatform_app.composeapp.generated.resources.*
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.low_flag
import multiplatform_app.composeapp.generated.resources.medium_flag
import multiplatform_app.composeapp.generated.resources.remove_filter
import multiplatform_app.composeapp.generated.resources.urgent_flag
import org.company.app.data.model.BoardMemberData
import org.company.app.presentation.component.board.card.TableCell
import org.company.app.presentation.ui.board.formatDateString
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject

object GoalsScreen :Screen {


    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val viewModel= koinInject<HomeViewModel>()
        val homeState = viewModel.homeState.collectAsState()
        if (homeState.value.navigateToHome){
            navigator.pop()
            viewModel.navigateToHome()
        }
        GoalsScreenContent()
    }
    @OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3WindowSizeClassApi::class,
        ExperimentalFoundationApi::class
    )
    @Composable
    fun GoalsScreenContent(){
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        Column(
            modifier = Modifier.fillMaxSize(),

            ) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(horizontal = if (useNavRail) 20.dp else 4.dp)
                    .padding(top = 20.dp)
            ) {
                Text(
                    "Goals", fontSize = 24.sp,
                    style = MaterialTheme.typography.bodyLarge,
                    fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                    color = Color(0xFF032549)
                )
                Spacer(modifier = Modifier.weight(1f))
                    Button(
                        contentPadding = PaddingValues(horizontal = 10.dp, vertical = 4.dp),
                        modifier = Modifier.defaultMinSize(1.dp, 1.dp),
                        onClick = {  },
                        colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.primaryContainer)
                    ) {
                        Text(
                            text = buildAnnotatedString {
                                withStyle(
                                    SpanStyle(
                                        fontSize = 20.sp,
                                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                        fontStyle = MaterialTheme.typography.bodyMedium.fontStyle
                                    )
                                ) {
                                    append("+")
                                }
                                append("  Add Goal")
                            },
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                            style = MaterialTheme.typography.bodySmall,
                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                            fontSize = 14.sp
                        )
                    }
                    Spacer(modifier = Modifier.width(12.dp))
                    Box {
                        IconButton(
                            onClick = {  }
                        ) {
                            Icon(
                                painter = painterResource(Res.drawable.filter),
                                contentDescription = "Filter"
                            )
                        }

                    }
                    Spacer(modifier = Modifier.width(3.dp))

                        IconButton(
                            onClick = {  }
                        ) {
                            Icon(
                                imageVector = Icons.Default.MoreVert,
                                contentDescription = "More Options"
                            )
                        }





            }
            Spacer(Modifier.height(12.dp))
            Row {
                Card(elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
                    colors = CardDefaults.cardColors(containerColor = Color(0xFFe2eeff)),
                    shape = RoundedCornerShape(16.dp), modifier = Modifier.fillMaxWidth(0.5f).height(300.dp).padding(start = if (useNavRail) 20.dp else 4.dp)
                ) {
                    Column(modifier = Modifier.fillMaxSize().padding(vertical = 34.dp)) {
                        Row (modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.SpaceEvenly){
                            Card(elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
                                colors = CardDefaults.cardColors(containerColor = Color(0xFFFAFAFA)),
                                shape = RoundedCornerShape(16.dp), modifier = Modifier.width(250.dp).height(100.dp)
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxHeight()) {
                                    Text("Goals\n" +
                                            "Planned", fontSize = 20.sp, style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A),
                                        modifier = Modifier.padding(start = 16.dp))
                                    Spacer(modifier = Modifier.weight(1f))
                                    Text("15", fontSize = 64.sp, style = MaterialTheme.typography.bodyLarge, fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                                        color = Color(0xFF03264A),
                                        modifier = Modifier.padding(end = 16.dp))
                                }
                            }

                            Card(elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
                                colors = CardDefaults.cardColors(containerColor = Color(0xFFFAFAFA)),
                                shape = RoundedCornerShape(16.dp), modifier = Modifier.width(250.dp).height(100.dp)
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxHeight()) {
                                    Text("Goals\n" +
                                            "In progress", fontSize = 20.sp, style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A),
                                        modifier = Modifier.padding(start = 16.dp))
                                    Spacer(modifier = Modifier.weight(1f))
                                    Text("05", fontSize = 64.sp, style = MaterialTheme.typography.bodyLarge, fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                                        color = Color(0xFF03264A),
                                        modifier = Modifier.padding(end = 16.dp))
                                }
                            }

                        }
                        Spacer(modifier = Modifier.height(32.dp))
                        Row (modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.SpaceEvenly){
                            Card(elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
                                colors = CardDefaults.cardColors(containerColor = Color(0xFFFAFAFA)),
                                shape = RoundedCornerShape(16.dp), modifier = Modifier.width(250.dp).height(100.dp)
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxHeight()) {
                                    Text("Goals\n" +
                                            "Stopped", fontSize = 20.sp, style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A),
                                        modifier = Modifier.padding(start = 16.dp))
                                    Spacer(modifier = Modifier.weight(1f))
                                    Text("03", fontSize = 64.sp, style = MaterialTheme.typography.bodyLarge, fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                                        color = Color(0xFF03264A),
                                        modifier = Modifier.padding(end = 16.dp))
                                }
                            }

                            Card(elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
                                colors = CardDefaults.cardColors(containerColor = Color(0xFFFAFAFA)),
                                shape = RoundedCornerShape(16.dp), modifier = Modifier.width(250.dp).height(100.dp)
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxHeight()) {
                                    Text("Goals\n" +
                                            "Completed", fontSize = 20.sp, style = MaterialTheme.typography.bodySmall, fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                        color = Color(0xFF5C728A),
                                        modifier = Modifier.padding(start = 16.dp))
                                    Spacer(modifier = Modifier.weight(1f))
                                    Text("07", fontSize = 64.sp, style = MaterialTheme.typography.bodyLarge, fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                                        color = Color(0xFF03264A),
                                        modifier = Modifier.padding(end = 16.dp))
                                }
                            }

                        }

                    }
                }
                Spacer(modifier = Modifier.width(64.dp))
                // progress bar here
                Box {
                    // Progress
                    Text(
                        "Progress", fontSize = 20.sp,
                        style = MaterialTheme.typography.bodyMedium,
                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                        color = Color(0xFF032549)
                    )
                    Box(contentAlignment = Alignment.Center,
                        modifier = Modifier.padding(top = 24.dp, start = 70.dp)
                    ) {
                        Card(
                            modifier = Modifier
                                .size(270.dp) ,// Add padding for elevation effect
                            elevation = 4.dp, // Set the desired elevation value
                            shape = CircleShape, // Use a circular shape
                            backgroundColor = Color.Transparent

                        ) {
                            CircularProgressIndicator(
                                progress = { (7/10f) },
                                modifier = Modifier.size(270.dp),
                                color = Color(0xFF3D8BFD),
                                trackColor = Color(0xFFFAFAFA),
                                strokeWidth = 24.dp,
                            )
                        }
                        Column {
                            Text("Completed", color = Color(0xFF737373),
                                style = MaterialTheme.typography.bodyMedium,
                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                fontSize = 14.sp)
                            Text("70%", color = Color(0xFF5C728A),
                                style = MaterialTheme.typography.bodyLarge,
                                fontFamily = MaterialTheme.typography.bodyLarge.fontFamily,
                                fontSize = 32.sp
                                )
                        }




                    }

                }
            }

            Spacer(modifier = Modifier.height(32.dp))
                val tableData = (1..25).mapIndexed { index, item ->
                    index to "Item $index"
                }
                // Each cell of a column must have the same weight.
                val columnWeight = 1f/0.7f


                // Here is the header
               Row(modifier = Modifier.fillMaxWidth().padding(start = 16.dp), horizontalArrangement = Arrangement.Start) {
                TableCell(text = "Title", weight = columnWeight)
                 //  Spacer(modifier = Modifier.weight(1f))
                TableCell(text = "Description", weight = columnWeight)
                   Spacer(modifier = Modifier.weight(1f))
                TableCell(text = "Progress", weight = columnWeight)
                  // Spacer(modifier = Modifier.weight(1f))
                TableCell(text = "Target", weight = columnWeight)
                  // Spacer(modifier = Modifier.weight(1f))
                TableCell(text = "Target date", weight = columnWeight)
                 //  Spacer(modifier = Modifier.weight(1f))
                TableCell(text = "Members", weight = columnWeight)
                 //  Spacer(modifier = Modifier.weight(1f))
                TableCell(text = "Option", weight = columnWeight)


            }
                LazyColumn(Modifier.fillMaxSize().padding(16.dp)) {
                    // Here is the header
                    // Here are all the lines of your table.
                    items(tableData) {
                        val (id, text) = it
                        Row(Modifier.fillMaxWidth().padding(vertical = 12.dp),horizontalArrangement = Arrangement.Start) {
                            // Task Name
                            Row(modifier=Modifier.weight(columnWeight)) {
                                Text("Design", color = Color(0xFF5C728A)
                                , fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily
                                )
                            }
                           // Spacer(modifier = Modifier.weight(1f))
                            // Priority
                            Row(modifier=Modifier.weight(columnWeight)) {
                                Text("Description here", color = Color(0xFF03264A)
                                    , fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily
                                )
                            }
                            Spacer(modifier = Modifier.weight(1f))
                            // Status
                            Row(modifier=Modifier.weight(columnWeight).padding(start = 8.dp), horizontalArrangement = Arrangement.Start) {
                                Text("50%", color = Color(0xFF03264A)
                                    , fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                )
                            }
                          //  Spacer(modifier = Modifier.weight(1f))
                            // Assigned
                            Row(modifier=Modifier.weight(columnWeight).padding(start = 8.dp),horizontalArrangement = Arrangement.Start) {
                                Text("5", color = Color(0xFF03264A)
                                    , fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                                )
                            }
                          //  Spacer(modifier = Modifier.weight(1f))
                            //Schedule
                            Row(modifier=Modifier.weight(columnWeight).padding(start = 8.dp),horizontalArrangement = Arrangement.Start) {
                                Text("05-05-2024", color = Color(0xFF03264A)
                                    , fontSize = 14.sp, style = MaterialTheme.typography.bodySmall,
                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,

                                )
                            }
                           // Spacer(modifier = Modifier.weight(1f))

                            // Due Date
                            Row(modifier=Modifier.weight(columnWeight).padding(start = 8.dp),horizontalArrangement = Arrangement.Start) {
                                Image(rememberImagePainter("https://i.postimg.cc/283wZFKf/Ellipse-116.png"),contentDescription = null, modifier = Modifier.padding(end = 6.dp).size(18.dp))
                                Image(rememberImagePainter("https://i.postimg.cc/283wZFKf/Ellipse-116.png"),contentDescription = null, modifier = Modifier.padding(end = 6.dp).size(18.dp))
                                Image(rememberImagePainter("https://i.postimg.cc/283wZFKf/Ellipse-116.png"),contentDescription = null, modifier = Modifier.padding(end = 6.dp).size(18.dp))

                            }
                          //  Spacer(modifier = Modifier.weight(1f))
                            // Delete
                            Row(modifier=Modifier.weight(columnWeight).padding(start = 8.dp),horizontalArrangement = Arrangement.Start, verticalAlignment = Alignment.CenterVertically) {
                                Icon(
                                    imageVector = Icons.Default.MoreVert,
                                    contentDescription = "More Options",
                                    modifier = Modifier.rotate(90f)
                                )
                            }



                        }
                    }
                }



        }
  }
}