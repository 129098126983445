package org.company.app.presentation.component

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Sort
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Expand
import androidx.compose.material.icons.filled.Filter
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material.icons.filled.Search
import androidx.compose.material.icons.filled.Sort
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onKeyEvent
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.PopupProperties
import com.mohamedrejeb.richeditor.model.RichTextState
import com.seiko.imageloader.rememberImagePainter
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.assign_member_
import org.company.app.presentation.component.table.ContentCell
import org.company.app.presentation.component.table.HeaderCell
import org.company.app.presentation.component.table.Table
import org.company.app.presentation.component.table.ToggleCell
import org.company.app.presentation.ui.TextFieldState
import org.company.app.presentation.ui.carddetails.AutoSubmittingRicthTextEditor
import org.company.app.presentation.utils.prettyFormat
import org.company.app.presentation.utils.selectedDateMillisToLocalDateTime
import org.company.app.presentation.ui.board.StatusChooseCreateTask
import org.company.app.presentation.ui.board.formatDateString
import org.company.app.presentation.utils.dateTimeToString
import org.company.app.presentation.utils.getRandomColorForColumns
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.SaveLoopDataInDb
import org.openapitools.client.models.SaveLoopDataInDbRequest
import org.openapitools.client.models.TaskItem
import org.openapitools.client.models.TaskStatusDetailsResponse

@OptIn(ExperimentalComposeUiApi::class, ExperimentalMaterial3Api::class)
@Composable
fun LoopComponent(modifier: Modifier = Modifier, basicRichTextState: RichTextState, textField: TextFieldState=TextFieldState(x = 0f, y = 0f),onClick: (String)->Unit={},createTable: (List<MutableList<String>>)->Unit={}){
    val isDropdownMenuExpanded = remember { mutableStateOf(false) }
    val divider = remember { mutableStateOf(false) }
    val isTable = remember { mutableStateOf(false) }
    val isTeamRetrospective = remember { mutableStateOf(false) }
    val viewModel = koinInject<HomeViewModel>()
    var datePicker = remember { mutableStateOf(false) }
    var jobTemplate = remember { mutableStateOf(false) }
    var okrTemplate = remember { mutableStateOf(false) }
    var callout = remember { mutableStateOf(false) }
    var isDate = remember { mutableStateOf(false) }
    var kanbanBoard = remember { mutableStateOf(false) }
    var loopCreated = remember { mutableStateOf(false) }
    var dateSelected =rememberDatePickerState(yearRange = 2023..2050,)
    val customHeaders = remember { mutableStateListOf<String>() }
    val customElements = remember { mutableStateListOf<@Composable () -> Unit>() }
    val data = remember { mutableStateListOf(
        mutableStateListOf("#","Column 1", "Column 2"),
        mutableStateListOf("","", ""),
        ) }




    Column(modifier) {
        Box() {
            if (!loopCreated.value){
                if (basicRichTextState.annotatedString.text==""){
                    Text("Type Description", fontSize = 18.sp, style = MaterialTheme.typography.bodySmall, fontWeight = FontWeight.Light,color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
                }
                AutoSubmittingRicthTextEditor(basicRichTextState, onClick = {
                    if (basicRichTextState.annotatedString.text!="/"&&!isDate.value){
                        onClick(basicRichTextState.annotatedString.text)
                    }

                }, textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A), fontWeight = FontWeight.Light),
                    modifier = Modifier.onKeyEvent {
                        if (it.key == Key.Slash) {
                            // Perform action when "/" is pressed
                            isDropdownMenuExpanded.value=true
                            true
                        } else {
                            false
                        }
                    }.alpha(if(basicRichTextState.annotatedString.text==".") 0f else 1f)
                )
            }



            DropdownMenu(
                expanded = isDropdownMenuExpanded.value,
                onDismissRequest = { isDropdownMenuExpanded.value = false },
                modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White)
            ){
                Text("General", fontSize = 11.sp, style =  MaterialTheme.typography.bodySmall,
                    fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                    ,modifier = Modifier.padding(start = 6.dp))


                DropdownMenuItem(
                    text = { Text("Job Template",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        jobTemplate.value = true
                        textField.type = "job_template"

                    }
                )
                DropdownMenuItem(
                    text = { Text("Okr Template",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        okrTemplate.value = true


                    }
                )

                DropdownMenuItem(
                    text = { Text("Kanban Board",
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                      //  kanbanBoard.value = true
                       // basicRichTextState.setText(basicRichTextState.annotatedString.text.replace("/","."))
                        val tasks: MutableList<TaskItem> = mutableListOf(
                            TaskItem(id = "id_1", taskStatus = "toDoId", dueDate = null,taskStatusDetails = TaskStatusDetailsResponse(id = "toDoId",name = "To Do", value = "TO_DO"), name = "Test"),
                            TaskItem(id = "id_2", taskStatus = "inProgressId", dueDate = null,taskStatusDetails = TaskStatusDetailsResponse(id = "inProgressId", name = "In Progress", value = "IN_PROGRESS"), name = "Test"),
                        )
                        val taskStatusDetails =   mutableStateListOf(TaskStatusDetailsResponse(id = "toDoId",name = "To Do", value = "TO_DO"),TaskStatusDetailsResponse(id = "inProgressId", name = "In Progress", value = "IN_PROGRESS"),TaskStatusDetailsResponse(id = "doneId",name = "Done", value = "DONE"))

                        //  KanbanBoard()
                        viewModel.saveLoopComponentInDb(loopId = viewModel.homeState.value.currentLoop.data?.id?:"", saveLoopDataInDbRequest = SaveLoopDataInDbRequest(type = "kanban", boardData = tasks, columns = taskStatusDetails))


                    }
                )

                DropdownMenuItem(
                    text = { Text("Callout",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        callout.value = true
                        basicRichTextState.setText(basicRichTextState.annotatedString.text.replace("/","."))


                    }
                )


                DropdownMenuItem(
                    text = { Text("Date",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        isDate.value = true
                        datePicker.value = true
                        textField.type = "date"
                        basicRichTextState.setText(" ")
                    }
                )

                DropdownMenuItem(
                    text = { Text("Table",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                      //  isTable.value = true
                        textField.type = "table"
                      //  basicRichTextState.setText("Table")
                        loopCreated.value = true
                        createTable(data)
                    }
                )
                DropdownMenuItem(
                    text = { Text("Voting Table",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        data[0].clear()
                        data[1].clear()
                        data[0].addAll(mutableListOf("Idea","Pros","Cons","Votes"))
                        data[1].addAll(mutableListOf("","","",""))

                        customHeaders.add("Votes")
                        customElements.add {

                            val counter = mutableStateOf(0)
                                OutlinedButton(modifier = Modifier.align(Alignment.CenterHorizontally).padding(start = 6.dp),onClick = {
                                    counter.value++
                                }) {
                                    Text(counter.value.toString(), color= Color.Black)
                                }


                        }


                        isTable.value = true
                        textField.type = "voting_table"
                        basicRichTextState.setText("Voting Table")
                    }
                )

                DropdownMenuItem(
                    text = { Text("Progress Tracker",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        isTable.value = true
                        textField.type = "progress_tracker"
                        basicRichTextState.setText("Progress Tracker")
                        data[0].clear()
                        data[1].clear()
                        data[0].addAll(mutableListOf("Work Area","Owner","Status","End date","Blockers"))
                        data[1].addAll(mutableListOf("","","","",""))
                        customHeaders.addAll(mutableListOf("Owner","Status","End date","Blockers"))
                        customElements.addAll(
                            mutableListOf(
                                {
                                val viewModel = koinInject<HomeViewModel>()
                                var assignToMember = remember { mutableStateOf(false) }
                                val userId = viewModel.homeState.value.user?.id
                                var memberAssigned = remember { mutableStateOf("") }
                                var assignedToId = remember { mutableStateOf("") }
                                var memberAssignedAvatar = remember { mutableStateOf("") }
                                Box {

                                    //Collecting states from ViewModel
                                    val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                                    val countriesList by viewModel.boardMemberList.collectAsState()


                                    TextButton(
                                        onClick = { assignToMember.value = !assignToMember.value }
                                    ) {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            if (memberAssigned.value==""){
                                                Image(painterResource(Res.drawable.assign_member_),modifier = Modifier.size(24.dp), contentDescription = null)
                                            } else {

                                                if (memberAssignedAvatar.value.isEmpty()){
                                                    val name = memberAssigned.value
                                                    val words = name.split(" ")
                                                    val initials = if (words.size > 1) {
                                                        words[0].take(1) + words[words.lastIndex].take(1)
                                                    } else {
                                                        words[0].take(2)
                                                    }.uppercase()
                                                    TextButton(onClick = {},modifier = Modifier
                                                        .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)
                                                    ){
                                                        Text(
                                                            text = initials,
                                                            fontSize = 16.sp,
                                                            color = Color.White,
                                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                            maxLines = 1,
                                                            overflow = TextOverflow.Ellipsis,
                                                            textAlign = TextAlign.Center
                                                        )
                                                    }

                                                }
                                                else {
                                                    Image(
                                                        rememberImagePainter(memberAssignedAvatar.value?:""),modifier = Modifier
                                                            .size(30.dp)
                                                            .clip(CircleShape)
                                                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                }
                                                Spacer(modifier = Modifier.width(6.dp))
                                                Text(memberAssigned.value,
                                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                    style =  MaterialTheme.typography.bodySmall
                                                    ,
                                                    fontSize = 14.sp, color = Color(0xFF5C728A),modifier = Modifier.padding(start = 4.dp))
                                            }

                                        }

                                    }
                                    DropdownMenu(
                                        expanded = assignToMember.value,
                                        onDismissRequest = { assignToMember.value = false },
                                        modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                    ){
                                        Text("Assign to", fontSize = 16.sp,
                                            //   fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                                            style =  MaterialTheme.typography.displayMedium
                                        )
                                        DockedSearchBar(
                                            query = searchText,//text showed on SearchBar
                                            onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                                            onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                                            active = true, //whether the user is searching or not
                                            onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                                            modifier = Modifier
                                                .fillMaxWidth()
                                                .padding(16.dp),
                                            placeholder = {
                                                Text(text = "Search members", fontSize = 15.sp,
                                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                    style =  MaterialTheme.typography.bodySmall
                                                )
                                            },
                                            leadingIcon = {
                                                Icon(imageVector = Icons.Default.Search, contentDescription = null)
                                            },
                                            colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                                        ) {
                                            countriesList.let {
                                                Column(
                                                    modifier = Modifier
                                                        .fillMaxWidth()
                                                        .heightIn(max=250.dp)
                                                        .verticalScroll(state = rememberScrollState())
                                                ) {
                                                    it.forEach { member ->
                                                        TextButton(
                                                            onClick = {
                                                                assignedToId.value = member?.id?:""
                                                                memberAssigned.value = member?.name?:""
                                                                assignToMember.value = false
                                                                memberAssignedAvatar.value = member?.avatar?:""
                                                            }
                                                        ) {
                                                            Row(verticalAlignment = Alignment.CenterVertically) {
                                                                if (member?.avatar.isNullOrEmpty()){
                                                                    val name = member?.name?:""
                                                                    val words = name.split(" ")
                                                                    val initials = if (words.size > 1) {
                                                                        words[0].take(1) + words[words.lastIndex].take(1)
                                                                    } else {
                                                                        words[0].take(2)
                                                                    }.uppercase()
                                                                    TextButton(onClick = {},modifier = Modifier
                                                                        .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)
                                                                    ){
                                                                        Text(
                                                                            text = initials,
                                                                            fontSize = 16.sp,
                                                                            color = Color.White,
                                                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                            maxLines = 1,
                                                                            overflow = TextOverflow.Ellipsis,
                                                                            textAlign = TextAlign.Center
                                                                        )
                                                                    }

                                                                }
                                                                else {
                                                                    Image(
                                                                        rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                                            .size(35.dp)
                                                                            .clip(CircleShape)
                                                                            .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                                                }
                                                                Spacer(modifier = Modifier.width(6.dp))

                                                                Text(if (member?.id==userId) "${member?.name} - @Me" else member?.name?:"",
                                                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                                    style =  MaterialTheme.typography.bodySmall,
                                                                    fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                                            }

                                                        }

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                                {
                                    var statusChange = remember { mutableStateOf(false) }
                                    val taskStatus = remember { mutableStateOf("To Do") }

                                    Box {
                                        TextButton(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                                            modifier = Modifier
                                                .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
                                                .width(100.dp)
                                            ,onClick = {statusChange.value=!statusChange.value}, colors = ButtonDefaults.textButtonColors(containerColor = when (taskStatus.value) {
                                                "To Do" -> Color(0xFFCFE2FF)
                                                "In Progress" -> Color(0xFFFFF3CD)
                                                "Done" -> Color(0xFFD1E7DD)
                                                else -> getRandomColorForColumns()
                                            })){
                                            Text(taskStatus.value, fontFamily = MaterialTheme.typography.bodySmall.fontFamily, fontSize = 12.sp, color = Color(0xFF03264A))
                                        }


                                        DropdownMenu(
                                            expanded = statusChange.value,
                                            onDismissRequest = { statusChange.value = false },
                                            modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                                        ){
                                            StatusChooseCreateTask(taskStatus,statusChange)
                                        }
                                    }
                                },
                                {
                                    TextButton(onClick = {
                                        datePicker.value=true
                                    }){ Text(
                                        if (!dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().date.prettyFormat().contains("1970"))dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().date.prettyFormat()
                                        else "Date"
                                    ) }
                                },
                                {
                                    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                                        val blocker = remember { mutableStateOf("") }
                                        val isBlockerDone = remember { mutableStateOf(false) }
                                        RadioButton(selected = isBlockerDone.value, onClick = {
                                            isBlockerDone.value= !isBlockerDone.value
                                        })

                                        ZiFocusTextField(value = blocker.value, onValueChange = {newValue->
                                            blocker.value = newValue
                                        }, placeHolder = "Add Blockers", withDivider = false)
                                    }
                                },)

                        )
                    }
                )


                DropdownMenuItem(
                    text = { Text("Team Retrospective",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        isTeamRetrospective.value = true

                    }
                )

                DropdownMenuItem(
                    text = { Text("Bullet list",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.setText(basicRichTextState.annotatedString.text.replace("/",""))
                        basicRichTextState.toggleUnorderedList()
                        textField.type = "bullet_list"
                    }
                )
                DropdownMenuItem(
                    text = { Text("Numbered list",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.setText(basicRichTextState.annotatedString.text.replace("/",""))
                        basicRichTextState.toggleOrderedList()
                        textField.type = "numbered_list"
                    }
                )
                DropdownMenuItem(
                    text = { Text("Divider",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        divider.value = !divider.value
                        basicRichTextState.setText(basicRichTextState.annotatedString.text.replace("/","."))

                    }
                )

                HorizontalDivider(modifier = Modifier.fillMaxWidth().padding(vertical = 6.dp))
                Text("Text styles", fontSize = 11.sp, style =  MaterialTheme.typography.bodySmall,
                    fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                    ,modifier = Modifier.padding(start = 6.dp))

                DropdownMenuItem(
                    text = { Text("Heading 1",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.toggleSpanStyle(
                            SpanStyle(
                                fontWeight = FontWeight.ExtraBold,
                                fontSize = 28.sp
                            )
                        )
                    }
                )

                DropdownMenuItem(
                    text = { Text("Heading 2",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.toggleSpanStyle(
                            SpanStyle(
                                fontWeight = FontWeight.Bold,
                                fontSize = 20.sp,
                            )
                        )
                    }
                )

                DropdownMenuItem(
                    text = { Text("Heading 3",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.toggleSpanStyle(
                            SpanStyle(
                                fontWeight = FontWeight.SemiBold,
                                fontSize = 14.sp
                            )
                        )
                    }
                )



            }

        }
      /*  if (isDate.value){
            OutlinedButton(onClick = {
                datePicker.value=true
            }){ Text(
                if (!dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().date.prettyFormat().contains("1970"))dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().date.prettyFormat()
                else "Date"
            , color = Color.Black) }
        } */

        if(callout.value){
            Callout()
        }
        if (kanbanBoard.value){
            Box(modifier = Modifier.padding(50.dp)) {
                val tasks: MutableList<TaskItem> = mutableListOf(
                    TaskItem(id = "id_1", taskStatus = "toDoId", dueDate = "2024-05-29T03:00:00.000Z",taskStatusDetails = TaskStatusDetailsResponse(id = "toDoId",name = "To Do", value = "TO_DO"), name = "Test"),
                    TaskItem(id = "id_2", taskStatus = "inProgressId", dueDate = "2024-05-29T03:00:00.000Z",taskStatusDetails = TaskStatusDetailsResponse(id = "inProgressId", name = "In Progress", value = "IN_PROGRESS"), name = "Test"),
                )
                val taskStatusDetails = remember { mutableStateListOf(TaskStatusDetailsResponse(id = "toDoId",name = "To Do", value = "TO_DO"),TaskStatusDetailsResponse(id = "inProgressId", name = "In Progress", value = "IN_PROGRESS"),TaskStatusDetailsResponse(id = "doneId",name = "Done", value = "DONE")) }

                //  KanbanBoard()
              //  viewModel.saveLoopComponentInDb(loopId = viewModel.homeState.collectAsState().value.currentLoop.data?.id?:"", saveLoopDataInDbRequest = SaveLoopDataInDbRequest(type = "kanban", boardData = tasks.toList(), columns = taskStatusDetails))
            }

        }


        if (datePicker.value){
            DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
                TextButton(onClick = {
                    datePicker.value = false
                    onClick(dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString())
                }) {
                    Text("Confirm",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall
                        , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
                }
            }, colors = DatePickerDefaults.colors(containerColor = Color(0xFFFAFAFA))){
                DatePicker(
                    state = dateSelected,
                    modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                    colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
                )
            }
        }
        if (jobTemplate.value){
            JobTemplateComponent()
        }
        if (okrTemplate.value){
            OKR()
        }
        if (isTable.value) {

            val checkedRows = remember {
                mutableStateMapOf<Int, Unit>()
            }

            // table

        Column(horizontalAlignment = Alignment.Start) {

        Row(modifier = Modifier.padding(10.dp)) {
            var expanded by remember { mutableStateOf(false) }
            var selectedSort by remember { mutableStateOf(data.firstOrNull()?.get(1)?:"") }
            var selectedOrder by remember { mutableStateOf("Ascending") }
            var sortMenuExpanded by remember { mutableStateOf(false) }
            var orderMenuExpanded by remember { mutableStateOf(false) }

        Column {
            Icon(Icons.AutoMirrored.Filled.Sort,null
                ,modifier=Modifier.clickable { expanded = true })



            // Dropdown menu with the options
            DropdownMenu(
                expanded = expanded,
                onDismissRequest = { expanded = false },
                properties = PopupProperties(focusable = true),
                modifier = Modifier.background(color =Color(0xFFFAFAFA) )
            ) {
                Row(modifier = Modifier.padding(8.dp)) {
                    // First dropdown menu for sorting options
                    Box(
                        modifier = Modifier
                            .clip(RoundedCornerShape(8.dp))
                            .background(Color.White)
                            .border(1.dp, Color.Gray, RoundedCornerShape(8.dp))
                            .clickable { sortMenuExpanded = true }
                            .padding(horizontal = 8.dp, vertical = 4.dp)
                            .width(150.dp)
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth().align(Alignment.Center),
                            horizontalArrangement = Arrangement.SpaceBetween,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Text(text = selectedSort, style = TextStyle(color = Color.Black))
                            Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                        }
                        DropdownMenu(
                            expanded = sortMenuExpanded,
                            onDismissRequest = { sortMenuExpanded = false },
                            properties = PopupProperties(focusable = true),
                            modifier = Modifier.background(color = Color(0xFFf5f5f5))

                        ) {
                            data.firstOrNull()?.filter { it != "#" }?.forEach {
                                DropdownMenuItem(onClick = {
                                    selectedSort = it
                                    sortMenuExpanded = false
                                    var isAscending = if (selectedOrder == "Ascending") true else false
                                    val header = data.firstOrNull() ?:return@DropdownMenuItem
                                    val rest = data.subList(1, data.size)
                                    val sortedDataAsc = (listOf(header) + rest.sortedWith(compareBy { it[data.firstOrNull()?.indexOf(selectedSort)?:0] }))
                                    val sortedDataDesc = (listOf(header) + rest.sortedWith(compareByDescending { it[data.firstOrNull()?.indexOf(selectedSort)?:0] })).toMutableStateList()
                                    val sortedData = if (isAscending) sortedDataAsc else sortedDataDesc
                                    data.clear()
                                    data.addAll(sortedData)
                                }, text = {
                                    Text(it)
                                }
                                )
                            }
                            // Add more sort options as needed
                        }
                    }

                    Spacer(modifier = Modifier.width(16.dp))

                    // Second dropdown menu for order options
                    Box(
                        modifier = Modifier
                            .clip(RoundedCornerShape(8.dp))
                            .background(Color.White)
                            .border(1.dp, Color.Gray, RoundedCornerShape(8.dp))
                            .clickable { orderMenuExpanded = true }
                            .padding(horizontal = 8.dp, vertical = 4.dp)
                            .width(150.dp)
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Text(text = selectedOrder, style = TextStyle(color = Color.Black))
                            Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                        }
                        DropdownMenu(
                            expanded = orderMenuExpanded,
                            onDismissRequest = { orderMenuExpanded = false },
                            properties = PopupProperties(focusable = true),
                            modifier = Modifier.background(color = Color(0xFFf5f5f5))
                        ) {
                            DropdownMenuItem(onClick = {
                                selectedOrder = "Ascending"
                                orderMenuExpanded = false
                                val header = data.firstOrNull() ?:return@DropdownMenuItem
                                val rest = data.subList(1, data.size)

                                val sortedDataAsc = (listOf(header) + rest.sortedWith(compareBy { it[data.firstOrNull()?.indexOf(selectedSort)?:0] }))
                                        data.clear()
                                        data.addAll(sortedDataAsc)


                                    },
                                    text = {
                                        Text("Ascending")
                                    })
                            DropdownMenuItem(onClick = {
                                selectedOrder = "Descending"
                                orderMenuExpanded = false
                                val header = data.firstOrNull() ?:return@DropdownMenuItem
                                val rest = data.subList(1, data.size)
                                val sortedDataDesc = (listOf(header) + rest.sortedWith(compareByDescending { it[data.firstOrNull()?.indexOf(selectedSort)?:0] })).toMutableStateList()
                                data.clear()
                                data.addAll(sortedDataDesc)

                            }, text = {
                                Text("Descending")
                            })
                            // Add more order options as needed
                        }
                    }
                }


                // Add more menu items as needed
            }
        }
            Spacer(modifier=Modifier.width(12.dp))

            Icon(Icons.Default.Filter,null
                ,modifier=Modifier.clickable {  })
            Spacer(modifier=Modifier.width(12.dp))

            Icon(Icons.Default.Expand,null
                ,modifier=Modifier.clickable {  })
            Spacer(modifier=Modifier.width(12.dp))

            Icon(Icons.Default.MoreVert,null
                ,modifier=Modifier.clickable {  })
            Spacer(modifier=Modifier.width(12.dp))
        /*
        TextButton(onClick={
            data.forEachIndexed { index, strings ->
                strings.add(if (index==0) "New Column" else "")
            }
        }){
            Text("New +")
        } */
    }
        Table(
        modifier = Modifier
                .verticalScroll(
                rememberScrollState()
                )
           // .fillMaxSize()
            .padding(10.dp).heightIn(max = 400.dp),
        columnCount = data.firstOrNull()?.size?:0,
        rowCount = data.size ,
        stickyRowCount = 0,
        stickyColumnCount = 0,
        maxCellWidthDp = 320.dp,
        customHeaders = customHeaders,
       // customHeaders = customHeaders.value,
        customElements = customElements
    ) { rowIndex, columnIndex ,customHeader , customElement->

        val header = data.firstOrNull()?.get(columnIndex)
        if (rowIndex == 0) {
                HeaderCell(mutableStateOf(header?:"") )
        } else {
            when (header) {
                "" -> {
                    ToggleCell(rowIndex - 1,checkedRows)
                }
                "#" -> {
                    ContentCell(rowIndex.toString(), checkedRows.containsKey(rowIndex - 1))
                }
               in customHeader->{
                   val customIndex = customHeader.indexOf(header)
                    Box(modifier = Modifier
                        .background(
                            color = Color.White,
                            shape = RoundedCornerShape(corner = CornerSize(0.dp))
                        )
                        .border(width = 1.dp, color = Color.Gray)

                    ) {
                            customElement[customIndex]()
                    }

                }
                else -> {



                    ContentCell( mutableStateOf(data[rowIndex][columnIndex]) , checkedRows.containsKey(rowIndex - 1), size = data.firstOrNull()?.size?:4){
                        data[rowIndex][columnIndex] = it?:""
                        createTable(data)
                        println(data.toString())
                        val loopId = viewModel.homeState.value.saveLoopDataInDbResponse.data?.id
                        if (loopId!=null){
                            viewModel.updateSaveLoopComponentInDb(loopId?:"", saveLoopDataInDbRequest = SaveLoopDataInDbRequest(type = "Table", tableData = data))

                        }

                    }
                }
            }


        }

    }
            TextButton(onClick={
                val newRow = List(data.firstOrNull()?.size?:0) { "" }.toMutableStateList()
                data.add(newRow)
            }){
                Text("New +")
            }

}

    }
        if (isTeamRetrospective.value){
            TeamRetrospective()
        }

    if (divider.value){
        HorizontalDivider(modifier = Modifier.fillMaxWidth().padding(top = 6.dp), thickness = 5.dp, color = Color.Black)
    }
}

}




@OptIn(ExperimentalComposeUiApi::class, ExperimentalMaterial3Api::class)
@Composable
fun LoopComponentLoad(modifier: Modifier = Modifier, basicRichTextState: RichTextState, textField: SaveLoopDataInDb, onClick: (String)->Unit={}, createTable: (MutableList<MutableList<String>>?)->Unit={}){
    val isDropdownMenuExpanded = remember { mutableStateOf(false) }
    val divider = remember { mutableStateOf(false) }
    val isTable = remember { mutableStateOf(textField.type=="table") }
    val isTeamRetrospective = remember { mutableStateOf(false) }
    val viewModel = koinInject<HomeViewModel>()
    var datePicker = remember { mutableStateOf(false) }
    var jobTemplate = remember { mutableStateOf(false) }
    var okrTemplate = remember { mutableStateOf(false) }
    var callout = remember { mutableStateOf(false) }
    var isDate = remember { mutableStateOf(textField.type=="date") }
    var kanbanBoard = remember { mutableStateOf(textField.type=="kanban") }
    var dateSelected =rememberDatePickerState(yearRange = 2023..2050,)
    val customHeaders = remember { mutableStateListOf<String>() }
    val customElements = remember { mutableStateListOf<@Composable () -> Unit>() }
    val data2 = remember { mutableStateListOf(
        mutableStateListOf("#","Name", "Age", "Country","Date"),
        mutableStateListOf("","", "", "",""),
    ) }
    val data  by mutableStateOf(textField.tableData)
        LaunchedEffect(Unit){
            if (textField.data !=null&&!isDate.value){
                basicRichTextState.setText(textField.data?:"")
            }

            
        }




    Column(modifier) {
        Box() {
            if (basicRichTextState.annotatedString.text==""&&textField.tableData==null&&textField.boardData==null){
                Text("Type Description", fontSize = 18.sp, style = MaterialTheme.typography.bodySmall, fontWeight = FontWeight.Light,color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
            }
            AutoSubmittingRicthTextEditor(basicRichTextState, onClick = {
                onClick(basicRichTextState.annotatedString.text)
            }, textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A), fontWeight = FontWeight.Light),
                modifier = Modifier.onKeyEvent {
                    if (it.key == Key.Slash) {
                        // Perform action when "/" is pressed
                        isDropdownMenuExpanded.value=true
                        true
                    } else {
                        false
                    }
                }.alpha(if(basicRichTextState.annotatedString.text==".") 0f else 1f)
            )


        }
        if (isDate.value){
            OutlinedButton(onClick = {
                datePicker.value=true
            }){ Text(
                formatDateString(textField.data?:"")
                , color = Color.Black) }
        }

        if(callout.value){
            Callout()
        }
        if (kanbanBoard.value){
            Box(modifier = Modifier.padding(50.dp)) {
                KanbanBoardLoad(textField)
            }

        }


        if (datePicker.value){
            DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
                TextButton(onClick = {
                    datePicker.value = false
                    textField.data = dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString()
                    onClick(dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString())
                }) {
                    Text("Confirm",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall
                        , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
                }
            }, colors = DatePickerDefaults.colors(containerColor = Color(0xFFFAFAFA))){
                DatePicker(
                    state = dateSelected,
                    modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                    colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
                )
            }
        }
        if (jobTemplate.value){
            JobTemplateComponent()
        }
        if (okrTemplate.value){
            OKR()
        }
        if (isTable.value) {

            val checkedRows = remember {
                mutableStateMapOf<Int, Unit>()
            }

            // table

            Column(horizontalAlignment = Alignment.Start) {

                Row(modifier = Modifier.padding(10.dp)) {
                    var expanded by remember { mutableStateOf(false) }
                    var selectedSort by remember { mutableStateOf(data?.firstOrNull()?.get(1)?:"") }
                    var selectedOrder by remember { mutableStateOf("Ascending") }
                    var sortMenuExpanded by remember { mutableStateOf(false) }
                    var orderMenuExpanded by remember { mutableStateOf(false) }

                    Column {
                        Icon(Icons.AutoMirrored.Filled.Sort,null
                            ,modifier=Modifier.clickable { expanded = true })



                        // Dropdown menu with the options
                        DropdownMenu(
                            expanded = expanded,
                            onDismissRequest = { expanded = false },
                            properties = PopupProperties(focusable = true),
                            modifier = Modifier.background(color =Color(0xFFFAFAFA) )
                        ) {
                            Row(modifier = Modifier.padding(8.dp)) {
                                // First dropdown menu for sorting options
                                Box(
                                    modifier = Modifier
                                        .clip(RoundedCornerShape(8.dp))
                                        .background(Color.White)
                                        .border(1.dp, Color.Gray, RoundedCornerShape(8.dp))
                                        .clickable { sortMenuExpanded = true }
                                        .padding(horizontal = 8.dp, vertical = 4.dp)
                                        .width(150.dp)
                                ) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth().align(Alignment.Center),
                                        horizontalArrangement = Arrangement.SpaceBetween,
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Text(text = selectedSort, style = TextStyle(color = Color.Black))
                                        Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                                    }
                                    DropdownMenu(
                                        expanded = sortMenuExpanded,
                                        onDismissRequest = { sortMenuExpanded = false },
                                        properties = PopupProperties(focusable = true),
                                        modifier = Modifier.background(color = Color(0xFFf5f5f5))

                                    ) {
                                        data?.firstOrNull()?.filter { it != "#" }?.forEach {
                                            DropdownMenuItem(onClick = {
                                                selectedSort = it
                                                sortMenuExpanded = false
                                                var isAscending = if (selectedOrder == "Ascending") true else false
                                                val header = data?.firstOrNull() ?:return@DropdownMenuItem
                                                val rest = data?.subList(1, data?.size?:0)
                                                val sortedDataAsc = (listOf(header) + rest?.sortedWith(compareBy { it[data?.firstOrNull()?.indexOf(selectedSort)?:0] }))
                                                val sortedDataDesc = (listOf(header) + rest?.sortedWith(compareByDescending { it[data?.firstOrNull()?.indexOf(selectedSort)?:0] })).toMutableStateList()
                                                val sortedData = if (isAscending) sortedDataAsc else sortedDataDesc
                                                data?.clear()
                                             //   data?.addAll(sortedData.toMutableStateList())
                                            }, text = {
                                                Text(it)
                                            }
                                            )
                                        }
                                        // Add more sort options as needed
                                    }
                                }

                                Spacer(modifier = Modifier.width(16.dp))

                                // Second dropdown menu for order options
                                Box(
                                    modifier = Modifier
                                        .clip(RoundedCornerShape(8.dp))
                                        .background(Color.White)
                                        .border(1.dp, Color.Gray, RoundedCornerShape(8.dp))
                                        .clickable { orderMenuExpanded = true }
                                        .padding(horizontal = 8.dp, vertical = 4.dp)
                                        .width(150.dp)
                                ) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth(),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {
                                        Text(text = selectedOrder, style = TextStyle(color = Color.Black))
                                        Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                                    }
                                    DropdownMenu(
                                        expanded = orderMenuExpanded,
                                        onDismissRequest = { orderMenuExpanded = false },
                                        properties = PopupProperties(focusable = true),
                                        modifier = Modifier.background(color = Color(0xFFf5f5f5))
                                    ) {
                                        DropdownMenuItem(onClick = {
                                            selectedOrder = "Ascending"
                                            orderMenuExpanded = false
                                            val header = data?.firstOrNull() ?:return@DropdownMenuItem
                                            val rest = data?.subList(1, data?.size?:0)

                                            val sortedDataAsc = (listOf(header) + rest?.sortedWith(compareBy { it[data?.firstOrNull()?.indexOf(selectedSort)?:0] }))
                                            data?.clear()
                                          //  data?.addAll(sortedDataAsc)


                                        },
                                            text = {
                                                Text("Ascending")
                                            })
                                        DropdownMenuItem(onClick = {
                                            selectedOrder = "Descending"
                                            orderMenuExpanded = false
                                            val header = data?.firstOrNull() ?:return@DropdownMenuItem
                                            val rest = data?.subList(1, data?.size?:0)
                                            val sortedDataDesc = (listOf(header) + rest?.sortedWith(compareByDescending { it[data?.firstOrNull()?.indexOf(selectedSort)?:0] })).toMutableStateList()
                                            data?.clear()
                                          //  data?.addAll(sortedDataDesc)

                                        }, text = {
                                            Text("Descending")
                                        })
                                        // Add more order options as needed
                                    }
                                }
                            }


                            // Add more menu items as needed
                        }
                    }
                    Spacer(modifier=Modifier.width(12.dp))

                    Icon(Icons.Default.Filter,null
                        ,modifier=Modifier.clickable {  })
                    Spacer(modifier=Modifier.width(12.dp))

                    Icon(Icons.Default.Expand,null
                        ,modifier=Modifier.clickable {  })
                    Spacer(modifier=Modifier.width(12.dp))

                    Icon(Icons.Default.MoreVert,null
                        ,modifier=Modifier.clickable {  })
                    Spacer(modifier=Modifier.width(12.dp))
                    /*
                    TextButton(onClick={
                        data.forEachIndexed { index, strings ->
                            strings.add(if (index==0) "New Column" else "")
                        }
                    }){
                        Text("New +")
                    } */
                }
                Table(
                    modifier = Modifier
                        .verticalScroll(
                            rememberScrollState()
                        )
                        // .fillMaxSize()
                        .padding(10.dp).heightIn(max = 400.dp),
                    columnCount = data?.firstOrNull()?.size?:0,
                    rowCount = data?.size?:0 ,
                    stickyRowCount = 0,
                    stickyColumnCount = 0,
                    maxCellWidthDp = 320.dp,
                    customHeaders = customHeaders,
                    // customHeaders = customHeaders.value,
                    customElements = customElements
                ) { rowIndex, columnIndex ,customHeader , customElement->

                    val header = data?.firstOrNull()?.get(columnIndex)
                    if (rowIndex == 0) {
                        HeaderCell(mutableStateOf(header?:""))
                    } else {
                        when (header) {
                            "" -> {
                                ToggleCell(rowIndex - 1,checkedRows)
                            }
                            "#" -> {
                                ContentCell(rowIndex.toString(), checkedRows.containsKey(rowIndex - 1))
                            }
                            in customHeader->{
                                val customIndex = customHeader.indexOf(header)
                                Box(modifier = Modifier
                                    .background(
                                        color = Color.White,
                                        shape = RoundedCornerShape(corner = CornerSize(0.dp))
                                    )
                                    .border(width = 1.dp, color = Color.Gray)

                                ) {
                                    customElement[customIndex]()
                                }

                            }
                            else -> {
                                ContentCell( mutableStateOf(data?.get(rowIndex)?.get(columnIndex)) , checkedRows.containsKey(rowIndex - 1), size = data?.firstOrNull()?.size?:4){
                                    data?.get(rowIndex)?.set(columnIndex, it?:"")
                                      createTable(data)
                                    println(data.toString())
                                    /*
                                    val loopId = viewModel.homeState.value.currentLoop.data?.id
                                    if (loopId!=null){
                                        viewModel.updateSaveLoopComponentInDb(loopId?:"", saveLoopDataInDbRequest = SaveLoopDataInDbRequest(type = "table", tableData = data))

                                    } */

                                }
                            }
                        }


                    }

                }
                TextButton(onClick={
                    val newRow = List(data?.firstOrNull()?.size?:0) { "" }.toMutableStateList()
                    data?.add(newRow)
                }){
                    Text("New +")
                }

            }

        }
        if (isTeamRetrospective.value){
            TeamRetrospective()
        }

        if (divider.value){
            HorizontalDivider(modifier = Modifier.fillMaxWidth().padding(top = 6.dp), thickness = 5.dp, color = Color.Black)
        }
    }

}