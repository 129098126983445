package org.company.app.presentation.component

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.Font
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.unit.dp
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import org.company.app.presentation.component.table.ContentCell
import org.company.app.presentation.component.table.HeaderCell
import org.company.app.presentation.component.table.JobContentCell
import org.company.app.presentation.component.table.Table
import org.company.app.presentation.component.table.ToggleCell
import org.company.app.presentation.ui.carddetails.AutoSubmittingRicthTextEditor
import org.jetbrains.compose.resources.InternalResourceApi

@OptIn(InternalResourceApi::class, ExperimentalComposeUiApi::class)
@Composable
fun JobTemplateComponent(){
    Column(modifier = Modifier.padding(16.dp)) {

        val html ="<!DOCTYPE html>\n" +
                "<html lang=\"en\">\n" +
                "<head>\n" +
                "    <meta charset=\"UTF-8\">\n" +
                "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n" +
                "    <title>Interview Template</title>\n" +
                "</head>\n" +
                "<body>\n" +
                "<p>This template is brought to you by Indeed, a leading job search engine.</p>\n" +
                "\n" +
                "<h2 class=\"section-title\">\uD83D\uDDC2 Candidate background</p>\n" +
                "<p>Include any context you've received on the candidate or the role, as well as any instructions the recruiter or hiring manager has given you.</p>\n" +
                "\n" +
                "<h2 class=\"section-title\">✏ Interview notes</p>\n" +
                "<p>Add your questions below ahead of time, then take notes on the candidate's responses during your interview with them.</p>\n" +
                "\n" +
                "<h2 class=\"section-title\">✅ Feedback</p>\n" +
                "<p>For each skill or ability, give the candidate a score from 1 (poor) to 5 (excellent). Be conscious of the potential for bias, and support your score with 1-2 specific examples from your interview.</p>\n" +
                "\n" +
                "<p class=\"section-title\">❓ Open questions</p>\n" +
                "<p>List any questions or concerns you have about the candidate based on your session, so that other interviewers can follow up on them.</p>\n" +
                "\n" +
                "</body>\n" +
                "</html>\n"




        val contentHtml = mutableStateOf("<!DOCTYPE html>\n" +
                "<html lang=\"en\">\n" +
                "<head>\n" +
                "    <meta charset=\"UTF-8\">\n" +
                "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n" +
                "    <title>Role Overview</title>\n" +
                "</head>\n" +
                "<body>\n" +
                "\n" +
                "<h2>\uD83D\uDCCB Role overview</h2>\n" +
                "<p>Give a brief description of the role.</p>\n" +
                "\n" +
                "<h2>\uD83D\uDC4C Role profile</h2>\n" +
                "<p>Include the key attributes required for the candidate. Confirm which attributes are required and which attributes are more 'nice to have' or teachable on the job.</p>\n" +
                "\n" +
                "<h2>\uD83C\uDFA2 Assessment process</h2>\n" +
                "<p>Outline what the assessment process will look like for this particular role, and @mention panel members for interviews. You can also include suggested screening questions.</p>\n" +
                "\n" +
                "</body>\n" +
                "</html>\n")
        val richTextState = rememberRichTextState()
        LaunchedEffect(Unit){
          //  richTextState.setHtml(contentHtml.value)
            richTextState.setHtml(html)
        }
        val data = remember { mutableStateListOf(
            mutableStateListOf("Candidate",""),
            mutableStateListOf("Interviewer","",),
            mutableStateListOf("Recruiter","",),
            mutableStateListOf("Job title","",),
            mutableStateListOf("Job level","",),
            mutableStateListOf("Recommendation","",),

            ) }
        Table(
            modifier = Modifier
                .padding(10.dp).fillMaxWidth(),
            columnCount = data.firstOrNull()?.size?:0,
            rowCount = data.size ,
            stickyRowCount = 0,
            stickyColumnCount = 0,

            // customHeaders = customHeaders.value,
        ) { rowIndex, columnIndex ,customHeader , customElement->

            val header = data.firstOrNull()?.get(columnIndex)
            val r = data[rowIndex]
            JobContentCell(remember {  mutableStateOf(r[columnIndex]) }, false, size = data.firstOrNull()?.size?:5){
                data[rowIndex][columnIndex] = it?:""
            }

        }
        AutoSubmittingRicthTextEditor(richTextState, onClick = {})

}
}