/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package api

import org.openapitools.client.models.ErrorData
import org.openapitools.client.models.LoginRequest
import org.openapitools.client.models.MessageResponse
import org.openapitools.client.models.SendOTPForEmailRequest
import org.openapitools.client.models.SendOTPForEmailResponse
import org.openapitools.client.models.UserRegistrationRequest
import org.openapitools.client.models.UserRegistrationResponse
import org.openapitools.client.models.UserUpdateRequest
import org.openapitools.client.models.UserUpdateResponse
import org.openapitools.client.models.VerifyOTPRequest
import org.openapitools.client.models.VerifyOTPResponse

import org.openapitools.client.infrastructure.*
import io.ktor.client.HttpClient
import io.ktor.client.HttpClientConfig
import io.ktor.client.request.forms.formData
import io.ktor.client.engine.HttpClientEngine
import kotlinx.serialization.json.Json
import io.ktor.http.ParametersBuilder
import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

open class RegistrationApi : ApiClient {

    constructor(
        baseUrl: String = ApiClient.BASE_URL,
        httpClientEngine: HttpClientEngine? = null,
        httpClientConfig: ((HttpClientConfig<*>) -> Unit)? = null,
        jsonSerializer: Json = ApiClient.JSON_DEFAULT
    ) : super(baseUrl = baseUrl, httpClientEngine = httpClientEngine, httpClientConfig = httpClientConfig, jsonBlock = jsonSerializer)

    constructor(
        baseUrl: String,
        httpClient: HttpClient
    ): super(baseUrl = baseUrl, httpClient = httpClient)

    /**
     * Delete a registered user
     * 
     * @param authorization Bearer token for authentication (optional)
     * @return MessageResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun deleteUser(authorization: kotlin.String? = null): HttpResponse<MessageResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.DELETE,
            "/users/deleteUser",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * Get information about a registered user
     * 
     * @param authorization Bearer token for authentication (optional)
     * @return UserRegistrationResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun getUsers(authorization: kotlin.String? = null): HttpResponse<UserRegistrationResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = 
            io.ktor.client.utils.EmptyContent

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.GET,
            "/users/getUser",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return request(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }


    /**
     * User login with Email ID and Password
     * 
     * @param loginRequest 
     * @param authorization Access token for authorization (optional)
     * @return UserRegistrationResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun login(loginRequest: LoginRequest, authorization: kotlin.String? = null): HttpResponse<UserRegistrationResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = loginRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/users/login",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * send OTP For Email
     * 
     * @param sendOTPForEmailRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return SendOTPForEmailResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun sendOTPForEmail(sendOTPForEmailRequest: SendOTPForEmailRequest, authorization: kotlin.String? = null): HttpResponse<SendOTPForEmailResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = sendOTPForEmailRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/users/sendOTPForEmail",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * User Registration
     * 
     * @param userRegistrationRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return UserRegistrationResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun signupUser(userRegistrationRequest: UserRegistrationRequest, authorization: kotlin.String? = null): HttpResponse<UserRegistrationResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = userRegistrationRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/users/signup",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * Update registered user information
     * Update user details based on specified parameters.
     * @param userUpdateRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return UserUpdateResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun updateUser(userUpdateRequest: UserUpdateRequest, authorization: kotlin.String? = null): HttpResponse<UserUpdateResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = userUpdateRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.PUT,
            "/users/updateUser",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



    /**
     * verify OTP For Email
     * 
     * @param verifyOTPRequest 
     * @param authorization Bearer token for authentication (optional)
     * @return VerifyOTPResponse
     */
    @Suppress("UNCHECKED_CAST")
    open suspend fun verifyOTP(verifyOTPRequest: VerifyOTPRequest, authorization: kotlin.String? = null): HttpResponse<VerifyOTPResponse> {

        val localVariableAuthNames = listOf<String>()

        val localVariableBody = verifyOTPRequest

        val localVariableQuery = mutableMapOf<String, List<String>>()
        val localVariableHeaders = mutableMapOf<String, String>()
        authorization?.apply { localVariableHeaders["Authorization"] = this.toString() }

        val localVariableConfig = RequestConfig<kotlin.Any?>(
            RequestMethod.POST,
            "/users/verifyOTP",
            query = localVariableQuery,
            headers = localVariableHeaders,
            requiresAuthentication = false,
        )

        return jsonRequest(
            localVariableConfig,
            localVariableBody,
            localVariableAuthNames
        ).wrap()
    }



}
