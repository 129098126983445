package org.company.app.presentation.component

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicText
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Expand
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material.icons.filled.ShoppingBag
import androidx.compose.material.icons.outlined.ShoppingBag
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.openapitools.client.models.TaskItem

@Composable
fun TeamRetrospectiveCard(task: MutableState<TaskItem>, onClick: () -> Unit, modifier: Modifier=Modifier) {
    val interactionSourceBook = remember { MutableInteractionSource() }
    val isHovered = interactionSourceBook.collectIsHoveredAsState().value
    val taskTitle = mutableStateOf(task.value.name?:"")

    Surface(
        shape = RoundedCornerShape(8.dp),
        shadowElevation = 4.dp,
        color = Color(0xFFFAFAFA),
        modifier = Modifier
            .padding(8.dp)
            .fillMaxWidth()
            .hoverable(interactionSourceBook)


    ) {
        Column {
            Row(
                modifier = Modifier
                    .padding(8.dp)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {

                ZiFocusTextField(text = taskTitle, placeHolder = "Type Here",
                    textStyle = MaterialTheme.typography.displayMedium.copy(color = Color(0xFF03264A), fontSize = 17.sp),
                    modifier = Modifier.padding(start = 10.dp,end=if (task.value.taskStatus=="IN_PROGRESS")65.dp else 30.dp),
                    withDivider = false
                )

                IconButton(modifier = Modifier.alpha(if (isHovered) 1f else 0f),onClick = { /* Handle click */ }) {
                    Icon(imageVector = Icons.Default.Expand, modifier = Modifier.rotate(90f),contentDescription = "More options")
                }

                IconButton(modifier = Modifier.alpha(if (isHovered) 1f else 0f),onClick = { /* Handle click */ }) {
                    Icon(imageVector = Icons.Default.MoreVert, modifier = Modifier.rotate(90f),contentDescription = "More options")
                }

            }
            Row(
                modifier = Modifier
                    .padding(8.dp)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Spacer(modifier=Modifier.width(10.dp))
                Icon(
                    imageVector = Icons.Outlined.ShoppingBag,
                    contentDescription = "Task Icon",
                    modifier = Modifier.size(20.dp)
                )
                Spacer(modifier = Modifier.width(4.dp))
               Text(
                    text = "+0",
                    style = MaterialTheme.typography.bodyLarge,
                    color = Color.Gray,
                   modifier = Modifier.border(border = BorderStroke(0.5.dp, color = Color.Gray), shape = RoundedCornerShape(10.dp)).padding(horizontal = 5.dp, vertical = 2.dp)
                )
            }
        }


    }
}


