package org.company.app.presentation.component

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.RadioButton
import androidx.compose.material3.RadioButtonDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource

@Composable
fun CheckItem(
    text: String, // the text to display next to the radio button
    selected: Boolean, // the state of the radio button
    onSelect: (Boolean) -> Unit, // the callback to invoke when the radio button is selected
    modifier: Modifier = Modifier, // the modifier to apply to the check item
    imageUnChecked: ImageBitmap?, // the image to display when the radio button is not selected
    imageChecked: ImageBitmap?, // the image to display when the radio button is selected
    useNavRail: Boolean
) {
    if (useNavRail){

        Column( // a row to arrange the radio button and the text horizontally
            modifier = modifier.border(border = BorderStroke(0.5.dp, Color.Gray), shape = RoundedCornerShape(10.dp)).background(
                Color(0xFFFAFAFA), shape = RoundedCornerShape(10.dp)
            ).padding(12.dp), // apply the modifier to the row
            horizontalAlignment = Alignment.CenterHorizontally // align the row items vertically
        ) {
            RadioButton( // the radio button composable
                selected = selected, // pass the state of the radio button
                onClick = { onSelect(true) }, // pass the callback function
                colors = RadioButtonDefaults.colors( // customize the colors of the radio button
                    selectedColor = MaterialTheme.colorScheme.primaryContainer, // the color of the radio button when selected
                    unselectedColor = Color.Black // the color of the radio button when unselected
                )
            )
            if (selected) { // if the radio button is selected, show the image
                if (imageChecked != null) {
                    Image(imageChecked ,contentDescription = null)
                }
            } else {
                if (imageUnChecked != null) {
                    Image(imageUnChecked ,contentDescription = null)
                }
            }
            Spacer(modifier = Modifier.width(8.dp)) // a spacer to add some horizontal space
            Text( // the text composable
                text = text, // pass the text to display
                fontSize = 17.sp,
                fontWeight = FontWeight(600),
                style =  MaterialTheme.typography.displayMedium,
                textAlign = TextAlign.Center
                //  modifier = Modifier.weight(1f) // make the text fill the remaining space
            )

        }
    }
    else {
        Row( // a row to arrange the radio button and the text horizontally
            modifier = modifier.border(border = BorderStroke(0.5.dp, Color.Gray), shape = RoundedCornerShape(10.dp)).background(
                Color.White, shape = RoundedCornerShape(10.dp)
            ).padding(12.dp), // apply the modifier to the row
            verticalAlignment = Alignment.CenterVertically // align the row items vertically
        ) {

            if (selected) { // if the radio button is selected, show the image
                if (imageChecked != null) {
                    Image(imageChecked ,contentDescription = null,modifier= Modifier.fillMaxWidth(0.3f))
                }
            } else {
                if (imageUnChecked != null) {
                    Image(imageUnChecked ,contentDescription = null,modifier= Modifier.fillMaxWidth(0.3f))
                }
            }
            Spacer(modifier = Modifier.width(8.dp)) // a spacer to add some horizontal space
            Text( // the text composable
                text = text, // pass the text to display
                modifier = Modifier.weight(1f), // make the text fill the remaining space
                style = MaterialTheme.typography.displayMedium
            )

            RadioButton( // the radio button composable
                selected = selected, // pass the state of the radio button
                onClick = { onSelect(true) }, // pass the callback function
                colors = RadioButtonDefaults.colors( // customize the colors of the radio button
                    selectedColor = MaterialTheme.colorScheme.primaryContainer, // the color of the radio button when selected
                    unselectedColor = Color.Black // the color of the radio button when unselected
                )
            )
        }
    }

}