package org.company.app.util

object Constants {
    const val BASE_URL = "https://z3j0z3ofie.execute-api.ap-south-1.amazonaws.com/develop"
    const val UPLOAD_URL = "https://3jiefcr0xf.execute-api.ap-south-1.amazonaws.com"
    const val env = "develop"
    const val WEBSITE_URL = "https://dev.zifoco.zimozi.xyz"
    const val PARAM_AUTH = "Authorization"
    var token =  ""
    const val FirebaseKey = "AIzaSyAJtMviEglTcNmjrYbiadsqHh8xIA8T1GQ"
    const val REPO_OWNER = "ZiFOCO"

    const val REPO_NAME = "ZiPages"

    const val GITHUB_BASE_URL = "https://api.github.com/repos/$REPO_OWNER/$REPO_NAME/contents"
}

