package org.company.app.presentation.component

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.TextFieldDefaults
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.TextField
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.eye
import org.jetbrains.compose.resources.painterResource

@Composable
fun Callout() {
    var text by remember { mutableStateOf("") }

    TextField(
        value = text,
        textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily),
        onValueChange = { text = it },
        placeholder = { Text("Just start typing...", style = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp, fontFamily = MaterialTheme.typography.bodySmall.fontFamily)) },
        leadingIcon = {
            Icon(
                painter = painterResource(Res.drawable.eye) , // Replace with your pin icon resource
                contentDescription = "Pin Icon",
                tint = Color.Red
            )
        },

        colors = TextFieldDefaults.textFieldColors(
            backgroundColor = Color(0xFFF5F5F5),
            focusedIndicatorColor = Color.Transparent,
            unfocusedIndicatorColor = Color.Transparent
        ),
        shape = RoundedCornerShape(8.dp), // Adjust corner radius as needed
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp) // Adjust padding as needed
            .border(
            BorderStroke(1.dp, Color.Gray), // Adjust border color and thickness as needed
            shape = RoundedCornerShape(8.dp) // Match the corner radius of the text field
        )
    )
}

