/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models


import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param type 
 * @param `data` 
 * @param tableData 
 * @param boardData 
 * @param columns 
 */
@Serializable

data class SaveLoopDataInDbRequest (

    @SerialName(value = "type") val type: kotlin.String? = null,

    @SerialName(value = "data") val `data`: kotlin.String? = null,

    @SerialName(value = "tableData") val tableData: kotlin.collections.List<kotlin.collections.List<kotlin.String>>? = null,

    @SerialName(value = "boardData") val boardData: MutableList<TaskItem>? = null,

    @SerialName(value = "columns") val columns: kotlin.collections.List<TaskStatusDetailsResponse>? = null

)

