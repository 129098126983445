package org.company.app.presentation.component

import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.BaselineShift
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import com.mohamedrejeb.richeditor.model.RichTextState

@Composable
fun EditorStyleRow(
    modifier: Modifier,
    richTextState: RichTextState,
    openLinkDialog: MutableState<Boolean>
) {
    LazyRow(modifier = modifier.padding(8.dp),
        verticalAlignment = Alignment.CenterVertically) {
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.addParagraphStyle(ParagraphStyle(textAlign = TextAlign.Left))
                },
                icon = Icons.Outlined.FormatAlignLeft,
                isSelected = richTextState.currentParagraphStyle.textAlign == TextAlign.Left
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.addParagraphStyle(ParagraphStyle(textAlign = TextAlign.Center))
                },
                icon = Icons.Outlined.FormatAlignCenter,
                isSelected = richTextState.currentParagraphStyle.textAlign == TextAlign.Center
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.addParagraphStyle(ParagraphStyle(textAlign = TextAlign.Right))
                },
                icon = Icons.Outlined.FormatAlignRight,
                isSelected = richTextState.currentParagraphStyle.textAlign == TextAlign.Right
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleSpanStyle(SpanStyle(fontWeight = FontWeight.Bold))
                },
                icon = Icons.Outlined.FormatBold,
                isSelected = richTextState.currentSpanStyle.fontWeight == FontWeight.Bold
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleSpanStyle(SpanStyle(fontStyle = FontStyle.Italic))
                },
                icon = Icons.Outlined.FormatItalic,
                isSelected = richTextState.currentSpanStyle.fontStyle == FontStyle.Italic
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleSpanStyle(SpanStyle(textDecoration = TextDecoration.Underline))
                },
                icon = Icons.Outlined.FormatUnderlined,
                isSelected = richTextState.currentSpanStyle.textDecoration == TextDecoration.Underline
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleSpanStyle(SpanStyle(textDecoration = TextDecoration.LineThrough))
                },
                icon = Icons.Outlined.FormatStrikethrough,
                isSelected = richTextState.currentSpanStyle.textDecoration == TextDecoration.LineThrough
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleSpanStyle(SpanStyle(baselineShift = BaselineShift.Subscript))
                },
                icon = Icons.Outlined.Subscript,
                isSelected = richTextState.currentSpanStyle.baselineShift == BaselineShift.Subscript
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleSpanStyle(SpanStyle(baselineShift = BaselineShift.Superscript))
                },
                icon = Icons.Outlined.Superscript,
                isSelected = richTextState.currentSpanStyle.baselineShift == BaselineShift.Superscript
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleSpanStyle(SpanStyle(baselineShift = BaselineShift.Superscript))
                },
                icon = Icons.Outlined.Superscript,
                isSelected = richTextState.currentSpanStyle.baselineShift == BaselineShift.Superscript
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    openLinkDialog.value = true
                },
                icon = Icons.Outlined.Link,
                isSelected = richTextState.isLink
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleUnorderedList()
                },
                icon = Icons.Outlined.FormatListBulleted,
                isSelected = richTextState.isUnorderedList
            )
        }
        item { Spacer(Modifier.width(4.dp)) }
        item {
            EditorStyleButton(
                onClick = {
                    richTextState.toggleOrderedList()
                },
                icon = Icons.Outlined.FormatListNumbered,
                isSelected = richTextState.isOrderedList
            )
        }
    }
}