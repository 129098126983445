package org.company.app.data.model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DocsFileRequest(
    @SerialName("content")
    var content: String? = null,
    @SerialName("message")
    var message: String,
    // Required if updating
    @SerialName("sha")
    var sha: String? = null
)