package org.company.app.presentation.component

import androidx.compose.foundation.background
import androidx.compose.foundation.hoverable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.focusProperties
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp

@Composable
fun EditorStyleButton(
    onClick: () -> Unit,
    icon: ImageVector,
    tint: Color? = null,
    isSelected: Boolean = false
) {
    val interactionSource = remember { MutableInteractionSource() }
    IconButton(
        modifier = Modifier.background(
            shape = RoundedCornerShape(4.dp),
            color = if (isSelected) MaterialTheme.colorScheme.primary else Color.Transparent
        )
        // Workaround to prevent the rich editor
        // from losing focus when clicking on the button
        // (Happens only on Desktop)
            .focusProperties { canFocus = false },
        onClick = onClick,
        colors = IconButtonDefaults.iconButtonColors(
            contentColor = if(isSelected) MaterialTheme.colorScheme.onPrimary else MaterialTheme.colorScheme.onBackground
        )
    ) {
        Icon(
            modifier = Modifier.hoverable(interactionSource, false),
            imageVector = icon,
            contentDescription = icon.name,
            tint = tint ?: LocalContentColor.current,
        )
    }
}