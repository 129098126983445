package org.company.app.presentation.ui

import androidx.compose.animation.*
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.*
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.interaction.*
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowForwardIos
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material.icons.filled.SpaceDashboard
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusProperties
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.input.key.*
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.substring
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.times
import androidx.compose.ui.window.Dialog
import cafe.adriel.voyager.core.screen.Screen
import com.mohamedrejeb.ksoup.html.parser.KsoupHtmlHandler
import com.mohamedrejeb.ksoup.html.parser.KsoupHtmlParser
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import com.mohamedrejeb.richeditor.ui.material3.OutlinedRichTextEditor
import com.mohamedrejeb.richeditor.ui.material3.RichText
import io.github.aakira.napier.Napier
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.company.app.data.model.DocsContentResponse
import org.company.app.openUrl
import org.company.app.presentation.component.EditorStyleRow
import org.company.app.presentation.viewmodel.DocsViewModel
import org.company.app.presentation.viewmodel.HomeViewModel
import org.koin.compose.koinInject
import org.koin.core.parameter.parametersOf
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

object DocsScreen : Screen {
    @Composable
    override fun Content() {
        DocsScreenContent()
    }


}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun DocsScreenContent() {
    val viewModel = koinInject<HomeViewModel>()
    val currentUser = viewModel.homeState.value.user
   // val currentUser = LocalActiveUser.current
    val docsViewModel =
        koinInject<DocsViewModel> { parametersOf(currentUser?.userPageId.toString()) }
    var docsContentListResponse: List<DocsContentResponse> by remember { mutableStateOf(emptyList()) }


    var openDrawer by remember {
        mutableStateOf(true)
    }

    val windowSizeClass = calculateWindowSizeClass()

    val isWideScreen by remember {
        mutableStateOf(windowSizeClass.widthSizeClass == WindowWidthSizeClass.Expanded)
    }

    var openNameDialog by remember {
        mutableStateOf(false)
    }

    var openLinkDialog = remember {
        mutableStateOf(false)
    }

    var childTitle by remember {
        mutableStateOf(TextFieldValue(""))
    }

    var addElementType by remember {
        mutableStateOf("")
    }

    var currentParent: DocsContentResponse? by remember {
        mutableStateOf(null)
    }

    val currentFile: DocsContentResponse? by docsViewModel.currentFileState.collectAsState()

    val isLoading by docsViewModel.loadingState.collectAsState()

    val markdown by docsViewModel.fileContentState.collectAsState()

//    markdown.useDebounce { textFieldValue ->
//        if (currentFile != null && textFieldValue.text != currentFile?.content) {
//            docsViewModel.addOrUpdateFile(currentFile!!.copy(content = textFieldValue.text))
//        }
//    }

    val focusRequester = remember { FocusRequester() }
    val focusManager = LocalFocusManager.current
    val scope = rememberCoroutineScope()
    val richTextState = rememberRichTextState()
    val richEditorMutableInteractionResource = remember { MutableInteractionSource() }
    val interaction by richEditorMutableInteractionResource.interactions.collectAsState(
        PressInteraction.Cancel(PressInteraction.Press(Offset.Zero))
    )

    richTextState.annotatedString.useDebounce {
        Napier.d("rich text -> MD: ${richTextState.toMarkdown()}, Html: ${richTextState.toHtml()}")
        if (currentFile != null && richTextState.toHtml() != currentFile?.content) {
            docsViewModel.addOrUpdateFile(currentFile!!.copy(content = richTextState.toHtml()))
        }
    }

    LaunchedEffect(markdown.text) {
        Napier.d("got text -> ${markdown.text}")
        richTextState.setHtml(markdown.text)
    }

    LaunchedEffect(docsViewModel) {
        Napier.d("Got cleared data $docsContentListResponse")
//        docsViewModel.clearCurrentFile()
        docsViewModel.getDocsContentForProject()
        docsViewModel.docsContentState.collect {
            docsContentListResponse = it
        }
    }

    DisposableEffect(docsViewModel) {
        onDispose {

            docsViewModel.clearDocsList()
            docsViewModel.clearCurrentFile()


        }
    }


    var text by remember { mutableStateOf("") }
    var link by remember { mutableStateOf("") }



    Box(modifier = Modifier.fillMaxSize().padding(16.dp)) {
        Row(
            modifier = Modifier.fillMaxHeight()
        ) {
            Column(
                modifier = Modifier.fillMaxHeight()
                    .border(
                        width = 0.5.dp,
                        color = MaterialTheme.colorScheme.outline
                    ),
            ) {
                FilledTonalIconToggleButton(
                    checked = openDrawer,
                    onCheckedChange = {
                        openDrawer = !openDrawer
                    },
                    shape = RectangleShape
                ) {
                    Icon(
                        imageVector = Icons.Filled.SpaceDashboard,
                        contentDescription = "Open drawer"
                    )
                }
            }
            if (openDrawer) {
                Column(
                    modifier = Modifier.fillMaxHeight().width(300.dp)
                        .border(width = 0.5.dp, color = MaterialTheme.colorScheme.outline)
                ) {
                    Column(modifier = Modifier.weight(1f).verticalScroll(rememberScrollState())) {
                        docsContentListResponse.forEach { folder ->
                            FolderItem(
                                folder, currentFile,
                                onExpanded = { folderPath ->
                                    docsViewModel.getContentForFolder(folderPath)
                                },
                                onAddChild = { element, type ->
                                    currentParent = element
                                    addElementType = type
                                    openNameDialog = true
                                },
                                onSelectFile = {
                                    docsViewModel.getContentForFile(it)
                                    if (windowSizeClass.widthSizeClass < WindowWidthSizeClass.Medium) openDrawer = false
                                },
                                onRename = { element, title ->
                                    //                                    folders.searchById(element.id)?.title = title
                                },
                                onDelete = { delete ->
                                    if (delete.type == "file") {
                                        docsViewModel.deleteFile(delete)
                                    } else {
                                        docsViewModel.deleteFolder(delete)
                                    }

                                })
                        }
                    }
                    TextButton(
                        onClick = {
                            addElementType = "dir"
                            openNameDialog = true
                            currentParent = null
                        },
                        shape = RectangleShape,
                        modifier = Modifier.fillMaxWidth().focusProperties { canFocus = false }
                            .border(width = 0.5.dp, color = MaterialTheme.colorScheme.outline)
                    ) {
                        Text("Add folder")
                    }
                    TextButton(
                        onClick = {
                            addElementType = "file"
                            openNameDialog = true
                            currentParent = null
                        },
                        shape = RectangleShape,
                        modifier = Modifier.fillMaxWidth().focusProperties { canFocus = false }
                            .border(width = 0.5.dp, color = MaterialTheme.colorScheme.outline)
                    ) {
                        Text("Add file")
                    }
                }

            }
            if ((windowSizeClass.widthSizeClass < WindowWidthSizeClass.Medium && !openDrawer) || (windowSizeClass.widthSizeClass >= WindowWidthSizeClass.Medium)) {
                Spacer(modifier = Modifier.width(8.dp))
                Column(modifier = Modifier.fillMaxWidth()) {
                    if (currentFile != null) {
                        EditorStyleRow(
                            modifier = Modifier.fillMaxWidth()
                                .border(width = 0.5.dp, color = MaterialTheme.colorScheme.outline),
                            richTextState = richTextState,
                            openLinkDialog = openLinkDialog
                        )
                        Spacer(modifier = Modifier.height(8.dp))
                        OutlinedRichTextEditor(
                            modifier = Modifier.fillMaxSize().focusRequester(focusRequester)
                                .pointerInput(Unit) {
                                    detectTapGestures(onTap = {
                                        Napier.d("Detected Tap")
                                    })
                                }
                                .onPreviewKeyEvent { keyEvent ->
                                    if ((keyEvent.isCtrlPressed && listOf(Key.ShiftLeft, Key.ShiftRight).contains(
                                            keyEvent.key
                                        )) || (keyEvent.isMetaPressed && listOf(Key.ShiftLeft, Key.ShiftRight).contains(
                                            keyEvent.key
                                        ))
                                    ) {
                                        try {
                                            Napier.d("Selection: ${richTextState.selection}")
                                            val searchText1 = richTextState.annotatedString.text.substring(
                                                richTextState.selection.start.minus(4).takeIf { it != -1 } ?: 0,
                                                richTextState.selection.start)
                                            val searchText2 = richTextState.annotatedString.text.substring(
                                                richTextState.selection.end,
                                                richTextState.selection.end.plus(4)
                                                    .takeIf { it <= richTextState.annotatedString.text.length.minus(1) }
                                                    ?: richTextState.annotatedString.text.length.minus(1))
                                            Napier.d("Search Texts: $searchText1, $searchText2")
                                            val link = richTextState.toHtml().findLink(
                                                searchText1
                                            ) ?: richTextState.toHtml().findLink(
                                                searchText2
                                            )

                                            openUrl(link)

                                            Napier.d("Link: $link")
                                        } catch (e: Exception) {
                                            Napier.e(e.message.toString(), e)
                                        }
                                        /*val start = richTextState.annotatedString.text.substring(0, richTextState.selection.start).lastIndexOf(" ")
                                        val end = richTextState.annotatedString.text.substring(richTextState.selection.end).indexOf(" ")

                                        val wordStart = if (start == -1) 0 else start + 1
                                        val wordEnd =
                                            if (end == -1) richTextState.annotatedString.text.length else richTextState.selection.end + end

                                        val currentWord = richTextState.annotatedString.text.substring(wordStart, wordEnd)
                                        val regex = "\\[$currentWord\\]\\(([^)]+)\\)".toRegex()
                                        val url = regex.find(richTextState.toMarkdown())?.groups?.get(1)?.value.toString()
                                        openUrl(url)*/
                                        true
                                    } else false
                                },
                            state = richTextState,
                            interactionSource = richEditorMutableInteractionResource
                        )
                    } else {
                        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
                            Text("No File is selected")
                        }
                    }
                }
            }
        }
        if (openNameDialog) {
            Dialog(onDismissRequest = {
                openNameDialog = false
                childTitle = TextFieldValue("")
                currentParent = null
                addElementType = ""
            }) {
                Card {
                    Column(
                        modifier = Modifier.padding(16.dp, 12.dp),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text(
                            modifier = Modifier.align(Alignment.CenterHorizontally),
                            text = "Add ${if (addElementType == "file") "File" else "Folder"}",
                            fontSize = 18.sp
                        )
                        Spacer(modifier = Modifier.height(12.dp))
                        OutlinedTextField(
                            modifier = Modifier.focusRequester(focusRequester),
                            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
                            label = {
                                Text("Title")
                            },
                            value = childTitle,
                            onValueChange = {
                                childTitle = it
                            }, singleLine = true
                        )
                        Spacer(modifier = Modifier.height(8.dp))
                        Button(onClick = {
                            openNameDialog = false
                            if (addElementType == "file") {
                                docsViewModel.addOrUpdateFile(
                                    DocsContentResponse(
                                        name = childTitle.text,
                                        path = "${currentParent?.path ?: currentUser?.userPageId}/${childTitle.text}",
                                        type = addElementType,
                                        parent = currentParent?.path
                                    ), isUpdate = false
                                )
                            } else {
                                docsViewModel.addSubFolder(
                                    DocsContentResponse(
                                        name = childTitle.text,
                                        path = "${currentParent?.path ?: currentUser?.userPageId}/${childTitle.text}",
                                        type = addElementType,
                                        parent = currentParent?.path
                                    ), currentParent
                                )
                            }
                            childTitle = TextFieldValue("")
                            currentParent = null
                            addElementType = ""

                        }) {
                            Text("Submit")
                        }
                    }
                }
            }
        }
        if (openLinkDialog.value) {
            Dialog(
                onDismissRequest = {
                    openLinkDialog.value = false
                    text = ""
                    link = ""
                }
            ) {
                Card() {
                    Column(
                        modifier = Modifier.padding(16.dp, 12.dp),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text(
                            modifier = Modifier.align(Alignment.CenterHorizontally),
                            text = "Add Link",
                            fontSize = 18.sp
                        )
                        Spacer(modifier = Modifier.height(12.dp))
                        OutlinedTextField(
                            modifier = Modifier.focusRequester(focusRequester),
                            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
                            label = {
                                Text("Text")
                            },
                            value = text,
                            onValueChange = {
                                text = it
                            }, singleLine = true
                        )
                        Spacer(modifier = Modifier.height(8.dp))
                        OutlinedTextField(
                            modifier = Modifier.focusRequester(focusRequester),
                            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
                            label = {
                                Text("Link")
                            },
                            value = link,
                            onValueChange = {
                                link = it
                            }, singleLine = true
                        )
                        Spacer(modifier = Modifier.height(12.dp))
                        Button(onClick = {
                            richTextState.addLink(text, link)
                            openLinkDialog.value = false
                            text = ""
                            link = ""
                        }) {
                            Text("Submit")
                        }
                    }

                }
            }
        }
    }

    if (isLoading) {
        Box(modifier = Modifier.fillMaxSize().background(Color.Gray.copy(alpha = 0.4f)), contentAlignment = Alignment.Center) {
            Column(horizontalAlignment = Alignment.CenterHorizontally) {
                CircularProgressIndicator()
                Spacer(modifier = Modifier.height(16.dp))
                Text("Loading Please Wait...")
            }
        }
    }
}




@Composable
fun FolderItem(
    item: DocsContentResponse,
    currentFile: DocsContentResponse?,
    onExpanded: (String) -> Unit,
    onAddChild: (DocsContentResponse, String) -> Unit,
    onSelectFile: (DocsContentResponse) -> Unit,
    onRename: (DocsContentResponse, String) -> Unit,
    onDelete: (DocsContentResponse) -> Unit,
    depth: Int = 0
) {
    var openElementDropdown by remember {
        mutableStateOf(false)
    }

    val focusRequester = remember { FocusRequester() }
    val scope = rememberCoroutineScope()
    var expanded by remember { mutableStateOf(false) }

    val rotationAngle: Float by animateFloatAsState(
        targetValue = if (expanded) 90f else 0f,
        animationSpec = tween(durationMillis = 300)
    )

    // opening animation
    val expandTransition = remember {
        expandVertically(
            expandFrom = Alignment.Top,
            animationSpec = tween(300)
        ) + fadeIn(
            animationSpec = tween(300)
        )
    }

    // closing animation
    val collapseTransition = remember {
        shrinkVertically(
            shrinkTowards = Alignment.Top,
            animationSpec = tween(300)
        ) + fadeOut(
            animationSpec = tween(300)
        )
    }
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {

        Row(
            modifier = Modifier.fillMaxWidth().background(
                if (currentFile?.path == item.path) MaterialTheme.colorScheme.outline.copy(alpha = 0.4f) else Color.Transparent
            ).clickable {
                if (item.type == "file") {
                    onSelectFile(item)
                }
            }.padding(8.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Spacer(
                modifier = Modifier.width(depth * 14.dp)/*.background(
                if (currentFile?.path == item.path) MaterialTheme.colorScheme.outline.copy(alpha = 0.4f) else Color.Transparent
            )*/
            )
            if (item.type == "dir") {
                Icon(
                    imageVector = Icons.Filled.ArrowForwardIos,
                    contentDescription = null,
                    modifier = Modifier
                        .size(16.dp)
                        .clickable {
                            expanded = !expanded
                            if (item.sha != null || item.children.isNotEmpty()) {
                                onExpanded(item.path.toString())
                            }
                        }
                        .rotate(rotationAngle)
                )
            }
            Spacer(modifier = Modifier.width(6.dp))
            Text(
                modifier = Modifier.weight(1f),
                text = item.name.toString()
            )
            Box {
                IconButton(
                    onClick = {
                        openElementDropdown = true
                    },
                    modifier = Modifier.size(18.dp)
                ) {
                    Icon(
                        imageVector = Icons.Default.MoreVert,
                        contentDescription = "Open Options"
                    )
                }
                DropdownMenu(expanded = openElementDropdown, onDismissRequest = {
                    openElementDropdown = false
                }) {

                    if (item.type != "file") {
                        DropdownMenuItem(
                            text = {
                                Text("Add Sub Folder")
                            },
                            onClick = {
                                openElementDropdown = false
                                onAddChild(item, "dir")
                            }
                        )
                        DropdownMenuItem(
                            text = {
                                Text("Add File")
                            },
                            onClick = {
                                openElementDropdown = false

                                onAddChild(item, "file")
                            }
                        )
                    }
//                    if (item.type == "file") {
                    DropdownMenuItem(
                        text = {
                            Text("Delete")
                        },
                        onClick = {
                            openElementDropdown = false
                            onDelete(item)
                        }
                    )
//                    }

                }
            }
        }
//        AnimatedVisibility(
//            visible = expanded,
//            enter = expandTransition,
//            exit = collapseTransition
//        ) {
        if (expanded) {
            item.children.forEach { subChild ->
                FolderItem(
                    subChild,
                    currentFile,
                    onExpanded,
                    onAddChild,
                    onSelectFile,
                    onRename,
                    onDelete,
                    depth = depth + 1
                )
            }
        }
//        }
    }


}

fun String.findLink(substring: String): String? {
//    val handler = KsoupHtmlHandler.Builder().onOpenTag { name, attributes, isImplied ->
//        Napier.i("Find Link: Name: $name, attributes: $attributes")
//    }.build()
//    val ksoupHtmlParser = KsoupHtmlParser(handler)
//    ksoupHtmlParser.write(this)
//    ksoupHtmlParser.end()
    // Define the regex pattern to extract the URL from the HTML content
    val hrefs = this.extractHrefs()
    Napier.i("Links: $hrefs")

    // Find first href where text contains substring
    // Check substring in link text rather than full input
    return hrefs.find { it.second.contains(substring) }?.first
}

fun String.extractHrefs(): MutableList<Pair<String, String>> {
    val startDelim = "<a href=\""
    val endDelim = "\""

    val aTextStartDelim = "target=\"_blank\">"
    val aTextEndDelim = "<"
    var startIndex = indexOf(startDelim)
    var aTextStartIndex = indexOf(aTextStartDelim)
    val links = mutableListOf<Pair<String, String>>()
    while (startIndex != -1 && aTextStartIndex != -1) {
        startIndex += startDelim.length
        aTextStartIndex += aTextStartDelim.length
        val endIndex = indexOf(endDelim, startIndex)
        val aTextEndIndex = indexOf(aTextEndDelim, aTextStartIndex)
        links.add(Pair(substring(startIndex until endIndex), substring(aTextStartIndex until aTextEndIndex)))
        startIndex = indexOf(startDelim, endIndex)
        aTextStartIndex = indexOf(aTextStartDelim, aTextEndIndex)
    }


    return links
}

@Composable
fun <T> T.useDebounce(
    delay: Duration = 1.seconds,
    // 1. coroutine scope
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    onChange: (T) -> Unit
): T {
    // 2. updating state
    val state by rememberUpdatedState(this)

    // 3. launching the side effect handler
    DisposableEffect(state) {
        val job = coroutineScope.launch {
            delay(delay)
            onChange(state)
        }
        onDispose {
            job.cancel()
        }
    }
    return state
}