package org.company.app.presentation.component.kanbanboard

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.DatePicker
import androidx.compose.material3.DatePickerDefaults
import androidx.compose.material3.DatePickerDialog
import androidx.compose.material3.DockedSearchBar
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.SearchBarDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.rememberDatePickerState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.seiko.imageloader.rememberImagePainter
import kotlinx.coroutines.delay
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.assign_member_
import multiplatform_app.composeapp.generated.resources.blockers
import multiplatform_app.composeapp.generated.resources.comments
import multiplatform_app.composeapp.generated.resources.due_datepicker_
import multiplatform_app.composeapp.generated.resources.low_flag
import multiplatform_app.composeapp.generated.resources.medium_flag
import multiplatform_app.composeapp.generated.resources.urgent_flag
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.board.formatDateString
import org.company.app.presentation.ui.carddetails.AutoSubmittingTextField
import org.company.app.presentation.utils.dateTimeToString
import org.company.app.presentation.utils.prettyFormat
import org.company.app.presentation.utils.selectedDateMillisToLocalDateTime
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.TaskItem

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class,
    ExperimentalComposeUiApi::class
)
@Composable
fun KanbanBoardCard(
    modifier: Modifier = Modifier,
    onClick: () -> Unit,
    task: MutableState<TaskItem>
) {
    val windowSizeClass = calculateWindowSizeClass()
    val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
    val taskTitle :MutableState<String?> = mutableStateOf(task.value.name?:"")

    val viewModel = koinInject<HomeViewModel>()
    var assignToMember = remember { mutableStateOf(false) }
    var datePicker = remember { mutableStateOf(false) }
    val userId = viewModel.homeState.value.user?.id
    var memberAssigned : MutableState<String?> = remember { mutableStateOf(null) }
    var assignedToId = remember { mutableStateOf("") }
    var memberAssignedAvatar = remember { mutableStateOf("") }
    var dateSelected = rememberDatePickerState(yearRange = 2023..2050,)

    if (datePicker.value){
        DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
            TextButton(onClick = {
                datePicker.value = false
                task.value.dueDate = dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().dateTimeToString()
                onClick()
            }) {
                Text("Confirm",
                    // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                    style =  MaterialTheme.typography.bodySmall
                    , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
            }
        }, colors = DatePickerDefaults.colors(containerColor = Color(0xFFFAFAFA))){
            DatePicker(
                state = dateSelected,
                modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
            )
        }
    }

    Card(
        modifier = modifier
            .padding(8.dp)
            // disable card Click
            .clickable {  }

        ,
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
        colors = CardDefaults.cardColors(
            containerColor = Color(0xFFFAFAFA), // set the background color of the card
        )
        ,
        shape = RoundedCornerShape(10.dp),
    ) {
        Column(
            modifier = Modifier.width(250.dp).padding(16.dp)
        ) {
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth()) {
                Box(modifier= Modifier.fillMaxWidth()) {
                  /*  ZiFocusTextField(text = taskTitle, placeHolder = "Type Here",
                        textStyle = MaterialTheme.typography.displayMedium.copy(color = Color(0xFF03264A), fontSize = 17.sp),
                        modifier = Modifier.padding(start = 10.dp,end=if (task.value.taskStatus=="IN_PROGRESS")65.dp else 30.dp),
                        withDivider = false
                    ) */
                    AutoSubmittingTextField(textState = taskTitle,
                        textStyle = MaterialTheme.typography.displayMedium.copy(color = Color(0xFF03264A), fontSize = 17.sp),
                        modifier = Modifier.padding(start = 10.dp,end=if (task.value.taskStatus=="IN_PROGRESS")65.dp else 30.dp),
                        onClick = {
                            task.value.name = taskTitle.value
                            onClick()
                        }
                    )


                }

            }

            Spacer(modifier= Modifier.height(12.dp))
            Box {

                //Collecting states from ViewModel
                val searchText by viewModel.searchText.collectAsState() // Query for SearchBar
                val countriesList by viewModel.boardMemberList.collectAsState()
                Row(verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .clickable { assignToMember.value = !assignToMember.value }
                ) {

                    Image(painter = painterResource(Res.drawable.assign_member_),contentDescription = null,modifier=Modifier.size(24.dp))
                    Spacer(modifier = Modifier.width(2.dp))
                    Text(task.value.assignedTo?:"Add Work Owner", modifier = Modifier.padding(start = 10.dp),color = Color(0xFF03264A),fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
                }
                DropdownMenu(
                    expanded = assignToMember.value,
                    onDismissRequest = { assignToMember.value = false },
                    modifier = Modifier.align(Alignment.TopEnd).background(color = Color(0xFFFAFAFA),shape = RoundedCornerShape(10.dp)).padding(12.dp)
                ){
                    Text("Assign to", fontSize = 16.sp,
                        //   fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                        style =  MaterialTheme.typography.displayMedium
                    )
                    DockedSearchBar(
                        query = searchText,//text showed on SearchBar
                        onQueryChange = viewModel::onSearchTextChange, //update the value of searchText
                        onSearch = viewModel::onSearchTextChange, //the callback to be invoked when the input service triggers the ImeAction.Search action
                        active = true, //whether the user is searching or not
                        onActiveChange = { viewModel.onToogleSearch() }, //the callback to be invoked when this search bar's active state is changed
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(16.dp),
                        placeholder = {
                            Text(text = "Search members", fontSize = 15.sp,
                                //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                style =  MaterialTheme.typography.bodySmall
                            )
                        },
                        leadingIcon = {
                            Icon(imageVector = Icons.Default.Search, contentDescription = null)
                        },
                        colors = SearchBarDefaults.colors(containerColor = Color(0xFFFAFAFA))
                    ) {
                        countriesList.let {
                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .heightIn(max=250.dp)
                                    .verticalScroll(state = rememberScrollState())
                            ) {
                                it.forEach { member ->
                                    TextButton(
                                        onClick = {
                                            assignedToId.value = member?.id?:""
                                            memberAssigned.value = member?.name?:""
                                            assignToMember.value = false
                                            memberAssignedAvatar.value = member?.avatar?:""
                                            task.value.assignedTo = member?.name?:""
                                            onClick()
                                        }
                                    ) {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            if (member?.avatar.isNullOrEmpty()){
                                                val name = member?.name?:""
                                                val words = name.split(" ")
                                                val initials = if (words.size > 1) {
                                                    words[0].take(1) + words[words.lastIndex].take(1)
                                                } else {
                                                    words[0].take(2)
                                                }.uppercase()
                                                TextButton(onClick = {},modifier = Modifier
                                                    .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(horizontal = 2.dp, vertical = 2.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)
                                                ){
                                                    Text(
                                                        text = initials,
                                                        fontSize = 16.sp,
                                                        color = Color.White,
                                                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                        maxLines = 1,
                                                        overflow = TextOverflow.Ellipsis,
                                                        textAlign = TextAlign.Center
                                                    )
                                                }

                                            }
                                            else {
                                                Image(
                                                    rememberImagePainter(member?.avatar?:""),modifier = Modifier
                                                        .size(35.dp)
                                                        .clip(CircleShape)
                                                        .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                            }
                                            Spacer(modifier = Modifier.width(6.dp))

                                            Text(if (member?.id==userId) "${member?.name} - @Me" else member?.name?:"",
                                                //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontSize = 14.sp, color = Color.Black,modifier = Modifier.padding(start = 4.dp))
                                        }

                                    }

                                }
                            }
                        }
                    }
                }
            }

            Spacer(modifier= Modifier.height(12.dp))
            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth().clickable {
                datePicker.value=true
            }) {
                Image(painter = painterResource(Res.drawable.due_datepicker_),contentDescription = null,modifier=Modifier.size(24.dp))
                Spacer(modifier = Modifier.width(2.dp))
                Text(if (task.value.dueDate!=null)formatDateString(task.value.dueDate?:"Select Date") else "Select Date", modifier = Modifier.padding(start = 10.dp),color = Color(0xFF03264A),fontFamily = MaterialTheme.typography.bodySmall.fontFamily)
            }

        }
    }
}