package com.jettaskboard.multiplatform.ui.screens.board.appBar


import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.DropdownMenuItem
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.*
import androidx.compose.material.icons.outlined.Notifications
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.russhwolf.settings.Settings
import com.seiko.imageloader.rememberImagePainter
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.task_notification
import org.company.app.platformName
import org.company.app.presentation.component.CustomAlertDialog
import org.company.app.presentation.component.CustomTextField
import org.company.app.presentation.ui.LoginScreen
import org.company.app.presentation.ui.carddetails.CardDetailsScreen
import org.company.app.presentation.ui.profile.ProfileScreen
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.theme.AppTheme
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.UserRegistration

@OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3Api::class)
@Composable
fun TaskBoardAppBar(
    modifier: Modifier = Modifier,
    navigator: Navigator,
    user: UserRegistration? =null,
    navigateAfterSignOut: () -> Unit={},
    navigateWithRouterCardDetails: () -> Unit,
    navigateBackFromCardDetails: () -> Unit,

    title: String,

    onHamBurgerMenuClick: () -> Unit = {}
) {
    val viewModel = koinInject<HomeViewModel>()
    val notifications = viewModel.homeState.value.myNotifications
    var count by remember { mutableStateOf(0) }
    LaunchedEffect(Unit){
        viewModel.getNotifications {
            count = it.totalCount?.toInt()?:0
        }
    }
    AppTheme {
        val isSignOutDialogVisible = remember { mutableStateOf(false) }
        if (isSignOutDialogVisible.value){
            CustomAlertDialog(title = "Log out", description = "Are you sure you want to Log out ?",
                confirmButtonTitle = "Log out",onDelete = {
                isSignOutDialogVisible.value=false
                val settings = Settings()
                viewModel.clearLogout()
                settings.clear()
                if (platformName()=="Web") navigateAfterSignOut() else navigator.replace(LoginScreen())
            }, onDismiss = {
                isSignOutDialogVisible.value=false
            })
        }


        val navigator2= LocalNavigator.currentOrThrow
            TopAppBar(
                modifier = modifier,


                navigationIcon = {
                },
                title = {
                    Row(modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                        Text(text = title, fontSize = 18.sp,
                           // fontFamily = fontFamilyResource(MR.fonts.sf.bold)
                            fontFamily =  MaterialTheme.typography.bodyLarge.fontFamily,
                            style = MaterialTheme.typography.bodyLarge
                            , color = Color(0xFF1BBEC1))
                        /* Hide Search Till Api is ready
                        Spacer(modifier = Modifier.weight(1f))
                       // SearchBar()
                        CustomTextField(leadingIcon = {
                            Icon(
                                Icons.Filled.Search,
                                null,
                                tint = LocalContentColor.current.copy(alpha = 0.3f),
                                modifier=Modifier.padding(start = 12.dp)
                            )
                        }, placeholderText = "Search")
                        */


                        Spacer(modifier = Modifier.weight(1f))
                    }
                        },
                colors = TopAppBarDefaults.topAppBarColors(Color(0xFFFAFAFA)),
                actions = {
                    Column (verticalArrangement = Arrangement.Center){
                        var showNotification by remember { mutableStateOf(false) }

                        Box(modifier = Modifier.padding(12.dp)) {
                            IconButton(
                                onClick = {
                                    viewModel.getNotifications(){
                                        count=it.totalCount?.toInt()?:0
                                    }
                                    showNotification = true

                                }
                            ) {
                                Icon(
                                    imageVector = Icons.Outlined.Notifications,
                                    //  tint = Color.White,
                                    contentDescription = "Notifications"
                                )
                            }
                            if (count!=0){
                                Badge(
                                    modifier = Modifier
                                        .border(1.dp, color = Color.White, shape = CircleShape)
                                        .align(Alignment.TopEnd)
                                        .clip(CircleShape)
                                ) {
                                    Text(text = "$count")
                                }
                            }

                        }

                            DropdownMenu(
                                expanded = showNotification,
                                onDismissRequest = { showNotification = false },
                                modifier = Modifier.background(color = Color(0xFFFAFAFA)).fillMaxWidth(0.5f)
                            ) {
                                Row(modifier=Modifier.padding(20.dp)) {
                                    Text("Notifications",
                                        style =  MaterialTheme.typography.bodyMedium,
                                        fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                        color = Color(0xFF5C728A),
                                        fontSize = 17.sp,
                                        lineHeight = 20.29.sp,
                                        fontWeight = FontWeight(500)
                                        )
                                    Spacer(modifier=Modifier.weight(1f))
                                    //
                                  /*  IconButton(
                                        onClick = {
                                        },
                                        modifier = Modifier.size(24.dp)
                                    ) {
                                        Icon(
                                            imageVector = Icons.Default.MoreVert,
                                            contentDescription = "Open Options"
                                        )
                                    } */
                                }
                                if (notifications?.isEmpty()==true){
                                    DropdownMenuItem(onClick = {
                                        /* Handle Profile click event */
                                        showNotification=false
                                    }) {
                                        Column() {
                                            Text(text = "You dont have any notification",
                                                fontSize = 15.sp,
                                                //  fontFamily = fontFamilyResource(MR.fonts.sf.medium),
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                textAlign = TextAlign.Start,
                                                color = Color(0xFF03264A),
                                                modifier=Modifier.fillMaxWidth(),
                                            )
                                        }
                                    }
                                } else {
                                    notifications?.forEach {
                                        DropdownMenuItem(onClick = {
                                            /* Handle Profile click event */
                                            viewModel.readNotification(it.id?:"")
                                            if (platformName()=="Web"){
                                                viewModel.getTaskDetails(it.referenceId) {
                                                    viewModel.currentTask(it)
                                                    if (platformName() == "Web") navigateWithRouterCardDetails()
                                                    viewModel.navigateToHome(false)
                                                    navigator2.push(CardDetailsScreen( navigateBackFromCardDetails))
                                                   /* navigator2.push(
                                                        CardDetailsScreen(

                                                        )
                                                    ) */

                                                }
                                            }
                                            showNotification=false
                                        },if (it.isRead==false) Modifier.background(color=Color(0xFFedf3fc)) else Modifier) {
                                            Row(verticalAlignment = Alignment.CenterVertically) {
                                                Image(painterResource(Res.drawable.task_notification),contentDescription = null)
                                                Spacer(modifier=Modifier.width(4.dp))
                                                Text(text = it.content?:"",
                                                    fontSize = 15.sp,
                                                    //  fontFamily = fontFamilyResource(MR.fonts.sf.medium),
                                                    style =  MaterialTheme.typography.bodySmall,
                                                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                    textAlign = TextAlign.Start,
                                                    color = Color(0xFF03264A),
                                                    modifier=Modifier.fillMaxWidth(),
                                                )

                                            }

                                        }
                                    }
                                }


                            }




                    }
                    Spacer(modifier=Modifier.width(8.dp))
                            Column {
                                var expanded by remember { mutableStateOf(false) }
                                if (user?.avatar==null){
                                    val name = user?.name?:""
                                    val words = name.split(" ")
                                    val initials = if (words.size > 1) {
                                        words[0].take(1) + words[words.lastIndex].take(1)
                                    } else {
                                        words[0].take(2)
                                    }.uppercase()

                                    TextButton(onClick = {expanded = true},modifier = Modifier
                                        .size(28.dp).defaultMinSize(1.dp,1.dp), contentPadding = PaddingValues(0.dp,0.dp), colors = ButtonDefaults.textButtonColors(containerColor = MaterialTheme.colorScheme.primary),shape = RoundedCornerShape(50.dp)){
                                        Text(
                                            text = initials,
                                            fontSize = 18.sp,
                                            color = Color.White,
                                            fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                            maxLines = 1,
                                            overflow = TextOverflow.Ellipsis,
                                            textAlign = TextAlign.Center
                                        )
                                    }
                                } else {
                                    Image(rememberImagePainter(user.avatar?:""),modifier = Modifier
                                        .size(40.dp)
                                        .clip(CircleShape)
                                        .clickable { expanded=true }
                                        .border(1.dp, Color.LightGray, CircleShape), contentDescription = null)
                                }



                                DropdownMenu(
                                    expanded = expanded,
                                    onDismissRequest = { expanded = false },
                                    modifier = Modifier.background(color = Color.White)
                                ) {
                                    DropdownMenuItem(onClick = {
                                    /* Handle Profile click event */

                                        navigator2.push(ProfileScreen(false))
                                        expanded=false
                                    }) {
                                        Row(horizontalArrangement = Arrangement.Center,) {
                                          /*  Icon(
                                                modifier= Modifier.padding(end=4.dp),
                                                painter = rememberVectorPainter(Icons.Default.Person) ,
                                                contentDescription = "Profile",
                                            ) */
                                            Text(text = "Profile",
                                                fontSize = 16.sp,
                                              //  fontFamily = fontFamilyResource(MR.fonts.sf.medium),
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                textAlign = TextAlign.Start,
                                                color = Color(0xFF03264A),
                                                modifier=Modifier.fillMaxWidth(),
                                            )
                                            // Spacer(modifier=Modifier.weight(1f))
                                        }
                                    }
                                    /*
                                    DropdownMenuItem(onClick = { /* Handle Settings click event */ }) {
                                        Row(horizontalArrangement = Arrangement.Center,) {
                                            Icon(
                                                modifier= Modifier.padding(end=4.dp),
                                                painter = rememberVectorPainter(Icons.Default.Settings) ,
                                                contentDescription = "Settings",
                                            )
                                            Text(text = "Settings",
                                                fontSize = 16.sp,
                                               // fontFamily = fontFamilyResource(MR.fonts.sf.medium),
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                textAlign = TextAlign.Start,
                                                modifier=Modifier.fillMaxWidth(),
                                            )
                                            // Spacer(modifier=Modifier.weight(1f))
                                        }
                                    }
                                    */
                                    DropdownMenuItem(onClick = {

                                    isSignOutDialogVisible.value=true

                                    }) {
                                        Row(horizontalArrangement = Arrangement.Center,) {
                                          /*  Icon(
                                                modifier= Modifier.padding(end=4.dp),
                                                painter = rememberVectorPainter(Icons.Default.ExitToApp),
                                                contentDescription = "Sign Out",

                                                ) */
                                            Text(text = "Log out",
                                                fontSize = 16.sp,
                                               // fontFamily = fontFamilyResource(MR.fonts.sf.medium),
                                                style =  MaterialTheme.typography.bodySmall,
                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                color = Color(0xFF03264A),
                                                textAlign = TextAlign.Start,
                                                modifier=Modifier.fillMaxWidth(),
                                            )

                                        }
                                    }
                                }

                            }
                    Spacer(modifier=Modifier.width(16.dp))

                            // drop down menu here


                }
            )

    }

}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SearchBarSample() {
    var text by rememberSaveable { mutableStateOf("") }
    var active by rememberSaveable { mutableStateOf(false) }

    Box(Modifier) {

        DockedSearchBar(
            modifier = Modifier
                .align(Alignment.TopCenter)
            ,
            query = text,
            onQueryChange = { text = it },
            onSearch = { active = false },
            active = active,
            onActiveChange = {
                active = it
            },
            placeholder = { Text("Hinted search text") },
            leadingIcon = { Icon(Icons.Default.Search, contentDescription = null) },

        ) {

        }
        /*
        LazyColumn(
            contentPadding = PaddingValues(start = 16.dp, top = 72.dp, end = 16.dp, bottom = 16.dp),
            verticalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            val list = List(100) { "Text $it" }
            items(count = list.size) {
                Text(list[it], Modifier.fillMaxWidth().padding(horizontal = 16.dp))
            }
        } */
    }
}
